interface Button {
  text: string;
  onClick: () => void;
  theme: "LIGHT" | "MEDIUM" | "DARK";
}

interface Props {
  buttons: Button[];
  className?: string;
}

const themes = {
  LIGHT:
    "text-black-50 bg-transparent border border-black-10 hover:bg-black-10",
  MEDIUM: "text-blue-100 bg-blue-10 hover:bg-blue-25",
  DARK: "text-white-100 bg-blue-100 hover:bg-blue-150",
};

/* We're using divs instead of buttons here bc I can't for the life of me get borders working inside of the ion toolbar */
export const ModalButtonContainer: React.FC<Props> = ({
  buttons,
  className,
}) => {
  return (
    <div
      className={`pt-2 pb-4 px-4 grid gap-3 grid-cols-${buttons?.length} ${
        className || ""
      }`}
    >
      {buttons?.map((button, key) => (
        <div
          className={`grid items-center ${
            themes[button.theme]
          } text-sm text-center font-semibold px-5 py-5 block rounded-lg transition-all cursor-pointer`}
          key={key}
          onClick={() => button?.onClick()}
        >
          <span>{button?.text}</span>
        </div>
      ))}
    </div>
  );
};
