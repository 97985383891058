import { IonContent, IonPage, useIonAlert } from "@ionic/react";
import { useRef } from "react";
import _ from "lodash";
import { useHistory, useParams } from "react-router";
import { DeliveryNoticeHeader } from "../../components/headers/DeliveryNotice";
import { FormContainer } from "../../components/deliveryNotice/FormContainer";
import { Wrapper } from "../../components/core/Wrapper";
import { Modal } from "../../components/core/Modal";
import { DeliveryInformation } from "../../components/modals/DeliveryInformation";
import { DeliveryHistory } from "../../components/modals/DeliveryHistory";
import { useEffect, useState } from "react";
import { StickyFooter } from "../../components/deliveryNotice/StickyFooter";
import { OrderReview } from "../../components/modals/OrderReview";
import { Loader } from "../../components/core/Loader";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { resetDelivery, setDelivery } from "../../redux/delivery";
import {
  setHasSubmitted,
  setInvalidProducts,
  setIsEmailValid,
} from "../../redux/validationSlice";
import { useDebounce, isValidEmail } from "../../lib";
import deliveryService from "../../service/deliveryService";
import userService from "../../service/userService";

const DeliveryNotice: React.FC = () => {
  /* Redux */
  const delivery = useAppSelector((state) => state.delivery);
  const validation = useAppSelector((state) => state.validation);

  const dispatch = useAppDispatch();

  /* Refs */
  const deliveryInformationModal = useRef<HTMLIonModalElement>(null);
  const deliveryHistoryModal = useRef<HTMLIonModalElement>(null);
  const orderReviewModal = useRef<HTMLIonModalElement>(null);
  const contentRef = useRef<any>(null);

  /* Hooks */
  const history = useHistory();
  const [hasMounted, setHasMounted] = useState(false);
  const { deliveryId } = useParams<{ deliveryId?: string }>();
  const [pathName, setPathName] = useState("");
  const debouncedDelivery = useDebounce(delivery, 300);
  const [hasLoadedDebounce, sethasLoadedDebounce] = useState(false);
  const [deliveryHistory, setDeliveryHistory] = useState<any[]>([]);
  const [originalDelivery, setOriginalDelivery] = useState<any>({});
  const [hasEditedDelivery, setHasEditedDelivery] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [presentAlert] = useIonAlert();

  /* Helpers */
  const handleBackClick = (resetDeliveryRedux: any) => {
    if (
      (delivery.status === "DELIVERED" || delivery.status === "CORRECTED") &&
      hasEditedDelivery
    ) {
      presentAlert({
        header: "You have unsaved changes",
        message:
          "Delivered or Corrected deliveries will only be saved if you mark them as delivered again. Are you sure you want to leave this page?",
        buttons: [
          {
            text: "Cancel",
            role: "cancel",
          },
          {
            text: "OK",
            role: "confirm",
            handler: () => {
              resetDeliveryRedux();
              history.push("/inbox");
            },
          },
        ],
      });
    } else {
      history.push("/inbox");
    }
  };

  /* On page load, scroll to the top */
  useEffect(() => {
    if (contentRef.current && hasMounted) {
      contentRef.current.scrollToPoint(0, 0, 0);
    }
  }, [contentRef.current, hasMounted]);

  /* 
    If a delivery is in the READY state, as soon as any piece of the delivery state changes, mark it as IN_PROGRESS
    Also, if there is no truck assigned to the delivery, assign the current user's truck
  */
  useEffect(() => {
    const setUpdatedBy = async () => {
      if (delivery.status === "READY") {
        const currentUser = await userService.getCurrentUser();
        const contactEmails = delivery.customer?.contactEmails?.map(
          (email: any) => email?.trim().toLowerCase()
        );
        if (currentUser.authenticated) {
          const updatedDelivery = {
            ...delivery,
            ...{
              status: "IN_PROGRESS",
              driver: { name: currentUser.name, email: currentUser.email },
              notificationEmails: contactEmails,
              source: {
                ...delivery.source,
                ...{
                  truck: delivery?.source?.truck || currentUser?.source?.truck,
                  warehouse:
                    delivery?.source?.warehouse ||
                    currentUser?.source?.warehouse,
                },
              },
            },
          };

          dispatch(setDelivery(updatedDelivery));
          await deliveryService.updateDelivery(updatedDelivery);
        }
      }
    };
    setUpdatedBy();
  }, [delivery]);

  /* 
    When a delivery changes in any way, perform validation on the fields we want to validate
  */
  useEffect(() => {
    (async () => {
      const invalidProducts: string[] = [];
      if (Object.keys(delivery)?.length) {
        delivery.productInventories.map((p: any) => {
          if (!p.lots.length && !p.skipDelivery) {
            invalidProducts.push(p.id);
          }
        });
        dispatch(setInvalidProducts(invalidProducts));

        /* Email Validation */
        if (delivery?.notificationEmails?.length) {
          let allEmailsValid = true;
          delivery.notificationEmails.map((email: string) => {
            if (!isValidEmail(email)) {
              allEmailsValid = false;
            }
          });

          dispatch(setIsEmailValid(allEmailsValid));
        } else if (!validation.isEmailValid) {
          dispatch(setIsEmailValid(true));
        }
      }
    })();
  }, [delivery]);

  /*
    When deliveries change, sometimes we want to warn the user that they have unsaved changes 
    when they go back, depending on the order status -- we're tracking those changes here
  */
  useEffect(() => {
    if (Object.keys(delivery).length && hasMounted) {
      if (!Object.keys(originalDelivery).length) {
        setOriginalDelivery(delivery);
      }

      if (
        !_.isEqual(originalDelivery, delivery) &&
        Object.keys(originalDelivery).length &&
        !hasEditedDelivery
      ) {
        setHasEditedDelivery(true);
      }
    }
  }, [delivery, hasEditedDelivery, hasMounted]);

  /*
    Listen on the debounced delivery, and update the service any time the object changes.
    But not including the first change, since that will be the initial state
  */
  useEffect(() => {
    if (hasMounted) {
      if (!hasLoadedDebounce) {
        sethasLoadedDebounce(true);
      } else {
        deliveryService.updateDelivery(delivery);
      }
    }
  }, [debouncedDelivery]);

  /* Lifecycle */

  /*
    Ionic lifecycle events are being inconsistent, so we're using this method to track when the component is mounted.
    A mixture of hasMounted + the pathname. I don't love checking the pathname, but this is the only way it will work
    for now + the app is small enough its not a huge deal. 
  */
  useEffect(() => {
    /* Entering */
    if (
      !hasMounted &&
      history.location.pathname.startsWith("/delivery-notice")
    ) {
      setHasMounted(true);
      setHasEditedDelivery(false);
      setOriginalDelivery({});
    }
    /* Leaving */
    if (
      hasMounted &&
      !history.location.pathname.startsWith("/delivery-notice")
    ) {
      dispatch(resetDelivery());
      setHasMounted(false);
      sethasLoadedDebounce(false);
      setPathName("");
      setIsSaving(false);
    }
  }, [history.location.pathname, hasMounted]);

  /*
    Just watching the deliveryId isn't good enough -- if we load the same delivery 2x in a row it won't trigger a change.
    So instead we watch the whole pathname, track it in a hook (and set it to empty on unmount). This way we know exactly
    when it changes, and most importantly, only changes ONCE (and doesn't double fetch, sometimes getting the wrong delivery)
  */
  useEffect(() => {
    if (!pathName.length) {
      setPathName(history.location.pathname);
    }
  }, [history.location.pathname]);

  /*
    Fetch the active delivery, if the component is mounted -- we only want to fech if there is a deliveryId
    since there will be no value for new deliveries. This was VERY tricky to get right for some reason with 
    the dependencies array -- if you change hasMounted, deliveryId or add anything else be VERY careful 
  */

  /*
    Update: We are checking for pathname (the full history, not our cached one) AND delivery ID 
    Without this, after marking an order as complete, deliveryIds are stale when navigating to the truck tab and back
  */
  const pn: any = history.location.pathname;
  useEffect(() => {
    (async () => {
      if (
        pn.length &&
        pn?.toLowerCase().includes("/delivery-notice") &&
        pn.includes(deliveryId)
      ) {
        if (deliveryId?.length) {
          const deliveryRes = await deliveryService.getDelivery(deliveryId);
          dispatch(setDelivery(deliveryRes));
        }
      }
    })();
  }, [deliveryId, pn]);

  return (
    <IonPage>
      {!!(hasMounted && delivery.id?.length && !isSaving) && (
        <DeliveryNoticeHeader
          title={delivery.customer.internalName}
          address={delivery.customer.address}
          onInfoClick={async () =>
            await deliveryInformationModal.current?.present()
          }
          onBackClick={() => {
            handleBackClick(() => {
              dispatch(setDelivery(originalDelivery));
            });
          }}
          onHistoryClick={async () => {
            // TODO: use the logged in customer ID
            const deliveries = await deliveryService.getHistory({
              customerId: delivery.customer.id,
              deliveryId: delivery.id,
            });
            setDeliveryHistory(deliveries);
            await deliveryHistoryModal.current?.present();
          }}
        />
      )}
      <IonContent ref={contentRef} onIonScroll={() => {}}>
        {!hasMounted || !delivery.id?.length || isSaving ? (
          <Loader className="flex h-screen justify-center items-center" />
        ) : (
          <>
            {/* Form Container */}
            <Wrapper>
              <FormContainer props={delivery} />{" "}
            </Wrapper>
          </>
        )}

        {!!(hasMounted && delivery.id?.length && !isSaving) && (
          <>
            {/* ------ Delivery Information Modal ------ */}
            <Modal
              ref={deliveryInformationModal}
              heading="Delivery information"
              buttons={[
                {
                  text: "Close",
                  theme: "LIGHT",
                  onClick: async () =>
                    await deliveryInformationModal.current?.dismiss(),
                },
              ]}
            >
              <DeliveryInformation />
            </Modal>
            {/* ------ Delivery History Modal ------ */}
            <Modal
              ref={deliveryHistoryModal}
              heading="Delivery History"
              buttons={[
                {
                  text: "Close",
                  theme: "LIGHT",
                  onClick: async () =>
                    await deliveryHistoryModal.current?.dismiss(),
                },
              ]}
            >
              <DeliveryHistory
                deliveryInformation={{
                  name: delivery.customer.name,
                  address: delivery.customer.address,
                }}
                orders={deliveryHistory}
              />
            </Modal>
            {/* Modal Components */}
            {/* ------ Order Review Modal ------ */}
            <Modal
              ref={orderReviewModal}
              heading="Order Review"
              buttons={[
                {
                  text: "Close",
                  theme: "LIGHT",
                  onClick: async () =>
                    await orderReviewModal.current?.dismiss(),
                },
                {
                  text: "Submit",
                  theme: "DARK",
                  onClick: async () => {
                    const currentUser = await userService.getCurrentUser();
                    const updatedDelivery = {
                      ...delivery,
                      status: "PROCESSING",
                      dateDelivered: new Date().toISOString(),
                      driver: {
                        name: currentUser.name,
                        email: currentUser.email,
                      },
                    };
                    dispatch(setDelivery(updatedDelivery));
                    setIsSaving(true);
                    await new Promise((resolve) => setTimeout(resolve, 1000));
                    await deliveryService.finishDelivery(updatedDelivery);
                    await orderReviewModal.current?.dismiss();
                    setIsSaving(false);
                    history.push("/inbox");
                  },
                },
              ]}
            >
              <OrderReview
                deliveryInformation={{
                  name: delivery.customer.name,
                  address: delivery.customer.address,
                }}
                gridData={[
                  {
                    key: "Sales Order Number (S1)",
                    value: delivery.otherIds.orderId,
                  },
                  {
                    key: "Pro Forma Number (SO)",
                    value: delivery.otherIds.proForma,
                  },
                ]}
                products={delivery.productInventories}
                dewars={delivery.dewarInventories}
              />
            </Modal>
          </>
        )}
        <div className="h-5"></div>
      </IonContent>
      {!!(hasMounted && delivery.id?.length && !isSaving) && (
        <StickyFooter
          orderId={delivery.otherIds.orderId}
          onInfoClick={async () =>
            await deliveryInformationModal.current?.present()
          }
          onSubmitClick={async () => {
            /*
              If there are products with missing lots, scroll to them, otherwise, let the user pop the submit modal
            */
            if (!validation.hasSubmitted) {
              dispatch(setHasSubmitted(true));
            }
            if (!validation.invalidProducts.length && validation.isEmailValid) {
              await orderReviewModal.current?.present();
            } else {
              const firstInvalidProduct = document.getElementById(
                validation.invalidProducts[0]
              );
              if (firstInvalidProduct) {
                firstInvalidProduct?.scrollIntoView({ behavior: "smooth" });
              } else if (!validation.isEmailValid) {
                const emailInput =
                  document.getElementById("signer-email-input");
                emailInput?.scrollIntoView({ behavior: "smooth" });
              }
            }
          }}
        />
      )}
    </IonPage>
  );
};

export default DeliveryNotice;
