import { createSlice, current } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {};

export const customerFilters = createSlice({
  name: "customerFilters",
  initialState,
  reducers: {
    setCustomerFilters: (state, action: PayloadAction<any>) => {
      state.filters = action.payload;
    },

    setCustomerFilter: (state, action: PayloadAction<any>) => {
      state.filters = state?.filters.map((filter: any) => {
        if (filter.type === action.payload.selectedFilter.type) {
          filter.value = action.payload.value;
        }
        return filter;
      });
    },
  },
});

export const { setCustomerFilters, setCustomerFilter } = customerFilters.actions;

export default customerFilters.reducer;
