import { IonList, IonItem, IonCheckbox } from "@ionic/react";
import { forwardRef, useState, useEffect } from "react";
import { AutocompleteInput } from "../core/AutocompleteInput";

interface Props {
  label: string;
  description?: string;
  ref: React.RefObject<HTMLIonModalElement>;
  values: any;
  options: any[];
  onChange: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur: () => void;
  loading?: boolean;
  alreadySelected?: any;
  placeholder?: string;
  canCreate?: boolean;
}

type Ref = HTMLIonModalElement;

// TODO: interface for options (label,value) so its documented

export const AutocompleteMulti = forwardRef<Ref, Props>((props, ref: any) => {
  const {
    label,
    description,
    values,
    onChange,
    onBlur,
    loading,
    options,
    alreadySelected = [],
    placeholder,
    canCreate = false,
  } = props;
  const [query, setQuery] = useState("");
  const [filteredOptions, setFilteredOptions] = useState<any[]>([]);

  /* We don't want to let users add anything if the alreadySelected prop contains its value */
  useEffect(() => {
    const filtered = options.filter((option: any) => {
      const isValueIncluded = query
        ? option.value.toLowerCase().includes(query.toLowerCase()) ||
          option.label.toLowerCase().includes(query.toLowerCase())
        : true;

      return isValueIncluded && !alreadySelected.includes(option.value);
    });

    if (filtered.length === 0 && query && query.length > 0 && canCreate) {
      filtered.push({ label: `Create ${query}`, value: query });
    }
    setFilteredOptions(filtered);
  }, [query, options]);

  const handleChange = (ev: Event) => {
    const target = ev.target as HTMLInputElement;
    if (target) setQuery(target.value);
  };

  /* Helpers */
  const sortAlphabetical = (items: any) => {
    return items.sort((a: any, b: any) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });
  };

  return (
    <>
      <div className="bg-white-100 sticky top-0 z-10 rounded-bl-xl rounded-br-xl">
        <div className="border border-blue-100 rounded-xl mb-3 focus-within:shadow-[0_0_0_4px_rgba(30,95,239,0.1)]">
          <AutocompleteInput
            label={label}
            placeholder={placeholder || "Select options"}
            description=""
            value={
              options?.find((option: any) => values.includes(option.value))
                ?.display
            }
            onChange={(ev: any) => handleChange(ev)}
            onBlur={onBlur}
            className={"border-0"}
          />
        </div>
      </div>

      {filteredOptions.length > 0 ? (
        <IonList className="rounded-lg border border-black-10">
          {sortAlphabetical(filteredOptions)?.map((option: any, index: any) => (
            <IonItem key={index} className="autocomplete-ion-list-override">
              <IonCheckbox
                value={option.value}
                checked={values.includes(option.value)}
                onIonChange={async () => {
                  onChange(option.value);
                }}
              >
                {option.label}
              </IonCheckbox>
            </IonItem>
          ))}
        </IonList>
      ) : loading ? (
        "Loading..."
      ) : (
        "No results"
      )}
    </>
  );
});
