import { useEffect, useState, useRef } from "react";
import { useCustomersContext } from "../../../contexts/CustomersContext";
import { CustomersContext } from "../../../contexts/CustomersContext";
import { Layout } from "../../../components/dashboard/Layout";
import { Header } from "../../../components/dashboard/Header";
import { Filters } from "../../../components/core/Filters";
import { CustomersView } from "../../../components/dashboard/views/CustomersView";
import { Button } from "../../../components/core/Button";
import {
  setCustomerFilters,
  setCustomerFilter,
} from "../../../redux/customerFilters";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import useFilters from "../../../hooks/useFilters";

/* We'll use this so we can track state on existing users or new users in the modal */
interface Customer {
  value: string;
  contactEmails?: "";
}
const defaultCustomerSchema: any = {
  value: "",
  contactEmails: [],
};

const validateFields = (activeCustomer: Customer) => {
  let errors: any = {};

  const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
  const hasInvalidEmail = (activeCustomer?.contactEmails || [])?.some(
    (email: string) => !emailRegex.test(email)
  );
  if (hasInvalidEmail) {
    errors.email = "Please enter a valid email.";
  }
  return errors;
};

const Customers: React.FC = ({}) => {
  /* Redux */
  const dispatch = useAppDispatch();
  const customerFilters = useAppSelector((state) => state.customerFilters);

  /* Hooks */
  const [activeCustomer, setActiveCustomer] = useState<any>(null);
  const [fieldErrors, setFieldErrors] = useState<any>({});
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const { filters, fetchFilters } = useFilters();

  /* Refs */
  const customerModal = useRef<HTMLIonModalElement>(null);

  /* Effects */
  /* Fetch filters on mount */
  useEffect(() => {
    fetchFilters({
      exclude: [
        "truck",
        "warehouse",
        "product",
        "driver",
        "on_target",
        "truck_load_status",
        "truck_empty_status",
        "lot",
        "dewar_status",
      ],
    });
  }, []);

  /* Once the filters have been fetched, send them off to redux */
  useEffect(() => {
    if (filters.length) {
      dispatch(
        setCustomerFilters(
          filters.map((filter: any) => {
            /* 
              Some dewars have no customer -- this means they are archived. 
              We'll hard code an empty value @ the end of the list with a label
              of "Archived" to let users filter by this.
            */
            return {
              ...{
                ...filter,
              },
              value: filter.value || "",
              selectedValues: [],
            };
          })
        )
      );
    }
  }, [filters]);

  /* If a user has submitted, and there are errors, start validating on keydown */
  useEffect(() => {
    if (hasSubmitted) {
      const errors = validateFields(activeCustomer);
      setFieldErrors(errors);
    } else {
      setFieldErrors({});
    }
  }, [hasSubmitted, activeCustomer]);
  return (
    <Layout>
      {/* Header */}
      <Header
        title={"Customer Settings"}
        filters={
          <Filters
            filters={customerFilters}
            setFilter={(e: any) => {
              dispatch(setCustomerFilter(e));
            }}
            setFilters={(e: any) => {
              dispatch(setCustomerFilters(e));
            }}
          />
        }
        button={<div className="flex items-center gap-2"></div>}
      />
      {/* Content */}
      <CustomersContext.Provider
        value={{
          activeCustomer,
          setActiveCustomer,
          setHasSubmitted,
          fieldErrors,
          setFieldErrors,
          defaultCustomerSchema,
          validateFields,
          customerModal,
          customerFilters,
        }}
      >
        <CustomersView />
      </CustomersContext.Provider>
    </Layout>
  );
};

export default Customers;
