import SVG from "react-inlinesvg";
import downloadIcon from "../../assets/svg/download.svg";
import editIcon from "../../assets/svg/edit.svg";
import deleteIcon from "../../assets/svg/trash.svg";
import archiveIcon from "../../assets/svg/archive.svg";

interface Props {
  data: any;
  roundHeader?: boolean;
  hasReachedMaxPagination?: boolean;
  fetchMore?: () => void;
  onRowClick?: (row: any) => void;
  isLoading?: boolean;
  actionClickOptions?: {}; // this will be an object with functions for each key type
}

const addSpacesToCamelCase = (str: string) => {
  return str.replace(/([a-z])([A-Z])/g, "$1 $2");
};

// TODO: organize.
const getColumnStyle = (key: string, value: any) => {
  switch (key) {
    case "order":
      return "underline underline-offset-4 text-blue-100 hover:text-blue-150";
    case "name":
      return "";
    case "status":
      let base = "px-2 py-1 rounded-full text-xs font-semibold";
      if (value === "CORRECTED")
        return `${base} bg-green-light-100 text-white-100`;
      if (value === "DELIVERED" || value?.toUpperCase() === "COMPLETE")
        return `${base} bg-green-light-10 text-green-light-100`;
      if (value === "PROCESSING" || value === "REPROCESSING")
        return `${base} bg-yellow-10 text-yellow-100`;
      if (value?.toUpperCase() === "UNKNOWN")
        return `${base} bg-black-5 text-black-50`;
      if (value?.toUpperCase() === "ERROR")
        return `${base} bg-red-10 text-red-100`;
      else if (value?.length > 0) return `${base} bg-yellow-10 text-yellow-100`;
      return base;
    case "customer":
      return "font-medium";
    case "deliveredOn":
      return "text-black-50";
    case "truck":
      return "text-black-50";
    case "unitsDelivered":
      return "font-semibold";
    default:
      return "";
  }
};

/* Helpers */
const mapIconToSVG = (icon: any) => {
  switch (icon) {
    case "download":
      return downloadIcon;
    case "edit":
      return editIcon;
    case "delete":
      return deleteIcon;
    case "archive":
      return archiveIcon;
    default:
      return "";
  }
};

// TODO: cleanup, and is there a better way to do this?
const renderCell = (
  key: any,
  value: any,
  actionClickOptions: any,
  row: any
) => {
  /* This is just to handle long order numbers from test data */
  if (key === "order") {
    return (
      <div className="max-w-[100px] whitespace-nowrap overflow-hidden text-ellipsis">
        {value}
      </div>
    );
  }
  if (key === "actions") {
    return (
      <div className="flex gap-4 justify-center w-full">
        {value.map((icon: any, index: any) => (
          <div
            key={index}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (actionClickOptions && actionClickOptions[icon]) {
                actionClickOptions[icon](row);
              }
            }}
          >
            <SVG
              src={mapIconToSVG(icon)}
              style={{
                fill: "rgba(17,20,46,0.5)",
                height: "20px",
                width: "20px",
              }}
            />
          </div>
        ))}
      </div>
    );
  }
  /* users page */
  if (key === "roles") {
    return (
      <div className="flex gap-1 justify-center w-full">
        {value.map((role: any, index: any) => (
          <div
            key={index}
            className="bg-blue-10 py-1 px-2 rounded-full capitalize"
          >
            {role.toLowerCase()}
          </div>
        ))}
      </div>
    );
  }
  /* Email page */
  if (key === "receivesEmailsFor") {
    if (value?.[0] === "ALL") {
      return (
        <div className="flex gap-1 justify-center w-full py-1 px-2 rounded-full bg-blue-100 text-white-100">
          All deliveries
        </div>
      );
    }
    return (
      <div className="flex gap-1 justify-center w-full">
        {value?.[0]}{" "}
        {value?.length > 1
          ? `+${value.length - 1} other${value?.length - 1 > 1 ? "s" : ""}`
          : ""}
      </div>
    );
  }
  return value;
};

export const DataTable: React.FC<Props> = ({
  data,
  roundHeader = true,
  hasReachedMaxPagination = false,
  onRowClick,
  fetchMore,
  isLoading,
  actionClickOptions = {},
}) => {
  /* If there's data we want on the object but not on the table, store it in a metadata var and hide it */
  const headings = data.length > 0 ? Object.keys(data[0]) : [];

  return (
    <table className="w-full">
      <thead className="color-black-100">
        <tr className="border-b border-b-black-10">
          {headings.map(
            (heading, index) =>
              heading !== "metadata" && (
                <th
                  key={heading}
                  className={`bg-slate-gray-100  text-left font-semibold uppercase py-3 px-4 text-black-50 text-xs ${
                    index === 0 && roundHeader ? "rounded-tl-xl" : ""
                  } ${
                    index === headings.length - 1 && roundHeader
                      ? "rounded-tr-xl"
                      : ""
                  }`}
                >
                  {heading !== "actions" ? addSpacesToCamelCase(heading) : ""}
                </th>
              )
          )}
        </tr>
      </thead>
      <tbody className="mx-2">
        {/* Loop through the keys in each object of the array and create a table row for them */}
        {data?.map((row: any, index: any) => (
          <tr
            key={index}
            className="border-b border-b-black-10 cursor-pointer last:border-b-0"
            onClick={() => (onRowClick ? onRowClick(row) : null)}
          >
            {Object.entries(row)?.map(
              ([key, value]) =>
                key !== "metadata" && (
                  <td key={key} className="py-1 px-0.5 text-black-100 text-sm">
                    <div
                      className={`inline-block p-3 text-black-100 text-sm ${getColumnStyle(
                        key,
                        value
                      )} ${key === "actions" ? "w-full justify-end" : ""}`}
                    >
                      {renderCell(key, value, actionClickOptions, row)}
                    </div>
                  </td>
                )
            )}
          </tr>
        ))}
        {!!(data.length && fetchMore && !hasReachedMaxPagination) && (
          <tr>
            <td className="p-4 text-center" colSpan={headings.length}>
              <div
                onClick={() => fetchMore()}
                className="inline-block border border-black-10 px-3 py-1.5 text-blue-100 font-medium rounded-md text-sm hover:bg-black-10 cursor-pointer transition-all ease-in-out"
              >
                Load more
              </div>
            </td>
          </tr>
        )}
        {(!data.length || hasReachedMaxPagination) && (
          <tr>
            <td className="p-4 text-center" colSpan={headings.length}>
              {isLoading
                ? "Loading..."
                : data.length
                ? "You've reached the end of the list"
                : "No results found"}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
