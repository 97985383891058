import { createSlice, current } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
  invalidProducts: [],
  haSubmitted: false,
  isEmailValid: true,
};

export const validation = createSlice({
  name: "validation",
  initialState,
  reducers: {
    setInvalidProducts: (state, action: PayloadAction<any>) => {
      state.invalidProducts = action.payload;
    },
    setHasSubmitted: (state, action: PayloadAction<any>) => {
      state.hasSubmitted = action.payload;
    },
    setIsEmailValid: (state, action: PayloadAction<any>) => {
      state.isEmailValid = action.payload;
    },
  },
});

export const { setInvalidProducts, setHasSubmitted, setIsEmailValid } =
  validation.actions;

export default validation.reducer;
