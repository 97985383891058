import { createSlice, current } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {};

export const customerProduct = createSlice({
  name: "customerProduct",
  initialState,
  reducers: {
    setCustomerProducts: (state, action: PayloadAction<any>) => {
      state.products = action.payload;
    },
  },
});

export const { setCustomerProducts } = customerProduct.actions;

export default customerProduct.reducer;
