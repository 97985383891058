interface Props {
  number: string | number;
  title: string;
}
export const SectionStatContainer: React.FC<Props> = ({ number, title }) => {
  return (
    <div className="flex items-center gap-1.5 py-5">
      <div className="text-3xl font-bold tracking-tight">{number}</div>
      <div className="text-black-50">{title}</div>
    </div>
  );
};
