import { useEffect, useState } from "react";
import axios from "axios";
import { useIonAlert } from "@ionic/react";
import { SectionContainer } from "../SectionContainer";
import { DataTable } from "../DataTable";
import { MultiSelect } from "../../core/MultiSelect";
import { Modal } from "../../core/Modal";
import useUsers from "../../../hooks/useUsers";
import { Input } from "../../core/Input";
import { useUsersContext } from "../../../contexts/UsersContext";
import userService from "../../../service/userService";
import service from "../../../service/service";
import useFilters from "../../../hooks/useFilters";

export const UsersView: React.FC = () => {
  /* Hooks */
  const { users, isUsersLoading, fetchUsers, setUsers } = useUsers();
  const [hasClickedExpireToken, setHasClickedExpireToken] = useState(false);
  const [presentAlert] = useIonAlert();
  const { filters, fetchFilters } = useFilters();

  /* Trucks are creatable, so we'll cache them into a hook */
  const [truckOptions, setTruckOptions] = useState<any[]>([]);

  /* TODO: when we make roles more robust, fetch these from the service. For launch we just have these two roles though  */
  const [roles, setRoles] = useState([
    { label: "App", value: "APP" },
    { label: "Dashboard", value: "DASHBOARD" },
  ]);

  /* Contexts */
  const {
    activeUser,
    setActiveUser,
    fieldErrors,
    setFieldErrors,
    setHasSubmitted,
    defaultUserSchema,
    validateFields,
    usersModal,
  } = useUsersContext();

  /* Watchers */
  useEffect(() => {
    fetchUsers();
    fetchFilters({
      exclude: ["truck_load_status", "truck_empty_status", "dewar_status"],
    });
  }, []);

  /* Constants */
  const formattedUsers = users.map((user: any) => {
    return {
      name: user.name,
      email: user.email,
      roles: user.roles || [],
      truck: user.source?.truck || "",
      actions: ["delete", "edit"],
      metadata: user,
    };
  });

  const filtersObject: any = filters.reduce(
    (acc, filter: any) => ({ ...acc, [filter.type]: filter }),
    {}
  );

  /* Helepers */
  const handleRowClick = async (row: any) => {
    setActiveUser({
      ...row,
      ...{ warehouse: row.metadata.source.warehouse },
    });
    await usersModal.current?.present();
  };

  const mapEntryToLabelValue = (entry: any) => ({
    label: entry.display,
    value: entry.value,
  });

  const findByValue = (value: string) => (type: any) => type.value === value;

  const filterSelectedEntries = (type: string) => (entry: any) => {
    return activeUser.metadata?.filters?.[type]?.includes(entry.value);
  };

  /* 
    Trucks can be free text, so on page load, if someone put a custom truck in their profile, we need to add it to the list of trucks
    We also want to make sure we add it to the list of trucks if they create it in the dropdown.

    This will only be temporary on the FE, but we want it to feel natural since we don't store it on the backend
  */
  useEffect(() => {
    if (Object.keys(filtersObject).length) {
      setTruckOptions(
        filtersObject["truck"]?.entries.map(mapEntryToLabelValue)
      );
    }
  }, [filtersObject["truck"]]);

  useEffect(() => {
    if (activeUser?.metadata?.source?.truck) {
      const truckValue = activeUser.metadata.source.truck;

      if (!truckOptions.find((option) => option.value === truckValue)) {
        setTruckOptions([
          ...truckOptions,
          {
            label: truckValue,
            value: truckValue,
          },
        ]);
      }
    }
  }, [activeUser, truckOptions]);

  return (
    <>
      {/* Table */}
      <div className="mb-[20px]">
        <SectionContainer className="px-0 pb-0!">
          <DataTable
            data={formattedUsers}
            isLoading={isUsersLoading}
            onRowClick={async (row: any) => handleRowClick(row)}
            actionClickOptions={{
              delete: async (row: any) => {
                presentAlert({
                  header: "Are you sure you want to delete this user?",
                  buttons: [
                    {
                      text: "Cancel",
                      role: "cancel",
                    },
                    {
                      text: "Yes",
                      role: "confirm",
                      handler: async () => {
                        await userService.deleteUser(row.metadata);
                        setUsers(
                          users.filter(
                            (user: any) => user.id !== row.metadata.id
                          )
                        );
                      },
                    },
                  ],
                });
              },
              edit: (row: any) => handleRowClick(row),
            }}
          />
        </SectionContainer>
      </div>
      {/* Modal */}
      <Modal
        ref={usersModal}
        heading={`${activeUser.metadata?.id ? "Edit" : "Add"} User`}
        onWillDismiss={() => {
          setActiveUser(defaultUserSchema);
          setHasSubmitted(false);
        }}
        buttons={[
          {
            text: "Close",
            theme: "LIGHT",
            onClick: async () => await usersModal.current?.dismiss(),
          },
          {
            text: "Save",
            theme: "DARK",
            onClick: async () => {
              setHasSubmitted(true);
              const errors = validateFields(activeUser);
              setFieldErrors(errors);
              if (!Object.keys(errors).length) {
                /* Construct a new object with the original pouch object + our updated fields */
                const updatedUser = {
                  ...activeUser.metadata,
                  ...{
                    name: activeUser.name,
                    email: activeUser.email,
                    roles: activeUser.roles,
                  },
                };

                /* Also update the list UI to reflect the new change */
                const updatedUsers = users.map((user: any) => {
                  if (user.id === updatedUser.id) {
                    return updatedUser;
                  }
                  return user;
                });

                /* 
                  If we're adding a new user, put them in the list and hit the user API insted since the
                  node server includes the necessary auth plugin to do this
                */
                if (!updatedUser.id) {
                  const newUser = await axios
                    .post(
                      `${process.env.REACT_APP_SERVER_URL}/api/v1/apps/ceva-delivery-service/users/`,
                      updatedUser,
                      {
                        headers: {
                          Authorization: `${process.env.REACT_APP_SERVICE_AUTH}`,
                        },
                      }
                    )
                    .then((r: any) => r.data);
                  if (newUser.rev) {
                    updatedUsers.push({
                      ...updatedUser,
                      ...{ rev: newUser.rev },
                    });
                  }
                } else {
                  await userService.updateUser(updatedUser);
                }

                setUsers(updatedUsers);
                await usersModal.current?.dismiss();
              }
            },
          },
        ]}
      >
        {/* Modal Inputs  */}
        <div className="grid gap-4">
          <div className="text-sm">Info</div>
          {/* Name */}
          <Input
            label="Name"
            placeholder={"Name"}
            value={activeUser.name}
            onChange={(e: any) =>
              setActiveUser({ ...activeUser, name: e.target.value })
            }
            type={"text"}
            errorMessage={fieldErrors?.name}
          />
          {/* Email */}
          <Input
            label="Email"
            placeholder={"Email"}
            value={activeUser.email}
            onChange={(e: any) =>
              setActiveUser({ ...activeUser, email: e.target.value })
            }
            type={"text"}
            errorMessage={fieldErrors?.email}
          />
          {/* Roles */}
          <MultiSelect
            label="Roles"
            value={roles?.filter((entry: any) => {
              return activeUser?.roles?.includes(entry.value);
            })}
            options={roles}
            isMulti
            onChange={(selected: any) => {
              const activeUserCopy = JSON.parse(JSON.stringify(activeUser));

              activeUserCopy.roles = selected.map((item: any) => item.value);
              setActiveUser({ ...activeUserCopy });
            }}
            errorMessage={fieldErrors?.roles}
          />

          {/* Truck */}

          <MultiSelect
            label="Truck"
            value={truckOptions.find(
              findByValue(activeUser?.metadata?.source?.truck)
            )}
            options={truckOptions}
            onChange={(selected: any) => {
              const activeUserCopy = JSON.parse(JSON.stringify(activeUser));
              activeUserCopy.metadata.source.truck = selected.value;
              setActiveUser(activeUserCopy);
            }}
            creatable={true}
          />

          {/* Warehouse */}
          <MultiSelect
            label="Warehouse"
            value={filtersObject["warehouse"]?.entries
              .map(mapEntryToLabelValue)
              .find(findByValue(activeUser?.metadata?.source?.warehouse))}
            options={filtersObject["warehouse"]?.entries.map(
              mapEntryToLabelValue
            )}
            onChange={(selected: any) => {
              const activeUserCopy = JSON.parse(JSON.stringify(activeUser));
              activeUserCopy.metadata.source.warehouse = selected.value;
              setActiveUser(activeUserCopy);
            }}
          />

          {/* Route Code */}
          <Input
            label="Route Code"
            placeholder={"Route Code"}
            value={activeUser.metadata.source.routeCode}
            onChange={(e: any) => {
              const activeUserCopy = JSON.parse(JSON.stringify(activeUser));
              activeUserCopy.metadata.source.routeCode = e.target.value;
              setActiveUser(activeUserCopy);
            }}
            type={"text"}
            errorMessage={fieldErrors?.routeCode}
          />

          {/* Default Filters - Truck */}
          <div className="text-sm mt-4">Default App Filters</div>
          <MultiSelect
            isMulti
            value={truckOptions?.filter(filterSelectedEntries("truck"))}
            options={truckOptions}
            label="Trucks"
            onChange={(selected: any) => {
              const activeUserCopy = JSON.parse(JSON.stringify(activeUser));
              activeUserCopy.metadata.filters["truck"] = selected.map(
                (item: any) => item.value
              );
              setActiveUser(activeUserCopy);
            }}
          />

          {/* Default Filters - Warehouse */}
          <MultiSelect
            isMulti
            value={filtersObject["warehouse"]?.entries
              ?.filter(filterSelectedEntries("warehouse"))
              ?.map(mapEntryToLabelValue)}
            options={filtersObject["warehouse"]?.entries.map(
              mapEntryToLabelValue
            )}
            label="Warehouses"
            onChange={(selected: any) => {
              const activeUserCopy = JSON.parse(JSON.stringify(activeUser));
              activeUserCopy.metadata.filters["warehouse"] = selected.map(
                (item: any) => item.value
              );
              setActiveUser(activeUserCopy);
            }}
          />
          {/* Expire Session */}
          {!activeUser?.metadata?.sessionExpiration?.length &&
            activeUser?.metadata?.id && (
              <>
                <div className="text-sm mt-4">Other</div>

                <div
                  className={`grid items-center text-sm text-center font-semibold px-5 py-3.5 rounded-lg transition-all cursor-pointer text-black-50 bg-transparent border border-black-10 hover:bg-black-10`}
                  onClick={async () => {
                    setHasClickedExpireToken(true);

                    await userService.updateUser({
                      ...activeUser.metadata,
                      sessionExpiration: new Date().toISOString(),
                    });
                  }}
                >
                  {hasClickedExpireToken ? "Session Expired" : "Expire Session"}
                </div>
              </>
            )}
        </div>
      </Modal>
    </>
  );
};
