import {
  IonButton,
  IonAlert,
  useIonAlert,
  IonActionSheet,
  useIonViewDidEnter,
} from "@ionic/react";
import { useState } from "react";
import SVG from "react-inlinesvg";
import expandMore from "../../assets/svg/expandMore.svg";
import moreHoriz from "../../assets/svg/moreHoriz.svg";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  addDewar,
  removeProductLot,
  setProductSkipDelivery,
} from "../../redux/delivery";
import deliveryService from "../../service/deliveryService";
import { ButtonContainer } from "../deliveryNotice/ButtonContainer";

interface Props {
  title: string;
  children: React.ReactNode;
  bubbleContent?: React.ReactNode;
  deleteMessage?: string;
  archiveMessage?: string;
  deleteCallback?: () => void;
  archiveCallback?: () => void;
  id: string;
  data?: any;
  type: string;
  updateDewar?: (dewar: any) => void;
  dewar?: any;
  isAdding?: boolean;
}

export const DewarEntry: React.FC<Props> = ({
  title,
  children,
  bubbleContent,
  deleteMessage,
  archiveMessage,
  deleteCallback,
  archiveCallback,
  id,
  data,
  type,
  updateDewar,
  dewar,
  isAdding,
}) => {
  /* Redux */
  const delivery = useAppSelector((state: any) => state.delivery);
  const dispatch = useAppDispatch();

  /* Hooks */
  const [expanded, setExpanded] = useState(false);
  const [isActionSheetOpen, setIsActionSheetOpen] = useState(false);

  const [presentAlert] = useIonAlert();

  /* Handlers */
  const handleDelete = () =>
    presentAlert({
      header: deleteMessage,
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "Delete",
          role: "confirm",
          handler: deleteCallback,
        },
        {
          text: "Archive",
          role: "confirm",
          handler: archiveCallback,
        },
      ],
    });

  const handleExpand = () => setExpanded(!expanded);

  return (
    <div
      className={`py-6 grid grid-cols-1 justify-between items-center ${
        expanded ? " mb-6 px-5" : ""
      }`}
      id={id}
    >
      {expanded ? (
        <div
          className={`bg-white-100 rounded-xl grid grid-cols-[40px,minmax(calc(70%-40px),_1fr),minmax(calc(30%-40px),_1fr),44px] items-center`}
        >
          <div
            className={`rounded-full border -mt-1 border-1 border-black-10 w-10 h-10 flex justify-center items-center ${
              expanded ? "rotate-180" : "rotate-0"
            } transition cursor-pointer`}
            onClick={handleExpand}
          >
            <SVG
              src={expandMore}
              style={{ height: "24px", width: "24px", cursor: "pointer" }}
            />
          </div>
          <div className="ml-1">{title}</div>
          <div className="grid justify-end">
            {bubbleContent && (
              <div className="grid gap-2 grid-cols-[repeat(2,auto)] items-center text-black-100 text-sm bg-black-5 border border-black-10 rounded-full px-2.5 py-0.5">
                {bubbleContent}
              </div>
            )}
          </div>
          <div
            className="grid justify-center p-2 ml-1 bg-transparent hover:bg-black-10 transition rounded-full"
            onClick={() => setIsActionSheetOpen(true)}
          >
            <SVG src={moreHoriz} style={{ height: "24px", width: "24px" }} />
          </div>
        </div>
      ) : (
        <div
          className={`grid grid-cols-[minmax(calc(70%-40px),_1fr),120px] items-center border-b pb-4 border-b-black-10`}
        >
          <div className="ml-1">{title}</div>

          <ButtonContainer
            text={
              isAdding
                ? "Adding..."
                : dewar.status === "ARCHIVED"
                ? "Archiving"
                : "Measure"
            }
            className={`justify-center mt-[-4px] mr-[-20px]`}
            type="updateDewar"
            disabled={isAdding || dewar.status === "ARCHIVED"}
            onClick={() => {
              updateDewar && updateDewar(dewar);
            }}
          />
        </div>
      )}

      <div className={`${expanded ? "block" : "hidden"} transition`}>
        {children}
      </div>
      {/* Action Sheet for delete + No Delivery  */}
      <IonActionSheet
        header="Actions"
        isOpen={isActionSheetOpen}
        buttons={[
          {
            text: "Delete",
            role: "destructive",
            data: {
              action: "delete",
            },
          },
          ...(type === "PRODUCT"
            ? [
                {
                  text: data?.skipDelivery ? "Add to Delivery" : "No Delivery",
                  data: {
                    action: data?.skipDelivery
                      ? "add-to-delivery"
                      : "no-delivery",
                  },
                },
              ]
            : []),
          {
            text: "Cancel",
            role: "cancel",
            data: {
              action: "cancel",
            },
          },
        ]}
        onDidDismiss={() => setIsActionSheetOpen(false)}
        onWillDismiss={async ({ detail }) => {
          if (detail?.data?.action === "delete") {
            handleDelete();
          } else if (
            detail?.data?.action === "no-delivery" &&
            Object.keys(data).length
          ) {
            dispatch(
              setProductSkipDelivery({ productId: data.id, skipDelivery: true })
            );
            const updateProductInventory = {
              ...data,
              skipDelivery: true,
            };

            /* When marked as no delivery, we need to actually go blow the lots away on the product */
            const removeLotPromises = updateProductInventory.lots.map(
              async (lot: any) => {
                /* Remove from the application state */
                dispatch(
                  removeProductLot({ productId: data.id, lotId: lot.id })
                );

                /* Pouch */
                await deliveryService.removeLotFromProduct({
                  productInventory: updateProductInventory,
                  lot,
                });
              }
            );

            await Promise.all(removeLotPromises);
            await deliveryService.updateProductInventory(
              updateProductInventory
            );
          } else if (
            detail?.data?.action === "add-to-delivery" &&
            Object.keys(data).length
          ) {
            dispatch(
              setProductSkipDelivery({
                productId: data.id,
                skipDelivery: false,
              })
            );
            const updateProductInventory = { ...data, skipDelivery: false };

            await deliveryService.updateProductInventory(
              updateProductInventory
            );
          }
        }}
      ></IonActionSheet>
    </div>
  );
};
