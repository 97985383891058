import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  setLoads,
  setIsLoadsLoading,
  setHasReachedMaxPagination,
} from "../redux/loads";

import loadService from "../service/loadService";

const useFetchLoads = () => {
  const loads = useAppSelector((state) => state.loads);
  const dispatch = useAppDispatch();

  const fetchLoads = async () => {
    dispatch(setIsLoadsLoading(true));
    try {
      const fetchedLoads = await loadService.getLoads("", 20);
      dispatch(setLoads(fetchedLoads));
    } catch (error) {
      console.error("Error fetching loads:", error);
    } finally {
      dispatch(setIsLoadsLoading(false));
    }
  };

  const fetchMore = useCallback(async () => {
    const lastLoadOfBatch = loads?.loads?.[loads?.loads?.length - 1];
    const newLoads = await loadService.getLoads(lastLoadOfBatch?._id || "", 20); // Page archived deliveries 10 at a time
    dispatch(setIsLoadsLoading(false));

    if (!newLoads.length) {
      dispatch(setHasReachedMaxPagination(true));
    } else {
      dispatch(setLoads([...loads?.loads, ...newLoads]));
    }
  }, [loads]);

  return {
    isLoadsLoading: loads.isLoadsLoading,
    loads: loads.loads,
    setLoads: (data: any) => dispatch(setLoads(data)),
    fetchLoads,
    fetchMore,
    hasReachedMaxPagination: loads.hasReachedMaxPagination,
  };
};

export default useFetchLoads;
