import { Warehouse } from "../core/Warehouse";
import { ProductReview } from "../deliveryNotice/ProductReview";
import { DewarReview } from "../deliveryNotice/DewarReview";
interface DeliveryInformation {
  name: string;
  address: {
    city: string;
    line1: string;
    state: string;
  };
}

// TODO: these can probably reference our global interfaces?
interface Lot {
  serial: string;
  quantity: number;
}

interface Product {
  name: string;
  lots: Lot[];
}

interface Order {
  date: string;
  orderNumber: string;
  products: Product[];
}

interface Props {
  children?: React.ReactNode;
  deliveryInformation: DeliveryInformation;
  orders: any[]; // TODO update this interface to match the server
}

export const DeliveryHistory: React.FC<Props> = ({
  deliveryInformation,
  orders,
}) => {
  return (
    <div>
      <Warehouse
        title={deliveryInformation.name}
        address={`${deliveryInformation.address.line1}, ${deliveryInformation.address.city}, ${deliveryInformation.address.state}`}
      />
      <div className="border-t border-black-10 my-6 py-6 grid gap-2">
        {orders.map((order, index) => (
          <div className="grid mb-4" key={index}>
            <div className="text-black-50">
              {new Date(
                order.dateDelivered ? order.dateDelivered : order.dateCreated
              ).toLocaleDateString("en-us")}
            </div>
            <div className="mt-2 px-4 py-6 border border-black-10 rounded-lg">
              <div
                className="grid grid-cols-2 justify-between items-center border-b border-black-10 pb-4"
                key={index}
              >
                <div className="text-md text-black-100">Order number</div>
                <div className="text-md text-black-100 text-right">
                  {order.otherIds.orderId}
                </div>
              </div>
              {order.productInventories.length && (
                <ProductReview
                  title="Products"
                  products={order.productInventories}
                />
              )}
            </div>
            <div className="mt-2 px-4 py-6 border border-black-10 rounded-lg">
              {<DewarReview title="Dewars" dewars={order.dewarInventories} />}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
