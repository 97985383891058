import { useState, useEffect, useRef } from "react";
import { UsersContext } from "../../../contexts/UsersContext";
import { Button } from "../../../components/core/Button";
import { UsersView } from "../../../components/dashboard/views/UsersView";
import { Header } from "../../../components/dashboard/Header";
import { Layout } from "../../../components/dashboard/Layout";

/* We'll use this so we can track state on existing users or new users in the modal */
interface User {
  name: "";
  email: "";
  roles: [];
  truck: "";
  warehouse: "";
  metadata: {};
}
const defaultUserSchema: User = {
  name: "",
  email: "",
  roles: [],
  truck: "",
  warehouse: "",
  metadata: {
    source: {},
    filters: {},
  },
};

const validateFields = (activeUser: User) => {
  let errors: any = {};

  if (!activeUser.name.length) {
    errors.name = "Please enter a valid name.";
  }

  const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
  if (!emailRegex.test(activeUser.email)) {
    errors.email = "Please enter a valid email.";
  }

  if (activeUser.roles.length === 0) {
    errors.roles = "Please select at least one role.";
  }

  return errors;
};

const Users: React.FC = ({}) => {
  /* Hooks */
  const [activeUser, setActiveUser] = useState(defaultUserSchema);
  const [fieldErrors, setFieldErrors] = useState<any>({});
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);

  /* Refs */
  const usersModal = useRef<HTMLIonModalElement>(null);

  /* If a user has submitted, and there are errors, start validating on keydown */
  useEffect(() => {
    if (hasSubmitted) {
      const errors = validateFields(activeUser);
      setFieldErrors(errors);
    } else {
      setFieldErrors({});
    }
  }, [hasSubmitted, activeUser]);

  return (
    <Layout>
      {/* Header */}
      <Header
        title={"Users"}
        button={
          <Button
            className="text-blue-100 bg-blue-10 hover:bg-blue-25 grid grid-cols-[auto,auto] items-center gap-0.5"
            onClick={() => {
              setActiveUser(defaultUserSchema);
              usersModal.current?.present();
            }}
            icon="add"
          >
            <span>Add User</span>
          </Button>
        }
      />
      {/* Content */}
      <UsersContext.Provider
        value={{
          activeUser,
          setActiveUser,
          setHasSubmitted,
          fieldErrors,
          setFieldErrors,
          defaultUserSchema,
          validateFields,
          usersModal,
        }}
      >
        <UsersView />
      </UsersContext.Provider>
    </Layout>
  );
};

export default Users;
