import { createSlice, current } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {};

export const customerDewar = createSlice({
  name: "customerDewars",
  initialState,
  reducers: {
    setCustomerDewars: (state, action: PayloadAction<any>) => {
      state.dewars = action.payload;
    },

    addCustomerDewar: (state, action: PayloadAction<any>) => {
      if (!state.dewars) state.dewars = [];
      state.dewars = [
        ...state.dewars,
        ...[{ serial: action.payload.dewar.serial }],
      ];
    },
  },
});

export const { setCustomerDewars, addCustomerDewar } = customerDewar.actions;

export default customerDewar.reducer;
