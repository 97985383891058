import { createPortal } from "react-dom";
import React, { useState, useEffect, useRef } from "react";

interface Props {
  buttonContent: React.ReactNode;
  modal: React.ReactNode;
  width?: number;
  isOpen?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  onClose?: () => void;
}

const DropdownModal: React.FC<{
  modal: any;
  width: any;
  triggerRef: any;
  modalRef: any;
  isOpen: any;
}> = ({ modal, width, triggerRef = null, modalRef = null, isOpen = false }) => {
  /* Portals */
  const portalNode =
    document.getElementById("date-dropdown-portal") ||
    document.createElement("div");

  /* If the node doesn't exist, create it and append it to the body */
  if (!document.getElementById("date-dropdown-portal")) {
    portalNode.id = "date-dropdown-portal";
    document.body.appendChild(portalNode);
  }

  /* Hooks */
  const [dropdownPosition, setDropdownPosition] = useState({
    top: 0,
    left: 0,
  });

  /* Constants */
  const updateDropdownPosition = () => {
    if (triggerRef.current) {
      const rect = triggerRef.current.getBoundingClientRect();
      const dropdownWidth = width; // we already have this + need to to position properly
      setDropdownPosition({
        top: rect.top + rect.height,
        left: rect.left + rect.width - dropdownWidth,
      });
    }
  };

  useEffect(() => {
    if (isOpen) {
      updateDropdownPosition();
    }
  }, [isOpen]);

  const dropdownContent = (
    <div
      ref={modalRef}
      className={`absolute`}
      style={{
        width: `${width}px`,
        position: "absolute",
        top: dropdownPosition.top + window.scrollY,
        left: dropdownPosition.left,
        zIndex: 1000,
      }}
    >
      {modal}
    </div>
  );

  return createPortal(dropdownContent, portalNode);
};

const Dropdown: React.FC<Props> = ({
  buttonContent = "",
  modal = "",
  width = 0,
  isOpen = false,
  setIsOpen = () => {},
  onClose = () => {},
}) => {
  /* Refs */
  const dropdownRef = useRef<any>(null);
  const modalRef = useRef<any>(null);

  /* Constants */
  const handleClickOutside = (event: any) => {
    if (
      dropdownRef.current !== null &&
      !dropdownRef.current.contains(event.target) &&
      modalRef.current !== null &&
      !modalRef.current.contains(event.target)
    ) {
      onClose();
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="relative ">
      <button onClick={() => setIsOpen(!isOpen)}>{buttonContent}</button>
      {isOpen && (
        <DropdownModal
          modal={modal}
          width={width}
          triggerRef={dropdownRef}
          modalRef={modalRef}
          isOpen={isOpen}
        />
      )}
    </div>
  );
};

export default Dropdown;
