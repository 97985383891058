const lotModel = {
    singular: 'lot',
    plural: 'lots',
    relations: {
        product: {
            belongsTo: 'product'
        },
        // productInventories: {
        //     belongsTo: 'productInventory'
        // }
    }
}

const productModel = {
    singular: 'product',
    plural: 'products',
    relations: {
        lots: {
            hasMany: 'lots'
        },
        productInventories: {
            hasMany: 'productInventories'
        }
    }
}

module.exports = {productModel, lotModel};