import { IonFooter } from "@ionic/react";
import SVG from "react-inlinesvg";
import info from "../../assets/svg/info.svg";
import { Button } from "../core/Button";

interface Props {
  onInfoClick: () => void;
  onSubmitClick: () => void;
  orderId: string;
}

export const StickyFooter: React.FC<Props> = ({
  onInfoClick,
  onSubmitClick,
  orderId,
}) => {
  return (
    <IonFooter>
      <div className="w-full bg-white-100 text-black-100 grid grid-cols-2 justify-between items-center p-6">
        <div
          className="grid grid-cols-[auto,auto,auto] gap-1 justify-start items-center py-4"
          onClick={onInfoClick}
        >
          <span>Order</span>
          <span className="font-bold">{orderId}</span>
          <SVG
            src={info}
            style={{
              fill: "rgba(17,20,46,1)",
              height: "16px",
              width: "16px",
            }}
          />
        </div>
        <div className="flex justify-end">
          <Button
            className="text-white-100 bg-blue-100 hover:bg-blue-150 grid grid-cols-[auto,auto] items-center gap-0.5 px-8"
            onClick={onSubmitClick}
          >
            <span>Mark as delivered</span>
          </Button>
        </div>
      </div>
    </IonFooter>
  );
};
