import React, { useEffect } from "react";
import { isPlatform } from "@ionic/react";
import { useHistory, useLocation } from "react-router-dom";
import userService from "./service/userService";
import jwtDecode from "jwt-decode";
import deliveryService from "./service/deliveryService";

const AuthHandler: React.FC<any> = () => {
  /* Hooks */
  const history = useHistory();
  const location = useLocation<any>();

  /*
    Whenever a route changes, check the JWT + (if the user has an expiration on their session) 
    and boot the user out if it is expired. 
  */
  useEffect(() => {
    const checkAuth = async () => {
      const token = await userService.getAuthToken();
      const user = await userService.getCurrentUser();

      if (token !== undefined && token.value) {
        const decodedToken: any = jwtDecode(token.value);
        const currentTime = Date.now() / 1000;

        const invalidTokens =
          decodedToken.exp < currentTime || !user?.accessToken?.length;

        const invalidSession =
          user?.sessionExpiration?.length &&
          new Date(user?.sessionExpiration).getTime() < Date.now();

        const invalidUserRoles =
          !user?.roles?.length ||
          (isPlatform("ios") && !user?.roles?.includes("APP")) ||
          (!isPlatform("ios") && !user?.roles?.includes("DASHBOARD"));

        if (invalidTokens || invalidUserRoles || invalidSession) {
          await deliveryService.destroy();
          await userService.signout();
          history.push(`/login`);
        }
      } else {
        history.push(`/login`);
      }
    };
    checkAuth();
  }, [location.pathname]);

  return null;
};

export default AuthHandler;
