/* We copied this because ion-inputs weren't working how we wanted with the autocomplte components. It's probably a semi-easy fix, but this was easy and we had to be clutch */
import SVG from "react-inlinesvg";
import info from "../../assets/svg/info.svg";

interface Props {
  label: string;
  placeholder?: string;
  description?: string;
  value: any;
  onChange: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  disabled?: boolean;
  type?: string;
  min?: string;
  max?: string;
  className?: string;
  errorMessage?: string;
  inputMode?: string;
  autoFocus?: boolean;
}

export const AutocompleteInput: React.FC<Props> = ({
  label,
  placeholder,
  description,
  value,
  onChange,
  onBlur,
  disabled,
  type = "text",
  min,
  max,
  className = "",
  errorMessage = "",
  inputMode,
  autoFocus,
}) => {
  return (
    <div
      className={`grid p-5 border border-black-10 rounded-xl focus-within:border-black-25 ${
        disabled ? "opacity-50 bg-black-5 cursor-not-allowed" : ""
      } ${className}`}
    >
      <label className="grid grid-cols-[auto,auto] justify-start items-center gap-1">
        <span className="text-gray-50 text-xs">{label}</span>
        {description && description.length && (
          <SVG
            src={info}
            style={{
              fill: "rgba(17,20,46,0.5)",
              height: "14px",
              width: "14px",
            }}
          />
        )}
      </label>
      <input
        className={`bg-transparent focus:outline-0 mt-1 border-0 focus:border-0 p-0 w-full`}
        placeholder={placeholder}
        value={value}
        onChange={(e: any) => onChange(e)}
        onBlur={() => (onBlur ? onBlur() : null)}
        disabled={disabled}
        type={type}
        {...(min ? { min } : {})}
        {...(max ? { max } : {})}
      />
      {errorMessage && errorMessage.length && (
        <div className="mt-2 text-sm text-orange-100 bg-orange-10 px-3 py-2 rounded-lg">
          {errorMessage}
        </div>
      )}
    </div>
  );
};
