import { createSlice, current } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
  dewars: [],
  isDewarsLoading: false,
  hasReachedMaxPagination: false,
};

export const dewars = createSlice({
  name: "dewars",
  initialState,
  reducers: {
    setDewars: (state, action: PayloadAction<any>) => {
      state.dewars = action.payload;
    },
    setIsDewarsLoading: (state, action: PayloadAction<any>) => {
      state.isDewarsLoading = action.payload;
    },
    setHasReachedMaxPagination: (state, action: PayloadAction<any>) => {
      state.hasReachedMaxPagination = action.payload;
    },
    updateDewarInList: (state, action: PayloadAction<any>) => {
      const existingDewar = state.dewars.find(
        (dewar: any) => dewar.id === action.payload.id
      );

      if (existingDewar) {
        state.dewars = state.dewars.map((dewar: any) => {
          if (dewar.id === action.payload.id) {
            return action.payload;
          }
          return dewar;
        });
      } else {
        state.dewars = [...state.dewars, ...[action.payload]];
      }
    },
  },
});

export const {
  setDewars,
  setIsDewarsLoading,
  setHasReachedMaxPagination,
  updateDewarInList,
} = dewars.actions;

export default dewars.reducer;
