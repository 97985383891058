import SVG from "react-inlinesvg";
import add from "../../assets/svg/add.svg";
import { Button } from "../core/Button";

/* Interfaces */
interface Props {
  text: string;
  className?: string;
  onClick: () => void;
  icon?: string;
  type?: string;
  disabled?: boolean;
}

/* Helpers */
const mapTypeToClassName = (type: string, disabled: boolean) => {
  if (disabled) {
    return "text-gray-100 bg-gray-10";
  }
  switch (type) {
    case "primary":
      return "text-blue-100 bg-blue-10 hover:bg-blue-25 mt-4";
    case "secondary":
      return "bg-white text-black-50";
    case "updateDewar":
      return "text-blue-100 bg-blue-10 hover:bg-blue-25";
    default:
      return "";
  }
};

export const ButtonContainer: React.FC<Props> = ({
  text,
  className,
  onClick,
  icon = "",
  type = "primary",
  disabled = false,
}) => {
  return (
    <div className={`flex justify-start ${className}`}>
      <Button
        className={`${mapTypeToClassName(
          type,
          disabled
        )} grid grid-cols-[auto,auto] items-center gap-0.5`}
        onClick={onClick}
        icon={icon}
        type={type}
        disabled={disabled}
      >
        <span>{text}</span>
      </Button>
    </div>
  );
};
