import { useEffect, useState } from "react";
import { useIonAlert } from "@ionic/react";
import { SectionContainer } from "../SectionContainer";
import { DataTable } from "../DataTable";
import { Modal } from "../../core/Modal";
import { Input } from "../../core/Input";
import { MultiSelect } from "../../core/MultiSelect";
import { useDewarsContext } from "../../../contexts/DewarsContext";
import useDewars from "../../../hooks/useDewars";
import deliveryService from "../../../service/deliveryService";
import FileUpload from "../../core/FileUpload";
import SVG from "react-inlinesvg";
import fileIcon from "../../../assets/svg/file.svg";

export const DewarsView: React.FC = () => {
  /* Contexts */
  const {
    activeDewar,
    setActiveDewar,
    fieldErrors,
    setFieldErrors,
    setHasSubmitted,
    defaultDewarSchema,
    validateFields,
    dewarModal,
    exportToCsvModal,
    dewarFilters,
  } = useDewarsContext();

  /* Hooks */
  const {
    dewarsIsLoading,
    dewars,
    setDewars,
    fetchDewars,
    fetchMore,
    hasReachedMaxPagination,
    activeInput,
    setActiveInput,
    viewAllHistory,
    setViewAllHistory,
    fileName,
    setFileName,
  } = useDewars();
  const [presentAlert] = useIonAlert();

  const [historyData, sethistoryData] = useState<any[]>([]);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return `${String(date.getMonth() + 1).padStart(2, "0")}/${String(
      date.getDate()
    ).padStart(2, "0")}/${date.getFullYear()}`;
  };

  /* 
    Fetch dewars on filter change.
  */
  useEffect(() => {
    if (dewarFilters?.filters && Object.keys(dewarFilters?.filters)?.length) {
      const filters: any = {};

      const customerFilters = dewarFilters.filters.find(
        (filter: any) => filter.type === "customer"
      );
      if (customerFilters?.selectedValues.length) {
        filters.customerId = customerFilters.selectedValues;
      }

      const warehouseFilters = dewarFilters.filters.find(
        (filter: any) => filter.type === "warehouse"
      );
      if (warehouseFilters?.selectedValues.length) {
        filters.warehouseId = warehouseFilters.selectedValues;
      }

      const statusFilters = dewarFilters.filters.find(
        (filter: any) => filter.type === "dewar_status"
      );
      if (statusFilters?.selectedValues.length) {
        filters.statuses = statusFilters.selectedValues;
      }
      /* Fetch dewars with our selected filters */
      fetchDewars(filters);
    }
  }, [dewarFilters]);

  /* Helpers */
  const getCustomerName = (id: string) => {
    if (dewarFilters?.filters && Object.keys(dewarFilters?.filters)?.length) {
      return dewarFilters.filters
        .find((filter: any) => filter.type === "customer")
        ?.entries?.find((entry: any) => entry.value === id)?.display;
    }
    return id;
  };

  /* Helepers */
  const handleRowClick = async (row: any) => {
    setActiveDewar({
      ...row,
    });
    await sethistoryData(
      await deliveryService.getDewarHistory(row.metadata.id)
    );
    await dewarModal.current?.present();
  };

  const formatDewars = (dewarsList: any) => {
    return dewarsList?.map((dewar: any) => {
      return {
        name: dewar.serial,
        customer: getCustomerName(dewar.customer),
        actions: ["archive", "edit"],
        metadata: dewar,
      };
    });
  };

  const filtersObject: any =
    dewarFilters.filters && Object.keys(dewarFilters?.filters).length
      ? dewarFilters?.filters?.reduce(
          (acc: any, filter: any) => ({ ...acc, [filter.type]: filter }),
          {}
        )
      : {};

  const mapEntryToLabelValue = (entry: any) => ({
    label: entry.display,
    value: entry.value,
  });

  const findByValue = (value: string) => (type: any) => type.value === value;

  return (
    <>
      {/* Table */}
      <div className="mb-[20px]">
        <SectionContainer
          title={
            <div className="flex gap-1">
              <span>Dewars</span>
              <span className="text-black-50 font-medium">{dewars.length}</span>
            </div>
          }
          // headerButton={
          //   <SmallButton onClick={async() => await exportToCsvModal.current?.present()}>
          //     <>Export to CSV</>
          //   </SmallButton>
          // }
          className="px-0 pb-0!"
        >
          <DataTable
            data={formatDewars(dewars)}
            isLoading={dewarsIsLoading}
            hasReachedMaxPagination={hasReachedMaxPagination}
            fetchMore={() => fetchMore(dewarFilters)}
            roundHeader={false}
            onRowClick={handleRowClick}
            actionClickOptions={{
              archive: async (row: any) => {
                presentAlert({
                  header: "Are you sure you want to archive this dewar?",
                  buttons: [
                    {
                      text: "Cancel",
                      role: "cancel",
                    },
                    {
                      text: "Yes",
                      role: "confirm",
                      handler: async () => {
                        const archivedDewar = {
                          ...row.metadata,
                          ...{ customer: "" },
                        };

                        await deliveryService.addOrUpdateDewar(archivedDewar);

                        setDewars(
                          dewars.map((dewar: any) => {
                            if (dewar.id === row.metadata.id) {
                              return archivedDewar;
                            }
                            return dewar;
                          })
                        );
                      },
                    },
                  ],
                });
              },
              edit: (row: any) => handleRowClick(row),
            }}
          />
        </SectionContainer>
        {/* Modals */}
        <Modal
          ref={dewarModal}
          onWillDismiss={() => {
            setActiveDewar(defaultDewarSchema);
            setHasSubmitted(false);
            setActiveInput("");
          }}
          heading={activeDewar?.metadata?.id ? "Dewar Details" : "Add Dewar"}
          buttons={[
            {
              text: "Close",
              theme: "LIGHT",
              onClick: async () => await dewarModal.current?.dismiss(),
            },
            {
              text: "Save",
              theme: "DARK",
              onClick: async () => {
                setHasSubmitted(true);
                const errors = validateFields(activeDewar);
                setFieldErrors(errors);
                if (!Object.keys(errors).length) {
                  /* Construct a new object with the original pouch object + our updated fields */
                  const updatedDewar = {
                    ...activeDewar.metadata,
                    ...{
                      serial: activeDewar.name,
                      customer: activeDewar.metadata.customer,
                      status: "ACTIVE",
                    },
                  };
                  /* Update Pouch */
                  await deliveryService.addOrUpdateDewar(updatedDewar);

                  /* Also update the list UI to reflect the new change */
                  const updatedDewars = dewars.map((dewar: any) => {
                    if (dewar.id === updatedDewar.id) {
                      return updatedDewar;
                    }
                    return dewar;
                  });

                  /* If we're adding a new dewar, put them in the list */
                  if (!updatedDewar.id) {
                    updatedDewars.push(updatedDewar);
                  }
                  setDewars(updatedDewars);
                  await dewarModal.current?.dismiss();
                }
              },
            },
          ]}
        >
          <div className="grid gap-4">
            {/* Name */}
            {activeInput === "name" || !activeDewar?.metadata?.id ? (
              <Input
                label="Dewar Serial"
                placeholder={"Dewar Serial"}
                value={activeDewar?.name}
                onChange={(e: any) =>
                  setActiveDewar({ ...activeDewar, name: e.target.value })
                }
                type={"text"}
                errorMessage={fieldErrors?.name}
              />
            ) : (
              <div className="border-t border-b border-black-10 py-6">
                <div className="grid grid-cols-2 justify-between items-center">
                  <div className="flex flex-col">
                    <div className="text-md text-black-100 font-medium">
                      Dewar Serial
                    </div>
                    <div
                      className="text-md text-black-50 underline underline-offset-2 cursor-pointer"
                      onClick={() => setActiveInput("name")}
                    >
                      Edit
                    </div>
                  </div>
                  <div className="text-md text-black-100 text-right">
                    {activeDewar?.name}
                  </div>
                </div>
              </div>
            )}
            {/* Customer */}
            {activeInput === "customer" || !activeDewar?.metadata?.id ? (
              <MultiSelect
                label="Customer"
                value={filtersObject?.["customer"]?.entries
                  .map(mapEntryToLabelValue)
                  .find(findByValue(activeDewar?.metadata?.customer))}
                options={
                  filtersObject?.["customer"]?.entries?.length
                    ? [
                        ...filtersObject?.["customer"]?.entries
                          .map(mapEntryToLabelValue)
                          .sort((a: any, b: any) => {
                            if (a.label === "Archived") return 1;
                            if (b.label === "Archived") return -1;
                            return a.label.localeCompare(b.label);
                          }),
                      ]
                    : []
                }
                onChange={(selected: any) => {
                  const activeDewarCopy = JSON.parse(
                    JSON.stringify(activeDewar)
                  );
                  activeDewarCopy.metadata.customer = selected.value;
                  setActiveDewar(activeDewarCopy);
                }}
              />
            ) : (
              <div className="border-b border-black-10 pb-6">
                <div className="flex flex-col text-md text-black-100">
                  <div className="text-md text-black-100 font-medium">
                    Current Location
                  </div>
                  <div className="text-md text-black-100">
                    {activeDewar?.customer}
                  </div>
                  <div
                    className="text-md text-black-50 underline underline-offset-2 cursor-pointer"
                    onClick={() => setActiveInput("customer")}
                  >
                    Move Dewar
                  </div>
                </div>
              </div>
            )}
            {/* History */}
            {activeDewar?.metadata?.id && (
              <div>
                <div className="text-md text-black-100 font-medium">
                  History
                </div>
                {historyData
                  .slice(0, viewAllHistory ? historyData.length : 2)
                  .map((history, index) => (
                    <div key={index} className={`${index !== 0 ? "mt-4" : ""}`}>
                      <div className="text-md text-black-50">
                        {formatDate(history.dateUpdated)}
                      </div>
                      <div className="border border-black-10 p-4 mt-2 rounded-lg">
                        <div className="text-md text-black-100 font-medium">
                          Measurement
                        </div>
                        <div className="flex justify-between items-center text-md text-black-100">
                          <div>Starting Level</div>
                          <div>
                            {history.measurement?.starting != null
                              ? history.measurement?.starting
                              : "-"}
                          </div>
                        </div>
                        <div className="flex justify-between items-center text-md text-black-100">
                          <div>Ending Level</div>
                          <div>
                            {history.measurement?.added != null
                              ? history.measurement?.ending
                              : "-"}
                          </div>
                        </div>
                        <div className="flex justify-between items-center text-md text-black-100">
                          <div>Number of Cans</div>
                          <div>
                            {history.measurement?.cans != null
                              ? history.measurement?.cans
                              : "-"}
                          </div>
                        </div>
                        <div className="flex justify-between items-center text-md text-black-100">
                          <div>Notes</div>
                          <div>{history.notes}</div>
                        </div>
                        <div className="border-t border-black-10 my-4"></div>
                        <div className="text-md text-black-100">
                          #{history.delivery}
                          <div className="float-right">
                            {history.customer?.internalName}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                {!viewAllHistory && (
                  <div
                    className="text-md text-black-50 underline underline-offset-2 mt-2 cursor-pointer text-center py-4"
                    onClick={() => setViewAllHistory(true)}
                  >
                    View All
                  </div>
                )}
              </div>
            )}
            {/* Archive */}
            {activeDewar?.metadata?.id && (
              <div
                className={`grid items-center text-sm text-center font-semibold px-5 py-3.5 mt-1 rounded-lg transition-all cursor-pointer text-black-50 bg-transparent border border-black-10 hover:bg-black-10`}
                onClick={async () => {
                  const activeDewarCopy = JSON.parse(
                    JSON.stringify(activeDewar)
                  );
                  activeDewarCopy.metadata.status = "ARCHIVED";
                  const updatedDewar = {
                    ...activeDewarCopy.metadata,
                    ...{
                      customer: null,
                      status: "ARCHIVED",
                    },
                  };
                  /* Update Pouch */
                  await deliveryService.addOrUpdateDewar(updatedDewar);

                  /* Also update the list UI to reflect the new change */
                  const updatedDewars = dewars.map((dewar: any) => {
                    if (dewar.id === updatedDewar.id) {
                      return updatedDewar;
                    }
                    return dewar;
                  });

                  setDewars(updatedDewars);
                  await dewarModal.current?.dismiss();
                }}
              >
                Archive Dewar
              </div>
            )}
          </div>
        </Modal>
        <Modal
          ref={exportToCsvModal}
          onWillDismiss={() => {
            setActiveDewar(defaultDewarSchema);
            setHasSubmitted(false);
            setActiveInput("");
          }}
          heading="Import Dewars"
          buttons={[
            {
              text: "Close",
              theme: "LIGHT",
              onClick: async () => await exportToCsvModal.current?.dismiss(),
            },
            {
              text: "Save",
              theme: "DARK",
              onClick: async () => {
                console.log("Save import");
              },
            },
          ]}
        >
          {fileName?.length ? (
            <div className="flex items-center text-sm rounded-lg px-3 py-3 my-3 bg-black-5 cursor-pointer">
              <div className="bg-black-10 rounded-full mr-1">
                {" "}
                <SVG
                  src={fileIcon}
                  style={{
                    fill: "rgba(0,0,0,1)",
                    height: "16px",
                    width: "16px",
                    margin: "4px",
                  }}
                />
              </div>
              <span className="truncate">{fileName}</span>
            </div>
          ) : (
            <FileUpload onFileLoad={(fileName: any) => setFileName(fileName)} />
          )}
        </Modal>
      </div>
    </>
  );
};
