import { IonInput } from "@ionic/react";
import SVG from "react-inlinesvg";
import info from "../../assets/svg/info.svg";
import barcode from "../../assets/svg/barcode.svg";

interface Props {
  label: string;
  placeholder?: string;
  description?: string;
  value: any;
  onChange: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  disabled?: boolean;
  type?: any;
  min?: string;
  max?: string;
  className?: string;
  errorMessage?: string;
  autoFocus?: boolean;
  inputMode?: any;
  icon?: string;
}

export const Input: React.FC<Props> = ({
  label,
  placeholder,
  description,
  value,
  onChange,
  onBlur,
  disabled,
  type = "text",
  min,
  max,
  className = "",
  errorMessage = "",
  autoFocus = false,
  inputMode = "text",
  icon,
}) => {
  /* Helpers */
  const mapIconToSVG = (icon: string) => {
    switch (icon) {
      case "barcode":
        return barcode;
      default:
        return "";
    }
  };

  return (
    <div
      className={`grid px-4 py-3 border border-black-10 rounded-xl focus-within:border-black-25 ${
        disabled ? "bg-black-3 cursor-not-allowed text-black-100" : ""
      } ${className}`}
    >
      <label className="grid grid-cols-[auto,auto] justify-start items-center gap-1">
        <span className="text-gray-50 text-xs">{label}</span>
        {description && description.length && (
          <SVG
            src={info}
            style={{
              fill: "rgba(17,20,46,0.5)",
              height: "14px",
              width: "14px",
            }}
          />
        )}
      </label>
      <div className="relative flex items-center">
        <IonInput
          className={`z-0 bg-transparent focus:outline-0 mt-1 border-0 focus:border-0 p-0 w-full ${
            disabled ? "ion-input-disabled-override" : ""
          }`}
          placeholder={placeholder}
          aria-label={label}
          value={value}
          onInput={(e: any) => onChange(e)}
          onBlur={() => (onBlur ? onBlur() : null)}
          disabled={disabled}
          type={type}
          inputMode={inputMode}
          autofocus={autoFocus}
          {...(min ? { min } : {})}
          {...(max ? { max } : {})}
        />
        {icon && (
          <div className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-black-3 p-2 rounded">
            <SVG
              src={mapIconToSVG(icon)}
              style={{
                fill: "rgba(0,0,0,1)",
                height: "24px",
                width: "24px",
              }}
            />
          </div>
        )}
      </div>
      {errorMessage && errorMessage.length && (
        <div className="mt-2 text-sm text-orange-100 bg-orange-10 px-3 py-2 rounded-lg">
          {errorMessage}
        </div>
      )}
    </div>
  );
};
