const customerModel = {
    singular: 'customer',
    plural: 'customers',
    relations: {
        dewars: {
            hasMany: {
                type:'dewar',
                options: {
                    //async: true,
                    queryInverse: 'customer'
                }
            }
        },
        deliveries: {
            hasMany: {
                type:'delivery',
                options: {
                    // async: true
                    queryInverse: 'customer'
                }
            }
        }
    }
}

module.exports = { customerModel }  