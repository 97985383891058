import { createSlice, current } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
  shouldRefreshInbox: false,
  progress: 100,
  total: 100,
};

export const backgroundSync = createSlice({
  name: "backgroundSync",
  initialState,
  reducers: {
    setShouldRefreshInbox: (state, action: PayloadAction<any>) => {
      state.shouldRefreshInbox = action.payload;
    },
    setProgress: (state, action: PayloadAction<any>) => {
      state.progress = action.payload;
    },
    setTotal: (state, action: PayloadAction<any>) => {
      state.total = action.payload;
    },
  },
});

export const { setShouldRefreshInbox, setProgress, setTotal } =
  backgroundSync.actions;

export default backgroundSync.reducer;
