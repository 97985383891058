import { IonApp, setupIonicReact, isPlatform } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { StatusBar, Style } from "@capacitor/status-bar";
import SentryContainer from "./SentryContainer";
import Routes from "./Routes";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import BackgroundSync from "./BackgroundSync";
import AuthHandler from "./AuthHandler";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";

import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/global.css";
import "./theme/tailwind.css";

/* Set the default config to ios, since that's our target platform */

setupIonicReact({
  rippleEffect: false,
  mode: "ios",
  swipeBackEnabled: false,
  animated: isPlatform("ios") ? false : true,
});

/* Since our UI theme is only light, we'll make sure the toolbar looks correct regardless of the user's theme */
const setStatusBarStyleLight = async () => {
  if (isPlatform("ios")) {
    await StatusBar.setStyle({ style: Style.Light });
  }
};

setStatusBarStyleLight();

const App: React.FC = () => {
  return (
    <IonApp>
      <SentryContainer>
        <Provider store={store}>
          <BackgroundSync />
          <IonReactRouter>
            <AuthHandler />
            <Routes />
          </IonReactRouter>
        </Provider>
      </SentryContainer>
    </IonApp>
  );
};

export default App;
