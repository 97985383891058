import React, { useRef, useState } from "react";
import SVG from "react-inlinesvg";
import publishIcon from "../../assets/svg/publish.svg";

/* Define the props interface */
interface FileUploadProps {
  onFileLoad: (fileName: string) => void;
}

const FileUpload: React.FC<FileUploadProps> = ({ onFileLoad }) => {
  const defaultHoverStyle = { borderColor: "", backgroundColor: "" };
  const dropRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [hoverStyle, setHoverStyle] = useState(defaultHoverStyle);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setHoverStyle({ borderColor: "#64748b", backgroundColor: "#e2e8f0" });
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setHoverStyle(defaultHoverStyle);
  };

  const handleFiles = async (files: FileList) => {
    if (files.length > 0) {
      const file = files[0]; // We only support 1 file at a time for now

      // Make sure the file is a CSV or Excel file
      const acceptedFileTypes = [
        "text/csv",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/x-excel",
        "application/vnd.ms-excel",
      ];
      if (!acceptedFileTypes.includes(file.type)) {
        console.log(
          "Only CSV and Excel files are allowed. Invalid file type: ",
          file.type
        );
        return;
      }

      /* To store this in redux, we'll add relevant data to an object w a file URL */
      const fileURL = URL.createObjectURL(file);

      const fileData = {
        name: file.name,
        type: file.type,
        size: file.size,
        url: fileURL,
      };

      console.log(fileData);

      onFileLoad(file.name);

      // Clear the file input
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setHoverStyle(defaultHoverStyle);
    const files = e.dataTransfer.files;
    handleFiles(files);
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      handleFiles(files);
    }
  };

  return (
    <div
      className="bg-slate-gray-100 flex flex-col items-center justify-center py-10 border-dashed border border-black-25 rounded-sm"
      ref={dropRef}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onClick={handleClick}
      style={{
        borderColor: hoverStyle.borderColor,
        backgroundColor: hoverStyle.backgroundColor,
      }}
    >
      <input
        type="file"
        style={{ display: "none" }}
        ref={fileInputRef}
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        onChange={handleFileSelect}
      />
      <SVG
        src={publishIcon}
        style={{
          fill: "rgba(0,0,0,1)",
          height: "16px",
          width: "16px",
        }}
      />
      <div className="text-xs font-medium text-black-50 my-1">
        Upload Your File Here (CSV, or XSLX)
      </div>
      <div className="text-black-100 text-xs font-medium underline underline-offset-2">
        Browse
      </div>
    </div>
  );
};

export default FileUpload;
