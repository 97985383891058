import { useEffect } from "react";
import { SectionContainer } from "../SectionContainer";
import { DataTable } from "../DataTable";
import { Modal } from "../../core/Modal";
import { useCustomersContext } from "../../../contexts/CustomersContext";
import useCustomers from "../../../hooks/useCustomers";
import deliveryService from "../../../service/deliveryService";
import { MultiSelect } from "../../core/MultiSelect";

export const CustomersView: React.FC = () => {
  /* Contexts */
  const {
    activeCustomer,
    setActiveCustomer,
    fieldErrors,
    setFieldErrors,
    setHasSubmitted,
    defaultCustomerSchema,
    validateFields,
    customerModal,
    customerFilters,
  } = useCustomersContext();

  /* Hooks */
  const { customersIsLoading, customers, setCustomers, fetchCustomers } =
    useCustomers();

  /* Fetch customers on filter change. */
  useEffect(() => {
    if (
      customerFilters?.filters &&
      Object.keys(customerFilters?.filters)?.length
    ) {
      const custFilters = customerFilters.filters.find(
        (filter: any) => filter.type === "customer"
      );
      const filters = custFilters?.selectedValues?.length
        ? { customerId: custFilters.selectedValues }
        : {};

      fetchCustomers(filters);
    }
  }, [customerFilters, fetchCustomers]);

  /* Helpers */
  const handleRowClick = async (row: any) => {
    setActiveCustomer({ ...row });
    await customerModal.current?.present();
  };

  const formatEmails = (emails: any) => {
    const emailCount = emails?.length || 0;
    if (emailCount === 0) {
      return "-";
    } else if (emailCount === 1) {
      return emails[0];
    } else if (emailCount === 2) {
      return `${emails[0]} + 1 other`;
    } else {
      return `${emails[0]} + ${emailCount - 1} others`;
    }
  };

  const formatMultiSelectEmails = (emails: any) => {
    return (
      emails?.map((email: any) => ({
        value: email,
        label: email,
      })) || []
    );
  };

  const formatCustomers = (customersList: any) => {
    return customersList?.map((customer: any) => {
      return {
        customer: customer.internalName,
        emails: formatEmails(customer?.contactEmails || []),
        actions: ["edit"],
        metadata: customer,
      };
    });
  };

  const filtersObject: any =
    customerFilters.filters && Object.keys(customerFilters?.filters).length
      ? customerFilters?.filters?.reduce(
          (acc: any, filter: any) => ({ ...acc, [filter.type]: filter }),
          {}
        )
      : {};

  return (
    <>
      <div className="mb-[20px]">
        <SectionContainer
          title={
            <div className="flex gap-1">
              <span>Customers</span>
              <span className="text-black-50 font-medium">
                {customers.length}
              </span>
            </div>
          }
          className="px-0 pb-0!"
        >
          <DataTable
            data={formatCustomers(customers)}
            isLoading={customersIsLoading}
            roundHeader={false}
            onRowClick={handleRowClick}
            actionClickOptions={{
              edit: (row: any) => handleRowClick(row),
            }}
          />
        </SectionContainer>
        <Modal
          ref={customerModal}
          onWillDismiss={() => {
            setActiveCustomer(defaultCustomerSchema);
            setHasSubmitted(false);
          }}
          heading="Customer Emails"
          buttons={[
            {
              text: "Close",
              theme: "LIGHT",
              onClick: async () => await customerModal.current?.dismiss(),
            },
            {
              text: "Save",
              theme: "DARK",
              onClick: async () => {
                setHasSubmitted(true);
                const errors = validateFields(activeCustomer);
                setFieldErrors(errors);
                if (!Object.keys(errors).length) {
                  await deliveryService.updateCustomer(
                    activeCustomer?.metadata
                  );

                  const updatedCustomers = customers.map((customer: any) => {
                    if (customer.id === activeCustomer?.metadata?.id) {
                      return activeCustomer?.metadata;
                    }
                    return customer;
                  });

                  setCustomers(updatedCustomers);
                  await customerModal.current?.dismiss();
                }
              },
            },
          ]}
        >
          <div className="grid gap-4">
            <div className="border-b border-black-10">
              <div className="flex items-center mb-4">
                <div className="flex items-center justify-center bg-black-10 text-black-100 rounded-lg w-10 h-10 mr-2">
                  {activeCustomer?.customer?.charAt(0)?.toUpperCase()}
                </div>
                <span>{activeCustomer?.customer}</span>
              </div>
            </div>
            <MultiSelect
              label="Emails"
              isMulti
              value={formatMultiSelectEmails(
                activeCustomer?.metadata?.contactEmails
              )}
              options={[]}
              creatable={true}
              onChange={(selected) => {
                const formattedEmails = selected.map((s: any) => s.value);
                setActiveCustomer({
                  ...activeCustomer,
                  emails: formattedEmails,
                  metadata: {
                    ...activeCustomer.metadata,
                    contactEmails: formattedEmails,
                  },
                });
              }}
              noOptionsMessage="Start typing to add an email"
              placeholder="Type to add an email"
            />
          </div>
        </Modal>
      </div>
    </>
  );
};
