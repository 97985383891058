import { createSlice, current } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
  deliveries: [],
  isDeliveriesLoading: false,
  filteredDeliveries: {},
  hasReachedMaxPagination: false,
};

export const deliveries = createSlice({
  name: "deliveries",
  initialState,
  reducers: {
    setDeliveries: (state, action: PayloadAction<any>) => {
      state.deliveries = action.payload;
    },
    setIsDeliveriesLoading: (state, action: PayloadAction<any>) => {
      state.isDeliveriesLoading = action.payload;
    },
    setFilteredDeliveries: (state, action: PayloadAction<any>) => {
      state.filteredDeliveries = action.payload;
    },
    setHasReachedMaxPagination: (state, action: PayloadAction<any>) => {
      state.hasReachedMaxPagination = action.payload;
    },
    /*
        This one is a bit weird -- if we update a delivery, we want to update the source but also
        the filtered list in the mobile app 
    */
    updateDeliveryInList: (state, action: PayloadAction<any>) => {
      state.deliveries = state.deliveries?.map((delivery: any) => {
        if (delivery.id === action.payload.id) {
          return { ...delivery, ...action.payload };
        }
        return delivery;
      });

      state.filteredDeliveries = Object.fromEntries(
        Object.entries(state.filteredDeliveries).map(([key, array]: any) => [
          key,
          array.map((item: any) =>
            item.id === action.payload.id
              ? { ...item, ...action.payload }
              : item
          ),
        ])
      );
    },
  },
});

export const {
  setDeliveries,
  setIsDeliveriesLoading,
  setFilteredDeliveries,
  setHasReachedMaxPagination,
  updateDeliveryInList,
} = deliveries.actions;

export default deliveries.reducer;
