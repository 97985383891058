import { useEffect, useRef, useState } from "react";
import _ from "lodash";
import axios from "axios";
import { saveAs } from "file-saver";
import { SectionContainer } from "../SectionContainer";
import { SectionStatContainer } from "../SectionStatCallout";
import { BarChart } from "../BarChart";
import { DoughnutChart } from "../DoughnutChart";
import { DataTable } from "../DataTable";
import { Modal } from "../../core/Modal";
import { Loader } from "../../core/Loader";
import { SmallButton } from "../../core/SmallButton";
import { OrderReview } from "../../modals/OrderReview";
import { jsonToCSV } from "../../../lib";
import useDeliveries from "../../../hooks/useDeliveries";
import { useAppSelector } from "../../../redux/hooks";
import deliveryService from "../../../service/deliveryService";

/* Start ChartJS related helpers */
const getBarChartConfig = ({ labels, data }: any) => {
  return {
    labels: labels,
    datasets: [
      {
        label: "Deliveries",
        data: data,
        backgroundColor: "rgba(30,95,239, 1)",
        borderRadius: 4,
      },
    ],
    animations: {
      from: 0,
      to: 1,
    },
  };
};

const getDoughnutChartConfig = ({ labels, data, ratio }: any) => {
  let color = "#ED7E30";
  if (ratio > 0.5 && ratio < 0.9) {
    color = "#1E5FEF";
  } else if (ratio >= 0.9) {
    color = "#34D399";
  }
  return {
    labels,
    datasets: [
      {
        label: "Deliveries",
        data: data,
        backgroundColor: [color, "#EEEEEE"],
        borderColor: ["rgba(255, 255,255,1)", "rgba(255, 255,255, 1)"],
        borderWidth: 4,
        borderRadius: 4,
        cutout: "80%",
      },
    ],
    ratio,
  };
};

const getDaysBetween = (startDate: Date, endDate: Date): number => {
  const oneDay = 24 * 60 * 60 * 1000;
  return (
    Math.round(Math.abs((startDate.valueOf() - endDate.valueOf()) / oneDay)) + 1
  );
};

const getMonthsBetween = (startDate: Date, endDate: Date): number => {
  const yearDiff = endDate.getFullYear() - startDate.getFullYear();
  const monthDiff = endDate.getMonth() - startDate.getMonth();
  return yearDiff * 12 + monthDiff + 1;
};

const getLabels = (startDate: Date, endDate: Date, unit: string): string[] => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  if (unit === "MONTH") {
    const numberOfMonths = getMonthsBetween(startDate, endDate);
    return Array.from({ length: numberOfMonths }, (_, i) => {
      const newDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth() + i,
        1
      );
      return monthNames[newDate.getMonth()];
    });
  } else {
    const numberOfDays = getDaysBetween(startDate, endDate);
    return Array.from({ length: numberOfDays }, (_, i) => {
      const newDate = new Date(startDate);
      newDate.setDate(startDate.getDate() + i);
      return unit === "DAY"
        ? newDate.getDate().toString()
        : dayNames[newDate.getDay()];
    });
  }
};

/* End ChartJS related helpers */

const generateFileName = (name: string) => {
  const currentDate = new Date();
  const formattedDate = `${
    currentDate.getMonth() + 1
  }-${currentDate.getDate()}-${currentDate.getFullYear()}`.replace(
    /\b(\d)\b/g,
    "0$1"
  );

  return `${name}-${formattedDate}`;
};

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return `${String(date.getMonth() + 1).padStart(2, "0")}/${String(
    date.getDate()
  ).padStart(2, "0")}/${date.getFullYear()}`;
};

const getPercent = (value: any) => {
  if (value === undefined) return 0;
  return `${Math.round(parseFloat((value * 100).toFixed(2)))}%`;
};

export const DeliveriesView: React.FC = ({}) => {
  /* Hooks */
  const dashboardFilters = useAppSelector((state) => state.dashboardFilters);
  const [activeDelivery, setActiveDelivery] = useState<any>({});
  const [isDeliveryLoading, setIsDeliveryLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [totalDeliveries, setTotalDeliveries] = useState<any>({});
  const [deliveriesOnTarget, setDeliveriesOnTarget] = useState<any>({});
  const [isSingleExportLoading, setIsSingleExportLoading] = useState(false);
  const [fetchOptions, setFetchOptions] = useState<any>({
    statuses: ["DELIVERED", "CORRECTED"],
    sortDateKey: "dateDelivered",
  });
  const {
    deliveries,
    hasReachedMaxPagination,
    fetchInitialDeliveries,
    fetchMore,
    isDeliveriesLoading,
  } = useDeliveries();

  /* Refs */
  const orderReviewModal = useRef<HTMLIonModalElement>(null);

  /* Helpers */
  const exportSingleDelivery = async (delivery: any) => {
    setIsSingleExportLoading(true);
    try {
      const result = await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/deliveries/${delivery.id}/export`,
          {
            headers: {
              Authorization: `${process.env.REACT_APP_SERVICE_AUTH}`,
            },
          }
        )
        .then((r) => r.data);
      const blob = new Blob([result], {
        type: "text/csv;charset=utf-8",
      });
      saveAs(blob, `ceva-delivery-export-${delivery.id}` || "export.csv");
    } catch (err) {
      console.error(err);
    }
    setIsSingleExportLoading(false);
  };

  const hasLetters = (str: string) => {
    return str.match(/[a-z]/i);
  };

  /* Constants */
  const formattedDeliveries = deliveries.map((d: any) => ({
    order: hasLetters(d.orderNumber) ? d.orderNumber : `#${d.orderNumber}`,
    name: d.driver?.name || "",
    status: d.status,
    customer: d.title,
    deliveredOn: formatDate(d.dateDelivered),
    truck: d.truck,
    unitsDelivered: d.unitsDelivered,
    actions: ["download"],
  }));

  /* Watchers */
  /*
   Watch for changes in the dashboard filters and refetch deliveries with the new filters
  */
  useEffect(() => {
    if (dashboardFilters?.filters?.length) {
      const tabFilters = dashboardFilters.filters.reduce(
        (acc: any, item: any) => {
          acc[item.type] = item.selectedValues;
          return acc;
        },
        {}
      );

      const dateFilters = {
        afterDate: dashboardFilters.dateRange?.startDate,
        beforeDate: dashboardFilters.dateRange?.endDate,
      };
      const newFetchOptions = {
        ...fetchOptions,
        ...{ ...tabFilters, ...dateFilters },
      };
      setFetchOptions(newFetchOptions);
      fetchInitialDeliveries(newFetchOptions);
    }
  }, [dashboardFilters]);

  /*
    When we kickoff a mass export, we need to get the details for each deliver
    as well as fetch all pages for a given filter. 
  */
  useEffect(() => {
    if (isExporting && !hasReachedMaxPagination) {
      fetchMore(fetchOptions);
    }
    if (hasReachedMaxPagination && isExporting) {
      const deliveryIds = deliveries.map((d: any) => d.id);
      const getDeliveries = async (deliveryIds: any) => {
        const deliveries = await Promise.all(
          deliveryIds.map(async (deliveryId: string) => {
            const deliveryRes = await deliveryService.getDelivery(deliveryId);
            return deliveryRes;
          })
        );

        return deliveries;
      };

      /* Export the CSV */
      getDeliveries(deliveryIds).then((detailedDeliveries) => {
        jsonToCSV(
          detailedDeliveries,
          generateFileName("ceva-deliveries-export"),
          saveAs
        );
      });

      setIsExporting(false);
    }
  }, [isExporting, deliveries.length, hasReachedMaxPagination]);

  /*
    When the date range changes, we need to refetch the deliveries and update the chart data
  */
  useEffect(() => {
    // bar Chart data
    deliveryService
      .aggregateDeliveries({
        startDate: dashboardFilters.dateRange.startDateFull,
        endDate: dashboardFilters.dateRange.endDateFull,
        filters: dashboardFilters.filters,
      })
      .then((res: any) => {
        const transformApiData = (
          apiData: any,
          numberOfUnits: number,
          unit: string
        ): number[] => {
          const transformedData: number[] = new Array(numberOfUnits).fill(0);
          apiData?.rows?.forEach(({ day, total, onTarget }: any) => {
            const date = new Date(day);
            const startDate = new Date(
              dashboardFilters.dateRange.startDateFull
            );
            const index =
              unit === "MONTH"
                ? (date.getFullYear() - startDate.getFullYear()) * 12 +
                  date.getMonth() -
                  startDate.getMonth()
                : getDaysBetween(startDate, date) - 1;
            if (index >= 0 && index < numberOfUnits) {
              if (transformedData[index]) {
                transformedData[index] += total;
              } else {
                transformedData[index] = total;
              }
            }
          });

          return transformedData;
        };

        const startDate = new Date(dashboardFilters.dateRange.startDateFull);
        const endDate = new Date(dashboardFilters.dateRange.endDateFull);

        const daysBetween = getDaysBetween(startDate, endDate);
        const unit =
          daysBetween > 31 ? "MONTH" : daysBetween > 7 ? "DAY" : "WEEK";

        const numberOfUnits =
          unit === "MONTH" ? getMonthsBetween(startDate, endDate) : daysBetween;

        const transformedData = transformApiData(res, numberOfUnits, unit);
        const labels = getLabels(startDate, endDate, unit);

        setTotalDeliveries(
          getBarChartConfig({ labels, data: transformedData })
        );

        /* Create Donut Chart Config from agg data */
        const totalDeliveries = res.rows?.reduce(
          (acc: number, row: any) => acc + row.total,
          0
        );
        const totalOnTarget = res.rows?.reduce(
          (acc: number, row: any) => acc + row.onTarget,
          0
        );
        const ratio = totalOnTarget / totalDeliveries;

        setDeliveriesOnTarget(
          getDoughnutChartConfig({
            labels: ["On target (within 10%)", "Off target"],
            data: [
              totalOnTarget,
              ratio < 1 ? totalDeliveries - totalOnTarget : 0,
            ],
            ratio: ratio,
          })
        );
      });
  }, [dashboardFilters]);

  return (
    <>
      {/* Charts */}
      <div className="grid grid-cols-[1fr] lg:grid-cols-[calc(70%-10px),calc(30%-10px)] gap-[20px] mb-[20px]">
        {/* Bar Chart */}
        <SectionContainer
          title="Deliveries over time"
          className="pb-4 relative"
        >
          <SectionStatContainer
            number={_.sum(totalDeliveries?.datasets?.[0]?.data)}
            title="Total Deliveries"
          />
          {totalDeliveries?.labels?.length ? (
            <BarChart data={totalDeliveries} />
          ) : isDeliveriesLoading ? (
            <Loader />
          ) : null}
        </SectionContainer>

        {/* Donut Chart */}
        <SectionContainer title="Deliveries on target">
          {deliveriesOnTarget?.ratio || deliveriesOnTarget?.ratio === 0 ? (
            <SectionStatContainer
              number={getPercent(deliveriesOnTarget?.ratio)}
              title="On target"
            />
          ) : null}
          {deliveriesOnTarget?.labels?.length ? (
            <DoughnutChart data={deliveriesOnTarget} />
          ) : isDeliveriesLoading ? (
            <Loader />
          ) : null}
        </SectionContainer>
      </div>

      {/* Table */}
      <div className="mb-[20px]">
        <SectionContainer
          title={
            <div className="flex gap-1">
              <span>Deliveries</span>
              <span className="text-black-50 font-medium">
                {_.sum(totalDeliveries?.datasets?.[0]?.data)}
              </span>
            </div>
          }
          // headerButton={
          //   <SmallButton onClick={() => setIsExporting(true)}>
          //     <div className="flex gap-1 items-center justify-between">
          //       <div>Export to CSV</div>
          //       {isExporting ? <Loader /> : null}
          //     </div>
          //   </SmallButton>
          // }
          className="px-0"
        >
          <DataTable
            data={formattedDeliveries}
            roundHeader={false}
            hasReachedMaxPagination={hasReachedMaxPagination}
            fetchMore={() => fetchMore(fetchOptions)}
            isLoading={isDeliveriesLoading}
            onRowClick={async (row: any) => {
              await orderReviewModal.current?.present();
              /* find the ID of the row based on the order number by looking it up in the filterdDeliveries array */
              const deliveryId = deliveries.find(
                (d: any) => d.orderNumber === row.order.replace("#", "")
              )?.id;
              setIsDeliveryLoading(true);
              const deliveryRes = await deliveryService.getDelivery(deliveryId);
              setIsDeliveryLoading(false);
              setActiveDelivery(deliveryRes);
            }}
            actionClickOptions={{
              download: (row: any) => {
                exportSingleDelivery({
                  ...row,
                  id: row.order.replace("#", ""),
                });
              },
            }}
          />
        </SectionContainer>
        <Modal
          ref={orderReviewModal}
          heading="Order Review"
          onWillDismiss={() => {
            setIsDeliveryLoading(true);
          }}
          buttons={[
            {
              text: "Close",
              theme: "LIGHT",
              onClick: async () => await orderReviewModal.current?.dismiss(),
            },
            {
              text: isSingleExportLoading ? "Exporting..." : "Export as CSV",
              theme: "DARK",
              onClick: async () => {
                if (!isDeliveryLoading) {
                  exportSingleDelivery(activeDelivery);
                }
              },
            },
          ]}
        >
          {!isDeliveryLoading && Object.keys(activeDelivery).length ? (
            <OrderReview
              deliveryInformation={{
                name: activeDelivery.customer.name,
                address: activeDelivery.customer.address,
              }}
              gridData={[
                {
                  key: "Sales Order Number (S1)",
                  value: activeDelivery.otherIds.orderId,
                },
                {
                  key: "Pro Forma Number (SO)",
                  value: activeDelivery.otherIds.proForma,
                },
              ]}
              products={activeDelivery.productInventories}
              dewars={activeDelivery.dewarInventories}
            />
          ) : (
            <Loader />
          )}
        </Modal>
      </div>
    </>
  );
};
