import { useEffect, useRef, useState } from "react";
import SVG from "react-inlinesvg";
import { SectionContainer } from "../SectionContainer";
import { DataTable } from "../DataTable";
import { Modal } from "../../core/Modal";
import useUsers from "../../../hooks/useUsers";
import doneIcon from "../../../assets/svg/done.svg";
import userService from "../../../service/userService";

export const EmailView: React.FC = ({}) => {
  /* Hooks */
  const { users, isUsersLoading, fetchUsers, setUsers } = useUsers();
  const [activeUser, setActiveUser] = useState<any>(null);
  const [currentUser, setCurrentUser] = useState<any>({});

  /* Refs */
  const usersModal = useRef<HTMLIonModalElement>(null);

  /* Watchers */
  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      const user = await userService.getCurrentUser();
      setCurrentUser(user);
    };
    fetchCurrentUser();
  }, []);

  /* Helepers */
  const handleRowClick = async (row: any) => {
    setActiveUser({
      ...row,
    });
    await usersModal.current?.present();
  };

  const formatEmails = (emails: any, subscribedToAll: boolean) => {
    const emailCount = emails?.length || 0;

    if (subscribedToAll) {
      return (
        <div className="px-2 py-1 rounded-full bg-blue-100 text-white-100">
          All deliveries
        </div>
      );
    } else if (emailCount === 0) {
      return "-";
    } else if (emailCount === 1) {
      return emails[0];
    } else if (emailCount === 2) {
      return `${emails[0]} + 1 other`;
    } else {
      return `${emails[0]} + ${emailCount - 1} others`;
    }
  };

  const formatAlerts = (alerts: any) => {
    const alertCount = alerts?.length || 0;

    const firstAlert =
      alertTypes?.find((alert) => alerts?.[0] === alert?.type)?.name || "";

    if (alertCount === 0) {
      return "-";
    } else if (alertCount === 1) {
      return firstAlert;
    } else if (alertCount === 2) {
      return `${firstAlert} + 1 other`;
    } else {
      return `${firstAlert} + ${alertCount - 1} others`;
    }
  };

  const isSelected = (user: any, email: any) => {
    if (!user || !user.metadata || !user.metadata.subscribedTo) return false;
    return user.metadata.subscribedTo.includes(email);
  };

  const isGeneralAlertSelected = (user: any, alertType: any) => {
    if (!user || !user.metadata || !user.metadata.subscribedToTopics)
      return false;
    return user.metadata.subscribedToTopics.includes(alertType);
  };

  const alertTypes = [
    {
      type: "SYSTEM_ALERTS",
      name: "System Alerts",
      description: "Get alerts for things like file import issues",
    },
    {
      type: "UNLOAD_ALERTS",
      name: "Unload Alerts",
      description: "Get alerts when trucks get unloaded",
    },
  ];

  /* Helper Components */
  const EmailRow = ({ user, isSelected, type }: any) => {
    return (
      <div
        className={`flex gap-4 cursor-pointer items-center px-2 py-6 bg-white-100 border-b border-b-black-10 ${
          isSelected ? "bg-blue-10" : ""
        }`}
      >
        <div
          className={`rounded-full flex items-center justify-center ${
            isSelected ? "bg-blue-100" : "bg-black-10"
          } h-[40px] w-[40px]`}
        >
          {isSelected ? (
            <SVG src={doneIcon} className="h-[30px] w-[30px]" />
          ) : null}
        </div>
        {type === "ALL" ? (
          <div>Subscribe to all users</div>
        ) : (
          <div>
            <div>{user.name}</div>
            <div className="text-sm text-black-50">{user.email}</div>
          </div>
        )}
      </div>
    );
  };

  /* Constants */
  const formattedUsers = users.map((user: any) => {
    return {
      name: user.name,
      email: user.email,
      subscribedTo:
        formatEmails(user.subscribedTo, user.subscribedToAll) !== "-"
          ? formatEmails(user.subscribedTo, user.subscribedToAll)
          : formatAlerts(user?.subscribedToTopics) || [],
      actions: ["edit"],
      metadata: user,
    };
  });

  return (
    <>
      {/* Table */}
      <div className="mb-[20px]">
        <SectionContainer className="px-0 pb-0!">
          <DataTable
            data={formattedUsers}
            isLoading={isUsersLoading}
            onRowClick={async (row: any) => handleRowClick(row)}
            actionClickOptions={{
              edit: (row: any) => {
                handleRowClick(row);
              },
            }}
          />
        </SectionContainer>
      </div>
      {/* Modal */}
      <Modal
        ref={usersModal}
        heading="Edit Email Settings"
        onWillDismiss={() => {
          setActiveUser({});
        }}
        buttons={[
          {
            text: "Close",
            theme: "LIGHT",
            onClick: async () => await usersModal.current?.dismiss(),
          },
          {
            text: "Save",
            theme: "DARK",
            onClick: async () => {
              /* Construct a new object with the original pouch object + our updated fields */
              const updatedUser = {
                ...activeUser.metadata,
                ...{
                  subscribedTo: activeUser.metadata.subscribedTo,
                  subscribedToAll: activeUser.metadata.subscribedToAll || false,
                },
              };
              await userService.updateUser(updatedUser);
              /* Also update the list UI to reflect the new change */
              const updatedUsers = users.map((user: any) => {
                if (user.id === updatedUser.id) {
                  return updatedUser;
                }
                return user;
              });

              setUsers(updatedUsers);
              await usersModal.current?.dismiss();
            },
          },
        ]}
      >
        {/* Modal Content */}
        <div>
          <div className="mb-6">
            <div className="font-bold text-lg text-black-100">
              General Alerts
            </div>
            {alertTypes.map((alert, index) => (
              <div
                key={index}
                onClick={() => {
                  const alreadySelected = isGeneralAlertSelected(
                    activeUser,
                    alert?.type
                  );

                  const updatedSubscribedToTopics = alreadySelected
                    ? activeUser.metadata.subscribedToTopics.filter(
                        (topic: any) => topic !== alert?.type
                      )
                    : [
                        ...(activeUser.metadata.subscribedToTopics || []),
                        alert?.type,
                      ];

                  setActiveUser({
                    ...activeUser,
                    metadata: {
                      ...activeUser.metadata,
                      subscribedToTopics: updatedSubscribedToTopics,
                    },
                  });
                }}
              >
                <EmailRow
                  user={{ name: alert.name, email: alert.description }}
                  isSelected={isGeneralAlertSelected(activeUser, alert?.type)}
                />
              </div>
            ))}
          </div>

          <div className="font-bold text-lg text-black-100">
            Delivery Alerts
          </div>

          <div
            onClick={() => {
              setActiveUser({
                ...activeUser,
                metadata: {
                  ...activeUser.metadata,
                  subscribedToAll: !activeUser.metadata.subscribedToAll,
                  subscribedTo: [],
                },
              });
            }}
          >
            <EmailRow
              type="ALL"
              isSelected={activeUser?.metadata?.subscribedToAll || false}
            />
          </div>
          {/* Don't include the logged in user in this list, they always get emails for themselves */}
          {users
            .filter(
              (user: any) =>
                activeUser?.metadata?.id !== currentUser.id ||
                user.id !== currentUser.id
            )
            .map((user: any, index: any) => (
              <div
                key={index}
                onClick={() => {
                  const alreadySelected = isSelected(activeUser, user.email);
                  const updatedSubscribedTo = alreadySelected
                    ? activeUser.metadata.subscribedTo.filter(
                        (email: any) => email !== user.email
                      )
                    : [...(activeUser.metadata.subscribedTo || []), user.email];

                  setActiveUser({
                    ...activeUser,
                    metadata: {
                      ...activeUser.metadata,
                      subscribedTo: updatedSubscribedTo,
                      ...(updatedSubscribedTo.length && {
                        subscribedToAll: false,
                      }),
                    },
                  });
                }}
              >
                <EmailRow
                  user={user}
                  isSelected={isSelected(activeUser, user.email)}
                />
              </div>
            ))}
        </div>
      </Modal>
    </>
  );
};
