import { totalmem } from "os";

// TODO: these can probably reference our global interfaces?

interface Dewar {
  dewar: {
    serial: string;
  };
  measurement: any;
}

interface Props {
  dewars: Dewar[];
  title: string;
}

export const DewarReview: React.FC<Props> = ({ title, dewars }) => {
  return (
    <>
      <div className="text-md text-black-100 mt-4 mb-4">{title}</div>
      {dewars.map((dewarInventory: any, dewarIndex: any) => (
        <div className="my-4 last:mb-0" key={dewarIndex}>
          <div className="mb-1">{dewarInventory?.dewar?.serial}</div>
          <div
            className="grid grid-cols-2 justify-between items-center gap-0.25 mb-0.5"
            key={dewarInventory?.serial}
          >
            <div className="text-black-50">Starting</div>
            <div className="text-black-50 text-right">
              {/* TODO - these data models look different */}
              {dewarInventory?.measurement?.starting || 0}
            </div>
            <div className="text-black-50">Ending</div>
            <div className="text-black-50 text-right">
              {dewarInventory?.measurement?.ending || 0}
            </div>
            <div className="text-black-50">Cans</div>
            <div className="text-black-50 text-right">
              {dewarInventory?.measurement?.cans || 0} cans
            </div>
            <div className="text-black-50">Notes</div>
            <div className="text-black-50 text-right">
              {dewarInventory?.notes || ""}
            </div>
          </div>
          {/* ))} */}
        </div>
      ))}
    </>
  );
};
