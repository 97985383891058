import { createSlice, current } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {};

export const truckFilters = createSlice({
  name: "truckFilters",
  initialState,
  reducers: {
    setTruckFilters: (state, action: PayloadAction<any>) => {
      state.filters = action.payload;
    },

    setTruckFilter: (state, action: PayloadAction<any>) => {
      state.filters = state?.filters.map((filter: any) => {
        if (filter.type === action.payload.selectedFilter.type) {
          filter.value = action.payload.value;
        }
        return filter;
      });
    },
  },
});

export const { setTruckFilters, setTruckFilter } = truckFilters.actions;

export default truckFilters.reducer;
