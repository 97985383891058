import {
  IonContent,
  IonPage,
  useIonViewDidEnter,
  useIonViewDidLeave,
} from "@ionic/react";
import { useRef, useState } from "react";
import _ from "lodash";
import { useHistory, useParams } from "react-router";
import {
  BarcodeScanner,
  SupportedFormat,
} from "@capacitor-community/barcode-scanner";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import ReactDOM from "react-dom";
import { Modal } from "../../components/core/Modal";
import { Capacitor } from "@capacitor/core";
import { Input } from "../../components/core/Input";
import service from "../../service/deliveryService";
import { addDewar } from "../../redux/delivery";
import { addCustomerDewar } from "../../redux/customerDewar";
import SVG from "react-inlinesvg";
import close from "../../assets/svg/close.svg";

// TODO: explain
/* Portals must be instantiated outside of the component */
const portalDiv = document.createElement("div");
portalDiv.setAttribute("id", "portal-div");
portalDiv.classList.add(
  "absolute",
  "w-16",
  "h-16",
  "bg-white-100",
  "!z-50",
  "bottom-4", // Adjust to slightly above the bottom of the screen
  "mx-auto",
  "left-0",
  "right-0",
  "rounded-full",
  "flex",
  "justify-center",
  "items-center",
  "cursor-pointer",
  "leading-3",
  "hidden"
);
document.documentElement.appendChild(portalDiv);

const DewarScanner: React.FC = () => {
  /* Redux */
  const delivery = useAppSelector((state) => state.delivery);

  const dispatch = useAppDispatch();

  /* Refs */
  const deliveryInformationModal = useRef<HTMLIonModalElement>(null);
  const deliveryHistoryModal = useRef<HTMLIonModalElement>(null);
  const orderReviewModal = useRef<HTMLIonModalElement>(null);
  const contentRef = useRef<any>(null);

  /* Hooks */
  const history = useHistory();
  const { deliveryId } = useParams<{ deliveryId?: string }>();

  const [scanData, setScanData] = useState<string | null>(null);

  const isWeb = !Capacitor.isNativePlatform();

  const closeScanner = () => {
    documentBody?.classList.remove("scanner-active");
    portalDiv.classList.add("hidden");
    BarcodeScanner.showBackground();
    history.goBack();
  };

  const onScan = () => {
    dewarModal.current?.present();

    portalDiv.classList.add("hidden");
    documentBody?.classList.remove("scanner-active");
    BarcodeScanner.showBackground();
  };

  const startScan = async () => {
    // if (isWeb) {
    //   setTimeout(() => {
    //     setScanData("542542"); // Set the test value
    //     onScan();
    //   }, 1000); // Simulate a delay
    // } else {
    /* Always check the camera permissions */
    await BarcodeScanner.checkPermission({ force: true });

    /* Start scanning */
    await BarcodeScanner.startScanning(
      {
        targetedFormats: [
          SupportedFormat.CODE_128,
          SupportedFormat.CODE_39,
          SupportedFormat.CODE_93,
          SupportedFormat.EAN_13,
          SupportedFormat.EAN_8,
          SupportedFormat.UPC_A,
          SupportedFormat.UPC_E,
          SupportedFormat.ITF,
          SupportedFormat.DATA_MATRIX,
          SupportedFormat.PDF_417,
          SupportedFormat.AZTEC,
        ],
      },
      (result) => {
        if (result.hasContent) {
          setScanData(result?.content);
          BarcodeScanner.pauseScanning();
          onScan();
        }
      }
    );
    // }
  };

  const dewarModal = useRef<HTMLIonModalElement>(null);

  /* Ionic lifecylce hooks */
  /* 
    When we open this view, we always want to start the camera, and when we leave, we always
    want to make sure it turns off
  */
  useIonViewDidEnter(() => {
    startScan();

    // When we route to the page
    portalDiv.classList.remove("hidden");
    documentBody?.classList.add("scanner-active");
    BarcodeScanner.hideBackground();
  });

  useIonViewDidLeave(() => {
    BarcodeScanner.stopScan();
    // When we route away
    portalDiv.classList.add("hidden");
    documentBody?.classList.remove("scanner-active");
    BarcodeScanner.showBackground();
  });

  // todo ew
  const documentBody = document.querySelector("body");

  return (
    <IonPage>
      <IonContent ref={contentRef} onIonScroll={() => {}}>
        <Modal
          heading={`Dewar Scanner`}
          ref={dewarModal}
          onWillDismiss={() => {
            // same func as viewDidEnter, move to a reusable function later
            portalDiv.classList.remove("hidden");
            documentBody?.classList.add("scanner-active");
            BarcodeScanner.hideBackground();
          }}
          buttons={[
            {
              text: "Close",
              theme: "LIGHT",
              onClick: async () => await dewarModal.current?.dismiss(),
            },
            {
              text: "Save",
              theme: "DARK",
              onClick: async () => {
                if (deliveryId?.length && scanData?.length) {
                  /*
                    Check if the dewar with the given serial already exists in dewarInventories.
                    If it doesn't, add it to the delivery. Otherwise, we can just close the modal 
                    because its already there
                  */
                  const dewarExists = delivery.dewarInventories.some(
                    (dewarInventory: any) =>
                      dewarInventory.dewar.serial === scanData
                  );

                  if (!dewarExists) {
                    /* Pouch updates */
                    const serviceRes = await service.addDewarToDelivery({
                      deliveryId: deliveryId,
                      dewar: { serial: scanData },
                    });
                    /* Redux updates */
                    if (serviceRes) {
                      dispatch(
                        addDewar({
                          dewarInventory: serviceRes,
                        })
                      );
                      dispatch(addCustomerDewar(serviceRes));
                    }
                  }
                  await dewarModal.current
                    ?.dismiss()
                    .then(() => closeScanner());
                }
              },
            },
          ]}
        >
          <Input
            label="Serial number"
            value={scanData || ""}
            icon="barcode"
            onChange={(e: any) => setScanData(e.target.value || scanData)}
          />
          <div className="px-4 py-1.5 bg-green-light-10 text-green-dark-100 font-medium text-sm rounded text-center mt-2.5">
            Barcode found!
          </div>
        </Modal>
        {/* Close button  */}
        {ReactDOM.createPortal(
          <div>
            <div
              onClick={() => {
                closeScanner();
              }}
            >
              <div className="ion-icon-scanner-override">
                <SVG
                  src={close}
                  style={{
                    fill: "rgba(0,0,0,1)",
                    height: "20px",
                    width: "20px",
                  }}
                />
              </div>
            </div>
          </div>,
          portalDiv
        )}
      </IonContent>
    </IonPage>
  );
};

export default DewarScanner;
