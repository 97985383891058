import Select, { StylesConfig, components } from "react-select";
import Creatable from "react-select/creatable";
/* Interfaces */
interface Props {
  options: { value: string; label: string; selected?: boolean }[];
  label: string;
  isMulti?: boolean;
  value?: any;
  onChange?: (selected: any) => void;
  creatable?: boolean;
  errorMessage?: string;
  placeholder?: string;
  noOptionsMessage?: string;
}

type OptionType = {
  value: string;
  label: string;
};

const selectStyles: StylesConfig<OptionType, true> = {
  control: (provided, state) => ({
    ...provided,
    padding: "16px 8px",
    borderRadius: "12px",
    border: state.isFocused
      ? "1px solid rgba(17,20,46,0.25)"
      : "1px solid rgba(17,20,46,0.1)",
    boxShadow: "none",
    outline: "none",
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: "rgba(30,95,239,0.1)",
    padding: "4px 8px",
    borderRadius: "12px",
  }),

  multiValueRemove: (provided, state) => {
    return {
      ...provided,
      ":hover": {
        backgroundColor: "rgba(30,95,239,0.1)",
        color: "inherit",
        borderRadius: "12px",
      },
    };
  },
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "rgba(30,95,239,0.25)" : "white",
    color: "rgba(17,20,46,1)",
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none",
  }),
};

/* Custom Select elements */
const CustomMultiValueRemove = (props: any) => {
  return (
    <components.MultiValueRemove {...props}>
      <svg
        width="12"
        height="12"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* Add your custom SVG icon path here */}
        <path
          d="M18 6L6 18"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 6L18 18"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </components.MultiValueRemove>
  );
};

const CustomClearIndicator = (props: any) => {
  return (
    <components.ClearIndicator {...props}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* Material Design clear SVG icon */}
        <path
          d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
          fill="currentColor"
        />
      </svg>
    </components.ClearIndicator>
  );
};

const CustomDropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 96 960 960"
      >
        <path d="M480 711 240 471l43-43 197 198 197-197 43 43-240 239Z" />
      </svg>
    </components.DropdownIndicator>
  );
};
const CustomValueContainer = (label: string) => (props: any) => {
  const { children, getValue } = props;

  return (
    <div>
      <span
        style={{
          display: "block",
          color: "rgba(17,20,46,1)",
          fontSize: "12px",
          margin: "0 0 0 8px",
        }}
      >
        {label}
      </span>
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
    </div>
  );
};

export const MultiSelect: React.FC<Props> = ({
  options,
  label,
  value = "",
  isMulti = false,
  onChange,
  creatable = false,
  errorMessage = "",
  placeholder = "Select",
  noOptionsMessage = "No options",
}) => {
  if (creatable) {
    return (
      <>
        <Creatable
          options={options}
          {...(isMulti ? { isMulti } : {})}
          styles={selectStyles}
          value={value}
          onChange={onChange}
          components={{
            MultiValueRemove: CustomMultiValueRemove,
            ClearIndicator: CustomClearIndicator,
            DropdownIndicator: CustomDropdownIndicator,
            ValueContainer: CustomValueContainer(label),
          }}
          placeholder={placeholder}
          noOptionsMessage={() => noOptionsMessage}
        />
        {errorMessage && errorMessage.length && (
          <div className="mt-2 mb-2 text-sm text-orange-100 bg-orange-10 px-3 py-2 rounded-lg">
            {errorMessage}
          </div>
        )}
      </>
    );
  }
  return (
    <>
      <Select
        options={options}
        {...(isMulti ? { isMulti } : {})}
        styles={selectStyles}
        value={value}
        onChange={onChange}
        components={{
          MultiValueRemove: CustomMultiValueRemove,
          ClearIndicator: CustomClearIndicator,
          DropdownIndicator: CustomDropdownIndicator,
          ValueContainer: CustomValueContainer(label),
        }}
      />
      {errorMessage && errorMessage.length && (
        <div className="mb-2 text-sm text-orange-100 bg-orange-10 px-3 py-2 rounded-lg">
          {errorMessage}
        </div>
      )}
    </>
  );
};
