interface Props {
  title?: string | React.ReactNode;
  children: React.ReactNode;
  headerButton?: React.ReactNode;
  className?: string;
}

export const SectionContainer: React.FC<Props> = ({
  title,
  children,
  headerButton = null,
  className = "",
}) => {
  return (
    <div className="rounded-xl border border-black-10 chart-js">
      {(title || headerButton) && (
        <div className="p-5 py-4 pl-6 border-b border-b-black-10 flex items-center justify-between">
          <div className="font-semibold">{title}</div>
          {headerButton}
        </div>
      )}
      <div className={`px-6 ${className}`}>{children}</div>
    </div>
  );
};
