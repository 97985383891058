import { TextArea } from "../core/TextArea";
import { Warehouse } from "../core/Warehouse";
import { GridData } from "../deliveryNotice/GridData";
import { setDeliveryInstructions } from "../../redux/delivery";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import deliveryService from "../../service/deliveryService";

interface Props {
  isOpen?: boolean;
}

export const DeliveryInformation = () => {
  /*
    I moved redux into this component cause I was having issues with the component re-rendering every prop
    change...but Also, it might have been bc the service didn't support updating the delivery instructions
    so, we can revisit later -- and we need to support delivery instruction update here
  */

  /* Redux */
  const delivery = useAppSelector((state) => state.delivery);
  const dispatch = useAppDispatch();

  /* Helpers */
  const capitalize = (word: string) => {
    return `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
  };

  return (
    <div>
      <Warehouse
        title={delivery.customer.name}
        address={`${delivery?.customer?.address?.line1}, ${delivery?.customer?.address?.city}, ${delivery?.customer?.address?.state}`}
      />
      <GridData
        data={[
          { key: "Started by", value: delivery?.driver?.name },
          {
            key: "Sales Order Number (S1)",
            value: delivery?.otherIds?.orderId,
          },
          {
            key: "Pro Forma Number (SO)",
            value: delivery?.otherIds?.proForma,
          },
          ...[
            delivery?.status === "DELIVERED" || delivery?.status === "CORRECTED"
              ? {
                  key: "Delivered on",
                  value: "",
                }
              : {},
          ],

          {
            key: "Status",
            value: capitalize(
              delivery?.status?.toLowerCase().replace("_", " ")
            ),
          },
        ]}
      />

      {/* Special Instructions, we have to use a plain textarea here bc the ionic textarea doesn't focus right in a modal  */}
      <div>
        <TextArea
          label="Special Instructions"
          placeholder={"Add special instructions"}
          description=""
          value={delivery?.customer?.deliveryInstructions}
          onChange={(e: any) => {
            dispatch(setDeliveryInstructions({ value: e.target.value }));
            deliveryService.updateCustomer({
              ...delivery?.customer,
              deliveryInstructions: e.target.value,
            });
          }}
        />
      </div>
    </div>
  );
};
