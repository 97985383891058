import { useEffect, useState } from "react";
import { Layout } from "../../../components/dashboard/Layout";
import { Header } from "../../../components/dashboard/Header";
import { Filters } from "../../../components/core/Filters";
import { DatePicker } from "../../../components/dashboard/DatePicker";
import { Button } from "../../../components/core/Button";
import { DeliveriesView } from "../../../components/dashboard/views/DeliveriesView";
import {
  setDashboardFilters,
  setDashboardFilter,
  setDashboardDateRange,
} from "../../../redux/dashboardFilters";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import deliveryService from "../../../service/deliveryService";
import { useIonAlert } from "@ionic/react";
import loadService from "../../../service/loadService";

const Deliveries: React.FC = ({}) => {
  /* Redux */
  const dispatch = useAppDispatch();
  const dashboardFilters = useAppSelector((state) => state.dashboardFilters);
  const deliveryIds = useAppSelector((state) =>
    state.deliveries?.deliveries?.map((d: any) => d.id)
  );

  /* Hooks */
  const [presentAlert] = useIonAlert();
  const [isExporting, setIsExporting] = useState<boolean>(false);

  /* Effects */
  /* Fetch filters on mount */
  useEffect(() => {
    deliveryService
      .getFilters({
        exclude: ["truck_load_status", "truck_empty_status", "dewar_status"],
      })
      .then((res) => {
        dispatch(
          setDashboardFilters(
            res.map((filter: any) => ({
              ...filter,
              value: filter.value || "",
              selectedValues: [],
            }))
          )
        );
      });
  }, []);

  /* Handlers */
  const exportDeliveries = async (dashboardFilters: any) => {
    setIsExporting(true);
    let result = await deliveryService.exportDeliveries(deliveryIds);
    await presentAlert({
      header: result.status < 400 ? "Export started" : "Export error",
      message:
        result.status < 400
          ? "Watch your email inbox for the completed export as an attachment."
          : result?.data?.message || "Export could not be completed",
      buttons: [
        {
          text: "Close",
          role: "cancel",
        },
      ],
    });
    setIsExporting(false);
  };

  return (
    <Layout>
      {/* Header */}
      <Header
        title={"Deliveries"}
        button={
          <Button
            className={`${
              isExporting ? "bg-black-50" : "bg-blue-100 hover:bg-blue-150"
            } text-white-100  grid grid-cols-[auto,auto] items-center gap-0.5 !py-3.5`}
            onClick={() => exportDeliveries(dashboardFilters)}
            disabled={isExporting}
          >
            <span>Bulk Export</span>
          </Button>
        }
        filters={
          <Filters
            filters={dashboardFilters}
            setFilter={(e: any) => {
              dispatch(setDashboardFilter(e));
            }}
            setFilters={(e: any) => {
              dispatch(setDashboardFilters(e));
            }}
          />
        }
        datePicker={
          <DatePicker
            dateRange={dashboardFilters.dateRange}
            setDateRange={(range: any) =>
              dispatch(setDashboardDateRange(range))
            }
          />
        }
      />
      {/* Content */}
      <DeliveriesView />
    </Layout>
  );
};

export default Deliveries;
