import { useRef, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Product } from "../../components/deliveryNotice/Product";
import { Dewar } from "../../components/deliveryNotice/Dewar";
import { FinalSteps } from "../../components/deliveryNotice/FinalSteps";
import { ButtonContainer } from "./ButtonContainer";
import { Modal } from "../core/Modal";
import { Autocomplete } from "../modals/Autocomplete";
import { Delivery, Dewar as DewarInterface } from "../../interfaces/delivery";
import service from "../../service/deliveryService";
import { addDewar, addProductToDelivery } from "../../redux/delivery";
import { addCustomerDewar } from "../../redux/customerDewar";
import { setCustomerProducts } from "../../redux/customerProduct";

import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { Loader } from "../core/Loader";
import { v4 as uuidv4 } from "uuid";

interface DeliveryWithSetDelivery extends Delivery {
  setDelivery: (data: any) => any;
  customerDewars: DewarInterface[];
}

interface Props {
  props: DeliveryWithSetDelivery;
}

export const FormContainer: React.FC<Props> = ({ props }) => {
  /* Props */
  const {
    productInventories,
    dewarInventories,
    signer,
    notes,
    id,
    customer,
    status,
  } = props;

  /* Refs */
  const addProductModal = useRef<HTMLIonModalElement>(null);
  const addDewarModal = useRef<HTMLIonModalElement>(null);

  /* Hooks */
  const history = useHistory();
  const [newDewarValue, setNewDewarValue] = useState("");
  const [newProductValue, setNewProductValue] = useState("");
  const [isAddItemLoading, setIsAddItemLoading] = useState(false);
  const [addMissingDewarsLoading, setAddMissingDewarsLoading] = useState(false);
  const [missingDewars, setMissingDewars] = useState<any>([]);
  const [dewarLoadingStates, setDewarLoadingStates] = useState<{
    [key: string]: boolean;
  }>({});

  /* Redux */
  const dispatch = useAppDispatch();
  const customerDewars = useAppSelector(
    (state) => state.delivery?.customer?.dewars
  );
  const customerProducts = useAppSelector((state) => state.customerProducts);
  const delivery = useAppSelector((state) => state.delivery);

  const updateDewar = async (dewar: any) => {
    setDewarLoadingStates((prevState) => ({
      ...prevState,
      [dewar.serial]: true,
    }));

    try {
      /* Pouch updates */
      const serviceRes = await service.addDewarToDelivery({
        deliveryId: id,
        dewar: { serial: dewar.serial },
      });

      /* Redux updates */
      if (serviceRes) {
        dispatch(
          addDewar({
            dewarInventory: serviceRes,
          })
        );
        dispatch(addCustomerDewar(serviceRes));
        setMissingDewars(
          missingDewars.filter((d: any) => d.dewar.id !== dewar.id)
        );
      }
    } catch (error) {
      console.error("Error updating dewar:", error);
    } finally {
      setDewarLoadingStates((prevState) => ({
        ...prevState,
        [dewar.serial]: false,
      }));
    }
  };

  /* Effects */

  /* Get all possible products a customer has permission to add */
  useEffect(() => {
    service.getProducts().then((products) => {
      dispatch(setCustomerProducts(products));
    });
  }, [dispatch]);

  /*
    On page load, if there are dewars that COULD be on the delivery and aren't in the inventory,
    then we should add them automatically. Make sure we only do this once per page load. 
  */
  const hasSetDewars = useRef(false);
  useEffect(() => {
    if (
      customerDewars?.length &&
      !hasSetDewars?.current &&
      status !== "DELIVERED" &&
      status !== "CORRECTED"
    ) {
      hasSetDewars.current = true;

      const missingDewars = customerDewars?.filter(
        (dewar: any) =>
          !dewarInventories?.some(
            (inventory) => inventory?.dewar?.serial === dewar.serial
          )
      );
      if (missingDewars?.length) {
        const transformToDewar = (obj: any) => {
          return { dewar: obj, id: uuidv4(), measurement: obj.measurement };
        };

        const transformedArray = missingDewars.map(transformToDewar);
        setMissingDewars(transformedArray);
      }
    }
  }, [customerDewars, dewarInventories, status]);

  return (
    <>
      {/* Products */}
      <>
        <div className="font-bold text-lg mb-3">Products</div>
        {!!productInventories?.length &&
          productInventories.map((product, index) => (
            <Product key={`${product.product.id}-${index}`} props={product} />
          ))}
        <div className="flex justify-center">
          <ButtonContainer
            text="Add product"
            className="justify-center"
            icon="add"
            onClick={async () => {
              setIsAddItemLoading(false);
              await addProductModal.current?.present();
            }}
          />
        </div>
      </>

      {/* Dewars */}
      <>
        <div className="font-bold text-lg mb-3 mt-8">Dewars</div>
        {addMissingDewarsLoading && (
          <div className="flex flex-col items-center justify-center gap-4">
            <div>Adding dewars</div>
            <Loader />
          </div>
        )}
        {!!dewarInventories?.length &&
          dewarInventories.map(
            (dewarInventory, index) =>
              dewarInventory?.dewar && (
                <Dewar
                  key={`${dewarInventory?.dewar?.id}-${index}`}
                  props={dewarInventory}
                  isAdded={true}
                  setMissingDewars={setMissingDewars}
                  missingDewars={missingDewars}
                  customerDewar={
                    customerDewars?.find(
                      (dewar: any) =>
                        dewar.serial === dewarInventory?.dewar?.serial
                    ) || {}
                  }
                  isAdding={
                    dewarLoadingStates[dewarInventory?.dewar?.serial] || false
                  }
                  updateDewar={updateDewar}
                />
              )
          )}
        {missingDewars.map(
          (dewar: any, index: any) =>
            dewar?.dewar && (
              <Dewar
                key={`${dewar?.id}-${index}`}
                props={dewar}
                isAdded={false}
                updateDewar={updateDewar}
                isAdding={dewarLoadingStates[dewar?.dewar?.serial] || false}
                customerDewar={
                  customerDewars?.find(
                    (dewar: any) =>
                      dewar?.dewar?.serial === dewar?.dewar?.serial
                  ) || {}
                }
              />
            )
        )}
        <ButtonContainer
          text="Scan dewar"
          className="justify-center"
          icon="scan"
          onClick={() => {
            history.push(`/delivery-notice/${id}/dewar-scanner`);
          }}
        />
        <ButtonContainer
          text="Add manually"
          className="justify-center bg-white text-black-50"
          icon="add"
          type="secondary"
          onClick={async () => {
            await addDewarModal.current?.present();
          }}
        />
      </>

      {/* Final Steps*/}
      <div className="font-bold text-lg mb-3 mt-8">Final Steps</div>
      <FinalSteps />

      {/* Autocomplete */}
      <Modal heading="Add product" ref={addProductModal}>
        {isAddItemLoading ? (
          <Loader />
        ) : (
          <Autocomplete
            label={"Product"}
            value={newProductValue}
            ref={addProductModal}
            options={customerProducts?.products?.map((product: any) => {
              return {
                value: product.id,
                label: product.name + " · " + product.code,
              };
            })}
            alreadySelected={productInventories?.map(
              (product) => product.product.id
            )}
            onChange={async (value: any): Promise<void> => {
              setIsAddItemLoading(true);

              /* Update our local hook value, pouch, AND redux */

              /* Grab the entire product object for the store */
              const selectedProduct = customerProducts?.products?.find(
                (product: any) => product.id === value
              );
              /* Pouch updates */
              const serviceRes = await service.addProductToDelivery({
                deliveryId: id,
                product: selectedProduct,
              });

              /* Redux updates */
              dispatch(addProductToDelivery({ product: serviceRes }));

              /* Component State updates */
              setNewProductValue(value);
              setIsAddItemLoading(false);
            }}
            onBlur={() => setNewProductValue("")}
          />
        )}
      </Modal>
      <Modal heading="Add dewar" ref={addDewarModal}>
        {isAddItemLoading ? (
          <Loader />
        ) : (
          <Autocomplete
            label={"Dewar"}
            value={newDewarValue}
            ref={addDewarModal}
            canCreate={true}
            options={customerDewars?.map((dewar: any) => {
              return { value: dewar.serial, label: dewar.serial };
            })}
            alreadySelected={dewarInventories?.map(
              (dewar) => dewar?.dewar?.serial
            )}
            onChange={async (value: any): Promise<void> => {
              setIsAddItemLoading(true);
              /* Update our local hook value, pouch, AND redux */
              const newDewar = {
                serial: value,
                dewar: { serial: value },
                measurement: { starting: 0, added: 0, free: 0 },
                delivery: id,
              };

              /* Component State updates */
              setNewDewarValue(value);

              /* Pouch updates */
              const serviceRes = await service.addDewarToDelivery({
                deliveryId: id,
                dewar: { serial: value },
              });

              /* Redux updates */
              if (serviceRes) {
                dispatch(
                  addDewar({
                    dewarInventory: serviceRes,
                  })
                );
                dispatch(addCustomerDewar(serviceRes));

                /* 
                  Update the missing deewars, since this item is no longer missing
                */
                setMissingDewars(
                  missingDewars.filter((d: any) => d.dewar.serial !== value)
                );
              }

              setIsAddItemLoading(false);
            }}
            onBlur={() => {
              setNewDewarValue("");
            }}
          />
        )}
      </Modal>
    </>
  );
};
