const appModel = {
    singular: 'app',
    plural: 'apps',
    relations: {
        users: {
            hasMany: 'user'
        }
    }
}

module.exports = { appModel };