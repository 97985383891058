import { Route, Switch, useLocation } from "react-router-dom";
import { IonRouterOutlet } from "@ionic/react";
import AppUrlListener from "./AppUrlListener";
import Inbox from "./pages/Inbox/Index";
import Truck from "./pages/Truck/Index";
import DeliveryNotice from "./pages/DeliveryNotice/Index";
import DewarScanner from "./pages/DewarScanner/Index";
import Dashboard from "./pages/Dashboard/Index";
import Login from "./pages/Login/Index";
import useSync from "./hooks/useSync";

/*
  Okay, this is kind of weird -- and maybe I should have used ionic differently, but the simplest thing here was this.
  I don't want the dashboard routes animated, but I do want the app routes animated. So I have two IonRouterOutlets 
  depending on the route. I'm not sure if this is the best way to do it, but it works.
*/
const Routes: React.FC = () => {
  /* Hooks */
  const location = useLocation();

  /*
    If we are already logged in, whenever a user enters any 
    view in the app/dashboard, we want to enable live sync
  */
  useSync();

  /* Dashboard Routes */
  if (location.pathname.startsWith("/dashboard")) {
    return (
      <>
        <Switch>
          <IonRouterOutlet animated={false} id="dashboard">
            <Route exact path={["/dashboard*"]} component={Dashboard} />
          </IonRouterOutlet>
        </Switch>
      </>
    );
  }

  /* App Routes */
  return (
    <>
      <AppUrlListener />
      <Switch>
        <IonRouterOutlet animated={true} id="dashboard">
          {/* Delivery App Routes */}
          <Route exact path={"/inbox"} component={Inbox} />
          <Route exact path={"/truck"} component={Truck} />

          <Route
            exact
            path="/delivery-notice/:deliveryId"
            component={DeliveryNotice}
          />
          <Route exact path="/delivery-notice" component={DeliveryNotice} />

          <Route
            exact
            path="/delivery-notice/:deliveryId/dewar-scanner"
            component={DewarScanner}
          />

          {/* Login Routes */}
          <Route exact path={["/", "/login"]} component={Login} />
        </IonRouterOutlet>
      </Switch>
    </>
  );
};

export default Routes;
