import { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

interface Props {
  data: any;
}

export const BarChart: React.FC<Props> = ({ data }) => {
  /* 
    Chart JS has a weird feature where the chart animates from the top left on page load if its responsive.
    We will push if its mounted to the bottom of the call stack so its not animated by default, but will be 
    for subsequent renders and data changs.
  */
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    if (!hasMounted) {
      setTimeout(() => setHasMounted(true), 1000);
    }
  }, []);

  return (
    <div className="h-[300px]">
      <Bar
        data={data}
        options={{
          animation: {
            duration: hasMounted ? 1000 : 0,
          },
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            x: {
              border: {
                display: false,
              },
              grid: {
                color: "transparent",
              },
            },
            y: {
              border: {
                display: false,
              },
            },
          },
        }}
      />
    </div>
  );
};
