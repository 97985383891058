import { useEffect, useRef, useState } from "react";

interface Props {
  title: string;
  filters?: React.ReactNode;
  button?: React.ReactNode;
  datePicker?: React.ReactNode;
}

export const Header: React.FC<Props> = ({
  title = "",
  filters = null,
  button = null,
  datePicker = null,
}) => {
  const [isSticky, setIsSticky] = useState(false);
  const stickyRef = useRef(null);
  const topRef = useRef(null);

  useEffect(() => {
    const handleIntersection = (entries: any) => {
      entries.forEach((entry: any) => {
        if (!entry.isIntersecting) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: [1],
    });

    if (topRef.current) {
      observer.observe(topRef.current);
    }

    return () => {
      if (topRef.current) {
        observer.unobserve(topRef.current);
      }
    };
  }, []);

  return (
    <>
      <div ref={topRef}></div>
      <div
        ref={stickyRef}
        className={`sticky top-0 pt-10 pb-6 bg-white-100 z-10 ${
          isSticky ? "border-b border-b-black-10" : ""
        }`}
      >
        <div className="flex justify-between items-top">
          <h1 className="text-3xl font-bold tracking-tight">{title}</h1>
          {button}
        </div>
        <div className="flex justify-between items-center gap-5 mt-4 overflow-auto hide-scrollbar">
          {filters}
          {datePicker}
        </div>
      </div>
    </>
  );
};
