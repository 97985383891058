import { Warehouse } from "../core/Warehouse";
import { GridData } from "../deliveryNotice/GridData";
import { ProductReview } from "../deliveryNotice/ProductReview";
import { DewarReview } from "../deliveryNotice/DewarReview";
interface DeliveryInformation {
  name: string;
  address: {
    city: string;
    line1: string;
    state: string;
  };
}

// TODO: these can probably reference our global interfaces?
interface Lot {
  serial: string;
  quantity: number;
}

interface Product {
  product: {
    code: string;
    name: string;
  };
  lots: Lot[];
}

interface Dewar {
  dewar: {
    serial: string;
  };
  measurement: any;
}

interface GridData {
  key: string;
  value: string;
}

interface Props {
  children?: React.ReactNode;
  deliveryInformation: DeliveryInformation;
  products: Product[];
  dewars: Dewar[];
  gridData: GridData[];
}

export const OrderReview: React.FC<Props> = ({
  deliveryInformation,
  products,
  dewars,
  gridData,
}) => {
  return (
    <div>
      <Warehouse
        title={deliveryInformation.name}
        address={`${deliveryInformation.address.line1}, ${deliveryInformation.address.city}, ${deliveryInformation.address.state}`}
      />
      <GridData data={gridData} />
      <ProductReview title="Products" products={products} />
      <DewarReview title="Dewars" dewars={dewars} />
    </div>
  );
};
