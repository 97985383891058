import { Link, useHistory } from "react-router-dom";
import SVG from "react-inlinesvg";
import { Delivery } from "../../interfaces/inbox";
import historyIcon from "../../assets/svg/history.svg";
import { IonItem, useIonAlert } from "@ionic/react";

interface StatusColor {
  [key: string]: string;
}

const mapStatusToColor: StatusColor = {
  READY: "bg-blue-10 text-blue-100",
  IN_PROGRESS: "bg-orange-10 text-orange-100",
  PROCESSING: "bg-yellow-10 text-yellow-100",
  REPROCESSING: "bg-yellow-10 text-yellow-100",
  DELIVERED: "bg-green-light-10 text-green-dark-100",
  CORRECTED: "bg-green-light-100 text-white-100",
};

interface DeliveryWithFiltered extends Delivery {
  filteredDeliveries: any;
  moveDeliveryToInProgress: any;
}

export const DeliveryCard: React.FC<DeliveryWithFiltered> = ({
  id,
  title,
  orderNumber,
  address,
  status,
  driver = {},
  dateUpdated,
  filteredDeliveries,
  moveDeliveryToInProgress,
}) => {
  /* Hooks */
  const history = useHistory();
  const [presentAlert] = useIonAlert();

  /* Helpers */
  const formatDate = (dateStr: string) => {
    if (dateStr?.length) {
      const date = new Date(dateStr);
      let formattedDate =
        (date.getMonth() + 1).toString().padStart(2, "0") +
        "/" + // Months are 0-based in JavaScript
        date.getDate().toString().padStart(2, "0") +
        "/" +
        date.getFullYear() +
        " " +
        date.getHours().toString().padStart(2, "0") +
        ":" +
        date.getMinutes().toString().padStart(2, "0");
      // +
      // ":" +i
      // date.getSeconds().toString().padStart(2, "0");
      return formattedDate;
    }
    return "";
  };

  /* Handlers */
  const handleDeliveryClick = (e: any) => {
    if (status === "DELIVERED" || status === "CORRECTED") {
      e.preventDefault();
      presentAlert({
        header: "Delivery Notice",
        message:
          "This delivery has already been completed. Edits to any products/dewars will trigger a new delivery email to be sent.",
        buttons: [
          {
            text: "Cancel",
            role: "cancel",
          },
          {
            text: "OK",
            role: "confirm",
            handler: () => {
              history.push("/delivery-notice/" + id);
            },
          },
        ],
      });
    } else if (status === "PROCESSING" || status === "REPROCESSING") {
      e.preventDefault();
      presentAlert({
        header: "Delivery Notice",
        message: "Deliveries that are processing cannot be edited.",
        buttons: [
          {
            text: "OK",
            role: "cancel",
          },
        ],
      });
    } else {
      moveDeliveryToInProgress(id, filteredDeliveries);
      history.push(`/delivery-notice/${id}`);
    }
  };

  return (
    <IonItem
      type="button"
      onClick={handleDeliveryClick}
      className="ion-item-card-override"
    >
      <div className="bg-white-100 rounded-xl px-5 py-6 grid grid-cols-[auto,auto] justify-between items-center w-full">
        <div>
          <div className="grid justify-start grid-cols-[auto,auto] gap-1 items-center">
            <div className="text-base">{title}</div>
            <div className="text-black-50 text-sm bg-black-5 border border-black-10 rounded-full inline-block px-1.5 py-0.25">
              {orderNumber}
            </div>
          </div>
          <div className="mt-0.25 text-black-50 text-base">{address}</div>
          {(driver.name || driver.email) && (
            <div className="mt-0 text-black-50 text-base items-center grid grid-cols-[auto] gap-0.5">
              <span>{driver.name || driver.email}</span>
            </div>
          )}
          {dateUpdated && (
            <div className="mt-0 text-black-50 text-base items-center grid grid-cols-[auto] gap-0.5">
              <span>Last updated: {formatDate(dateUpdated || "")}</span>
            </div>
          )}
        </div>
        <div className="grid grid-cols-[auto,auto] gap-1.5 justify-end items-center">
          <SVG src={historyIcon} className="h-5 w-5 opacity-50" />
          <div
            className={`capitalize text-sm font-medium rounded-full inline-block px-3 py-1.5 ${mapStatusToColor[status]}`}
          >
            {status?.toLowerCase().replace("_", " ")}
          </div>
        </div>
      </div>
    </IonItem>
  );
};
