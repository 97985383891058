import { useEffect, useState } from "react";
import { Input } from "../../components/core/Input";
import { TextArea } from "../../components/core/TextArea";
import {
  setSigner,
  setNotes,
  setNotificationEmails,
  setSendNotification,
} from "../../redux/delivery";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { MultiSelect } from "../core/MultiSelect";

export const FinalSteps: React.FC = () => {
  /* Redux */
  const delivery = useAppSelector((state) => state.delivery);
  const validation = useAppSelector((state) => state.validation);
  const dispatch = useAppDispatch();
  const [isSendNotification, setIsSendNotification] = useState(
    delivery.sendNotification !== undefined ? delivery.sendNotification : true
  );

  const [customerEmails, setCustomerEmails] = useState<any[]>([]);

  useEffect(() => {
    const existingEmails = delivery.notificationEmails?.map((email: any) => {
      return { label: email, value: email };
    });

    // Show the applied emails if they have already been set on this delivery.
    if (existingEmails?.length > 0) {
      setCustomerEmails(existingEmails);
    } else if (delivery.status == "READY") {
      // If the delivery is new, apply the customer's contact emails.
      const transformedEmails = delivery.customer?.contactEmails?.map(
        (email: any) => {
          return { label: email, value: email };
        }
      );
      setCustomerEmails(transformedEmails || []);
    }
  }, []);

  useEffect(() => {
    // Initialize the checkbox state with delivery.sendNotification or true if it's undefined
    if (typeof delivery.sendNotification !== "undefined") {
      setIsSendNotification(delivery.sendNotification);
    }
  }, [delivery.sendNotification]);

  const handleToggle = () => {
    const newState = !isSendNotification;
    setIsSendNotification(newState);
    dispatch(setSendNotification({ value: newState }));
  };

  return (
    <div className="bg-white-100 rounded-xl px-5 py-6 grid grid-cols-1 justify-between items-center">
      <div className="grid grid-cols-1fr gap-5">
        <Input
          label="Signer's name"
          placeholder={"Jane Doe"}
          description=""
          value={delivery.signer?.name || ""}
          onChange={(e: any) =>
            dispatch(
              setSigner({
                key: "name",
                value: e.target.value,
              })
            )
          }
        />
        <div id="signer-email-input">
          <MultiSelect
            label="Send a copy"
            value={customerEmails}
            options={customerEmails}
            creatable={true}
            isMulti={true}
            onChange={(selected: any) => {
              setCustomerEmails(selected);
              dispatch(
                setNotificationEmails(
                  selected?.map((item: any) => item.value?.trim().toLowerCase())
                )
              );
            }}
            errorMessage={
              !validation.isEmailValid && validation.hasSubmitted
                ? "Please make sure all emails are valid"
                : ""
            }
          />
        </div>
        <div className="flex ml-2">
          <div className="flex ml-2 items-center">
            <div
              className={`mr-2 w-5 h-5 border border-gray-400 items-center justify-center m-auto ${
                isSendNotification ? "bg-blue-600" : "bg-white"
              }`}
              onClick={handleToggle}
              style={{ cursor: "pointer" }}
            >
              {isSendNotification && (
                <svg
                  className="w-5 h-5 text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  ></path>
                </svg>
              )}
            </div>
            <label className="text-sm text-black-80">
              Send a copy of this delivery notice to the the above emails
            </label>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <TextArea
          label="Demand Fulfillment Notes"
          placeholder={"Add note"}
          description=""
          value={delivery.notes || ""}
          onChange={(e: any) => {
            dispatch(
              setNotes({
                value: e.target.value,
              })
            );
          }}
        />
      </div>
    </div>
  );
};
