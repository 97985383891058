const notionExport = ``;

export const KnowledgeCenterView: React.FC = ({}) => {
  return (
    <div className="h-full p-4 overflow-y-scroll">
      <details className="mb-4">
        <summary>Question 1</summary>
        <p>Answer coming soon</p>
      </details>
      <details className="mb-4">
        <summary>Question 2</summary>
        <p>Answer coming soon</p>
      </details>
    </div>
  );
};
