import { useCallback, useEffect, useRef, useState } from "react";
import moment from "moment";
import { DayPickerRangeController } from "react-dates";
import calendar from "../../assets/svg/calendar.svg";
import Dropdown from "./../core/Dropdown";
import { getDateRanges } from "../../lib";

import SVG from "react-inlinesvg";
import arrowDown from "../../assets/svg/arrowDown.svg";
import arrowRight from "../../assets/svg/arrowRight.svg";

/* React Datepicker Styles*/
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "../../theme/custom-react-dates.css";

/* Date options helpers */
const prettyFormatDate = (dateString: any) => {
  const date = new Date(dateString);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return `${monthNames[date.getMonth()]} ${date.getDate()}`;
};

/* Helpers */
const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);

  return `${year}-${month}-${day}`;
};

const getDateRange = ({ startDate, endDate }: any) => {
  return `${prettyFormatDate(startDate)} - ${prettyFormatDate(endDate)}`;
};

const dateRanges = getDateRanges();

interface Props {
  dateRange: any;
  setDateRange: (dateRange: any) => void;
}

export const DatePicker: React.FC<Props> = ({ dateRange, setDateRange }) => {
  /* Hooks */
  const [showCalendar, setShowCalendar] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [focusedInput, setFocusedInput] = useState<any>("startDate");
  const [localDateRange, setLocalDateRange] = useState<any>(dateRange);
  const [initialDateRange] = useState<any>(dateRange);

  /* Refs */
  const dateRangeRef = useRef(dateRange);

  /* Watchers */
  /* 
    Update the dateRangeRef whenever dateRange changes. 
    We are doing this because for whatever reason the prop isn't updating right when used in the scope it
    is below. This is kinda a weird fix, but GPT suggested it and it works!
  */
  useEffect(() => {
    dateRangeRef.current = dateRange;
  }, [dateRange]);

  const onDropdownClose = useCallback(() => {
    setLocalDateRange(dateRangeRef.current);
  }, []);

  const buttonContent = (
    <div className="border border-black-10 rounded-md pl-3 pr-2 py-3 overfloy-x-hidden w-[200px] grid items-center grid-cols-[20px,auto,20px] gap-2 cursor-pointer hover:bg-black-10 transition-all relative">
      <img src={calendar} alt="calendar" />
      <div className="text-sm text-black-100 text-left">
        {dateRange.type !== "CUSTOM" ? dateRange.label : "Custom"}
      </div>
      <img src={arrowDown} alt="arrow down" />
    </div>
  );

  const modal = (
    <div className="bg-white-100 border border-black-10 py-6 pb-4 px-4 w-full shadow-md mt-2 rounded-md">
      <div className="uppercase text-xs text-black-50 tracking-tight font-medium mb-1 mx-1.5">
        Date range
      </div>
      {!showCalendar ? (
        <>
          {dateRanges.map((range, index) => (
            <div
              className="mb-3 pb-2 border-b border-b-black-10 cursor-pointer"
              key={index}
            >
              {range.options.map((option, optionIndex) => (
                <div
                  key={optionIndex}
                  className={`flex justify-between items-center mb-0.5 py-1.5 px-1.5 rounded-md hover:bg-black-5 transition-all ease-out ${
                    option.type === localDateRange.type
                      ? "bg-black-10"
                      : "bg-transparent"
                  }`}
                  onClick={() => {
                    setLocalDateRange({
                      startDate: formatDate(option.range.startDate),
                      startDateFull: option.range.startDate,
                      endDate: formatDate(option.range.endDate),
                      endDateFull: option.range.endDate,
                      type: option.type,
                      label: option.label,
                    });
                  }}
                >
                  <div className="text-sm text-black-100 font-medium">
                    {option.label}
                  </div>
                  <div className="text-sm text-black-50">
                    {getDateRange(option.range)}
                  </div>
                </div>
              ))}
            </div>
          ))}
          <div
            className={`flex items-center justify-between py-1.5 px-1.5 cursor-pointer rounded-md hover:bg-black-5 transition-all ease-out ${
              localDateRange.type === "CUSTOM"
                ? "bg-black-10"
                : "bg-transparent"
            }`}
            onClick={() => setShowCalendar(true)}
          >
            <div className="text-sm text-black-100 font-medium">
              Custom date range
            </div>
            <SVG
              src={arrowRight}
              style={{
                fill: "rgba(17, 20, 46, 0.5)",
                height: "20px",
                width: "20px",
              }}
            />
          </div>
        </>
      ) : (
        <div>
          <div className="w-[240px] min-h-[300px]">
            {/* @ts-ignore */}
            <DayPickerRangeController
              startDate={startDate}
              endDate={endDate}
              onDatesChange={({ startDate, endDate }) => {
                setStartDate(startDate);
                setEndDate(endDate);
                setLocalDateRange({
                  startDate: formatDate(
                    startDate?.toDate().toISOString() ||
                      new Date().toISOString()
                  ),
                  startDateFull:
                    startDate?.toDate().toISOString() ||
                    new Date().toISOString(),

                  endDate: formatDate(
                    endDate?.toDate().toISOString() || new Date().toISOString()
                  ),
                  endDateFull:
                    endDate?.toDate().toISOString() || new Date().toISOString(),
                  type: "CUSTOM",
                  label: "Custom",
                });
              }}
              focusedInput={focusedInput}
              onFocusChange={(focusedInput) => {
                if (!focusedInput) {
                  setFocusedInput("startDate");
                } else {
                  setFocusedInput(focusedInput);
                }
              }}
              numberOfMonths={1}
              isOutsideRange={() => false}
            />
          </div>
        </div>
      )}
      <div className="flex gap-2 items-center justify-between mt-2">
        <div
          className="w-full bg-white-100 text-blue-100 border border-black-10 py-2 px-2 text-center text-sm font-medium rounded-md cursor-pointer"
          onClick={() => {
            if (showCalendar) {
              setShowCalendar(false);
            } else {
              setLocalDateRange(initialDateRange);
              setDateRange(initialDateRange);
              setStartDate(null);
              setEndDate(null);
              setIsOpen(false);
            }
          }}
        >
          {showCalendar ? "Back" : "Clear"}
        </div>
        <div
          className="w-full bg-blue-100 text-white-100 border border-blue-100 py-2 px-2 text-center text-sm font-medium rounded-md cursor-pointer"
          onClick={() => {
            setIsOpen(false);
            setDateRange(localDateRange);
          }}
        >
          Apply
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <Dropdown
        buttonContent={buttonContent}
        modal={modal}
        width={showCalendar ? 350 : 300}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClose={onDropdownClose}
      />
    </div>
  );
};
