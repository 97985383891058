import { useEffect, useState, useRef } from "react";
import { useDewarsContext } from "../../../contexts/DewarsContext";
import { DewarsContext } from "../../../contexts/DewarsContext";
import { Layout } from "../../../components/dashboard/Layout";
import { Header } from "../../../components/dashboard/Header";
import { Filters } from "../../../components/core/Filters";
import { DewarsView } from "../../../components/dashboard/views/DewarsView";
import { Button } from "../../../components/core/Button";
import { setDewarFilters, setDewarFilter } from "../../../redux/dewarFilters";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import useFilters from "../../../hooks/useFilters";
import { useIonAlert } from "@ionic/react";
import deliveryService from "../../../service/deliveryService";
import useDewars from "../../../hooks/useDewars";
import { set } from "lodash";

/* We'll use this so we can track state on existing users or new users in the modal */
interface Dewar {
  name: "";
  customer: "";
}
const defaultDewarSchema: any = {
  name: "",
  customer: "",
  metadata: {},
};

const validateFields = (activeDewar: Dewar) => {
  let errors: any = {};

  if (!activeDewar?.name.length) {
    errors.name = "Please enter a valid name.";
  }
  return errors;
};

const Dewars: React.FC = ({}) => {
  const {
    dewarsIsLoading,
    dewars,
    setDewars,
    fetchDewars,
    fetchMore,
    hasReachedMaxPagination,
    activeInput,
    setActiveInput,
    viewAllHistory,
    setViewAllHistory,
    fileName,
    setFileName,
  } = useDewars();

  /* Redux */
  const dispatch = useAppDispatch();
  const dewarFilters = useAppSelector((state) => state.dewarFilters);

  /* Hooks */
  const [presentAlert] = useIonAlert();
  const [activeDewar, setActiveDewar] = useState<any>(null);
  const [fieldErrors, setFieldErrors] = useState<any>({});
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [history, setHistory] = useState<any>(null);
  const { filters, fetchFilters } = useFilters();
  const [isExporting, setIsExporting] = useState<boolean>(false);

  /* Refs */
  const dewarModal = useRef<HTMLIonModalElement>(null);
  const exportToCsvModal = useRef<HTMLIonModalElement>(null);

  /* Handlers */
  const presentHelpAlert = async (e: any) => {
    e.preventDefault();
    await presentAlert({
      header: "Export started",
      message: "Check your email soon",
      buttons: [
        {
          text: "Close",
          role: "cancel",
        },
      ],
    });
  };

  const exportDewars = async (ids: any) => {
    setIsExporting(true);
    let dewarIds = await dewars.map((d: any) => d.id);
    let result = await deliveryService.exportDewars(dewarIds);
    await presentAlert({
      header: result.status < 400 ? "Export started" : "Export error",
      message:
        result.status < 400
          ? "Watch your email inbox for the completed export as an attachment."
          : result?.data?.message || "Export could not be completed",
      buttons: [
        {
          text: "Close",
          role: "cancel",
        },
      ],
    });
    setIsExporting(false);
  };

  /* Effects */
  /* Fetch filters on mount */
  useEffect(() => {
    fetchFilters({
      exclude: [
        "truck",
        "product",
        "driver",
        "on_target",
        "truck_load_status",
        "truck_empty_status",
        "lot",
      ],
    });
  }, []);

  /* Once the filters have been fetched, send them off to redux */
  useEffect(() => {
    if (filters.length) {
      dispatch(
        setDewarFilters(
          //filters
          filters.map((filter: any) => {
            if (filter.type === "dewar_status") {
              return {
                ...filter,
                selectedValues: ["ACTIVE"],
                value: "In-Service",
                display: "Status",
              };
            }

            return filter;
          })
        )
      );
    }
  }, [filters]);

  /* If a user has submitted, and there are errors, start validating on keydown */
  useEffect(() => {
    if (hasSubmitted) {
      const errors = validateFields(activeDewar);
      setFieldErrors(errors);
    } else {
      setFieldErrors({});
    }
  }, [hasSubmitted, activeDewar]);
  return (
    <Layout>
      {/* Header */}
      <Header
        title={"Dewar Management"}
        filters={
          <Filters
            filters={dewarFilters}
            setFilter={(e: any) => {
              dispatch(setDewarFilter(e));
            }}
            setFilters={(e: any) => {
              dispatch(setDewarFilters(e));
            }}
          />
        }
        button={
          <div className="flex items-center gap-2">
            <Button
              className={`${
                isExporting ? "bg-black-50" : "bg-blue-100 hover:bg-blue-150"
              } text-white-100  grid grid-cols-[auto,auto] items-center gap-0.5 !py-3.5`}
              onClick={exportDewars}
              disabled={isExporting}
            >
              <span>Bulk Export</span>
            </Button>
            <Button
              className="text-blue-100 bg-blue-10 hover:bg-blue-25 grid grid-cols-[auto,auto] items-center gap-0.5 !py-3.5"
              onClick={() => {
                setActiveDewar(defaultDewarSchema);
                dewarModal.current?.present();
              }}
              icon="add"
            >
              <span>Add Dewar</span>
            </Button>
          </div>
        }
      />
      {/* Content */}
      <DewarsContext.Provider
        value={{
          activeDewar,
          setActiveDewar,
          setHasSubmitted,
          fieldErrors,
          setFieldErrors,
          defaultDewarSchema,
          validateFields,
          dewarModal,
          exportToCsvModal,
          dewarFilters,
          history,
          setHistory,
        }}
      >
        <DewarsView />
      </DewarsContext.Provider>
    </Layout>
  );
};

export default Dewars;
