import _ from "lodash";

const mapById: any = (list: any[], idField: string) => {
  return new Map(Object.entries(_.keyBy(list, idField)));
};

const getLocalizedDateString: any = (dateString: string) => {
  try {
    let date = new Date(Date.parse(dateString));
    const formatter = new Intl.DateTimeFormat("en-US", {
      timeZone: "America/Chicago",
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });

    const [month, , day, , year, , hour, , minute, , second] =
      formatter.formatToParts(date);
    return `${month.value}/${day.value}/${year.value}`;
  } catch (e) {
    console.log("Issue parsing date", e);
    return dateString;
  }
};

export { mapById, getLocalizedDateString };
