import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setUsers, setIsUsersLoading } from "../redux/users";

import userService from "../service/userService";

const useFetchUsers = () => {
  /* Redux */
  const users = useAppSelector((state) => state.users);
  const dispatch = useAppDispatch();

  const fetchUsers = async () => {
    dispatch(setIsUsersLoading(true));
    try {
      const fetchedUsers = await userService.getUsers();
      /* Filter our Haystack users */
      const filteredUsers = fetchedUsers?.filter(
        (user: any) =>
          !user.email?.includes("haystack") ||
          process.env.NODE_ENV === "development"
      );
      dispatch(setUsers(filteredUsers));
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      dispatch(setIsUsersLoading(false));
    }
  };

  return {
    isUsersLoading: users.isUsersLoading,
    users: users.users,
    setUsers: (data: any) => dispatch(setUsers(data)),
    fetchUsers,
  };
};

export default useFetchUsers;
