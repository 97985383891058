import { useRef, useState, useEffect } from "react";

/*
    This allows us to get the previous value of any hook or redxu state so we can 
    use it to compare (i.e. validations, etc)
*/
export const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

// Borrowed from https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci
export const useDebounce = (value: any, delay: any) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
};

/*  For some inputs, we only want numbers. iOS can't restrict the keyboard, so we'll just not allow them on keyDown*/
export const numericOnly = (value: string) => {
  return value.replace(/[^\d.-]+/g, "");
};

/* For the CSV Export */
export const flattenObject = (obj: any, prefix = "") => {
  return Object.keys(obj).reduce((acc, key): any => {
    const newKey = prefix ? `${prefix}.${key}` : key;
    if (typeof obj[key] === "object" && obj[key] !== null) {
      return { ...acc, ...flattenObject(obj[key], newKey) };
    }
    return { ...acc, [newKey]: obj[key] };
  }, {});
};

/* Export to CSV */
export const jsonToCSV = (data: any, filename: string, saveAs: any) => {
  const JSONData = Array.isArray(data) ? data : [data];
  const flattenedData = JSONData.map((obj) => flattenObject(obj));
  const keys = Object.keys(flattenedData[0]);
  const csvData =
    keys.join(",") +
    "\n" +
    flattenedData
      .map((obj) =>
        keys
          .map((key) =>
            (obj as Record<string, unknown>)[key] !== undefined
              ? (obj as Record<string, unknown>)[key]
              : ""
          )
          .join(",")
      )
      .join("\n");

  const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
  saveAs(blob, filename || "export.csv");
};

/* Grab date ranges for dashboard filters */
export const getDateRanges = () => {
  const today = new Date();
  const dayOfWeek = today.getDay();
  const dayOfMonth = today.getDate();
  const month = today.getMonth();
  const year = today.getFullYear();

  return [
    {
      options: [
        {
          type: "THIS_WEEK",
          label: "This Week",
          range: {
            startDate: new Date(
              year,
              month,
              dayOfMonth - dayOfWeek
            ).toISOString(),
            endDate: new Date(
              year,
              month,
              dayOfMonth - dayOfWeek + 6
            ).toISOString(),
          },
        },
        {
          type: "THIS_MONTH",
          label: "This Month",
          range: {
            startDate: new Date(year, month, 1).toISOString(),
            endDate: new Date(year, month + 1, 0).toISOString(),
          },
        },
        {
          type: "THIS_YEAR",
          label: "This Year",
          range: {
            startDate: new Date(year, 0, 1).toISOString(),
            endDate: new Date(year + 1, 0, 0).toISOString(),
          },
        },
      ],
    },
    {
      options: [
        {
          type: "LAST_WEEK",
          label: "Last Week",
          range: {
            startDate: new Date(
              year,
              month,
              dayOfMonth - dayOfWeek - 7
            ).toISOString(),
            endDate: new Date(
              year,
              month,
              dayOfMonth - dayOfWeek - 1
            ).toISOString(),
          },
        },
        {
          type: "LAST_MONTH",
          label: "Last Month",
          range: {
            startDate: new Date(year, month - 1, 1).toISOString(),
            endDate: new Date(year, month, 0).toISOString(),
          },
        },
        {
          type: "LAST_YEAR",
          label: "Last Year",
          range: {
            startDate: new Date(year - 1, 0, 1).toISOString(),
            endDate: new Date(year, 0, 0).toISOString(),
          },
        },
      ],
    },
  ];
};

export const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};
