import { IonItem } from "@ionic/react";
import { getLocalizedDateString } from "../../service/helper";

interface StatusColor {
  [key: string]: string;
}

const mapStatusToColor: StatusColor = {
  UNLOADED: "bg-blue-10 text-blue-100",
  LOADED: "bg-green-light-10 text-green-dark-100",
  PROCESSING: "bg-yellow-10 text-yellow-100",
  PENDING: "bg-yellow-10 text-yellow-100",
};

export const TruckCard: React.FC<any> = ({ lotInfo, handleClick }) => {
  const {
    serial,
    measurement,
    expiration,
    dateCreated,
    truck,
    status = "LOADED",
  } = lotInfo;

  return (
    <IonItem
      type="button"
      onClick={() => handleClick(lotInfo)}
      className="ion-item-card-override"
    >
      <div className="bg-white-100 rounded-xl px-5 py-6 grid grid-cols-[auto,auto] justify-between items-center w-full">
        <div>
          <div className="grid justify-start grid-cols-[auto,auto] gap-1 items-center">
            <div className="text-lg font-medium">{serial}</div>
          </div>
          <div className="mt-0.25 text-black-80 text-base grid grid-cols-[16px,auto] items-center relative">
            <div className="h-2 w-2 rounded-full bg-black-25"></div>
            {measurement?.remaining !== undefined && (
              <div>
                Remaining ·{" "}
                <span
                  className={
                    measurement?.remaining < 0 ? "text-orange-100" : ""
                  }
                >
                  {measurement.remaining} Units
                </span>
              </div>
            )}
          </div>

          <div className="text-sm text-black-80 mt-2">
            {truck}
            {dateCreated
              ? ` (loaded ${getLocalizedDateString(dateCreated)})`
              : ""}
          </div>
        </div>
        <div className="grid grid-cols-[auto,auto] gap-1.5 justify-end items-center">
          <div
            className={`capitalize text-base font-medium rounded-full inline-block px-3 py-1.5 ${mapStatusToColor[status]}`}
          >
            {status === "UNLOADED"
              ? "Unloaded"
              : status === "LOADED"
              ? "Loaded"
              : status === "PROCESSING"
              ? "Processing"
              : status === "PENDING"
              ? "Pending Unload"
              : "LOADED"}
          </div>
        </div>
      </div>
    </IonItem>
  );
};
