import { IonContent, IonPage, useIonAlert } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { InboxHeader } from "../../components/headers/Inbox";
import { Wrapper } from "../../components/core/Wrapper";
import { Loader } from "../../components/core/Loader";
import { useHistory } from "react-router";
import { TruckCard } from "../../components/inbox/TruckCard";
import { Modal } from "../../components/core/Modal";
import deliveryService from "../../service/deliveryService";
import { Truck } from "../../components/modals/Truck";
import useFilters from "../../hooks/useFilters";
import { Filters } from "../../components/core/Filters";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setTruckFilter, setTruckFilters } from "../../redux/truckFilters";
import userService from "../../service/userService";
import useLots from "../../hooks/useLots";
import { ButtonContainer } from "../../components/deliveryNotice/ButtonContainer";
import { StickyFooter } from "../../components/truck/StickyFooter";

const MyTruck: React.FC = () => {
  /* Redux */
  const truckFilters = useAppSelector((state) => state.truckFilters);
  const dispatch = useAppDispatch();

  /* Hooks */
  const [activeLot, setActiveLot] = useState<any>({});
  useState<boolean>(false);
  const [activeLotDeliveries, setActiveLotDeliveries] = useState<any>([]);
  const [deliveriesByLotLoading, setDeliveriesByLotLoading] =
    useState<boolean>(false);
  const [hasMounted, setHasMounted] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<any>({});
  const [hasSetDefaultFilters, setHasSetDefaultFilters] =
    useState<boolean>(false);
  const history = useHistory();
  const { filters, fetchFilters } = useFilters();
  const [presentAlert] = useIonAlert();
  const [title, setTitle] = useState<string>("Select All");

  const {
    lotsByProduct,
    setLotsByProduct,
    fetchMoreLots,
    lotsByProductLoading,
    hasReachedMaxPagination,
    fetchInitialLots,
  } = useLots();

  /* Refs */
  const truckModal = useRef<HTMLIonModalElement>(null);

  /* Helpers */
  const handleLoadMore = () => {
    setTitle("Select All");
    fetchMoreLots(truckFilters);
  };

  /*
    On page mount, fetch all lots and group them by product
  */
  useEffect(() => {
    if (hasMounted && Object.keys(truckFilters).length) {
      fetchInitialLots(truckFilters);
    }
  }, [hasMounted, truckFilters]);

  /* Grab filters on the first page load */
  useEffect(() => {
    fetchFilters({
      exclude: [
        "customer",
        "driver",
        "on_target",
        "warehouse",
        "product",
        "dewar_status",
      ],
    });
  }, []);

  /*  Watch for entering the inbox page */
  useEffect(() => {
    (async () => {
      if (!hasMounted && history.location.pathname.startsWith("/truck")) {
        setHasMounted(true);
      }
    })();
  }, [history.location.pathname, hasMounted]);

  /* Watch for leaving the inbox page */
  useEffect(() => {
    (async () => {
      if (hasMounted && !history.location.pathname.startsWith("/truck")) {
        setHasMounted(false);
      }
    })();
  }, [history.location.pathname, hasMounted]);

  /* Filters */
  /* Fetch the current user so we know their default filters */
  useEffect(() => {
    const fetchCurrentUser = async () => {
      const user = await userService.getCurrentUser();
      setCurrentUser(user);
    };
    fetchCurrentUser();
  }, []);

  /* Set the default filters */
  useEffect(() => {
    if (
      currentUser &&
      Object.keys(currentUser)?.length &&
      filters?.length &&
      !hasSetDefaultFilters
    ) {
      setHasSetDefaultFilters(true);
      /* 
        Kinda weird, but we only want to show 1 truck at a time, so in the case that a user has 2 
        trucks defaulted to them, we only want to show one, so let's show the first one
      */

      dispatch(
        setTruckFilters(
          filters?.map((filter: any) => {
            if (currentUser?.filters?.[filter.type]) {
              if (filter.type === "truck") {
                return {
                  ...filter,
                  selectedValues: [currentUser.filters[filter.type]?.[0]],
                  value: currentUser.filters[filter.type]?.[0],
                };
              }

              return {
                ...filter,
                selectedValues: currentUser.filters[filter.type],
              };
            }
            if (filter.type === "truck_load_status") {
              return {
                ...filter,
                selectedValues: ["LOADED"],
                value: "LOADED",
                display: "status",
              };
            }
            if (filter.type === "truck_empty_status") {
              return {
                ...filter,
                selectedValues: ["NOT_EMPTY"],
                value: "NOT_EMPTY",
                display: "empty",
              };
            }
            return filter;
          })
        )
      );
    }
  }, [currentUser, filters.length, hasSetDefaultFilters]);

  /* Helpers */
  const handleCardClick = async (lotInfo: any) => {
    setDeliveriesByLotLoading(true);
    setActiveLot(lotInfo);
    setActiveLotDeliveries([]);
    await truckModal.current?.present();
    const deliveries = await deliveryService.getDeliveriesByLot(lotInfo.id);

    setActiveLotDeliveries(deliveries);
    setDeliveriesByLotLoading(false);
  };

  return (
    <IonPage>
      <InboxHeader
        filters={
          <Filters
            className="mt-6 pb-4 bg-[#fafafa]"
            filters={truckFilters}
            setFilter={(e: any) => {
              dispatch(setTruckFilter(e));
            }}
            setFilters={(e: any) => {
              dispatch(setTruckFilters(e));
            }}
            showSelectedIfSingleSelect={true}
          />
        }
      />
      <IonContent>
        <Wrapper>
          {Object.keys(lotsByProduct)?.map((product, productIndex) => (
            <div className="grid grid-cols-1 gap-4 mb-6" key={productIndex}>
              <div className="pl-2">
                {lotsByProduct[product][0]?.productName +
                  " · " +
                  lotsByProduct[product][0]?.product}
              </div>{" "}
              {lotsByProduct[product]?.map((lot: any, lotIndex: any) => (
                <TruckCard
                  lotInfo={lot}
                  key={lotIndex}
                  handleClick={() => handleCardClick(lot)}
                />
              ))}
            </div>
          ))}
          {/* All the button store for pagination, would be cleaner as its own component, but this is simple */}{" "}
          {lotsByProductLoading ? (
            <Loader />
          ) : !hasReachedMaxPagination ? (
            <div className="mb-6">
              <ButtonContainer
                text="Load more"
                className="justify-center"
                onClick={handleLoadMore}
              />
            </div>
          ) : (
            hasReachedMaxPagination && (
              <div className="text-center text-black-50 mb-2">
                You've reached the end of the list
              </div>
            )
          )}
          <div className="h-4 w-full"></div>
        </Wrapper>

        <Modal
          ref={truckModal}
          heading="Lot information"
          buttons={[
            {
              text: "Close",
              theme: "LIGHT",
              onClick: async () => await truckModal.current?.dismiss(),
            },
            {
              text: `Mark as ${
                activeLot.status === "UNLOADED" ? "Loaded" : "Unloaded"
              }`,
              theme: "DARK",
              onClick: async () => {
                const updatedLot = {
                  ...activeLot,
                  ...{
                    status:
                      activeLot.status === "UNLOADED" ? "LOADED" : "PENDING",
                  },
                };
                await deliveryService.updateLot({
                  lot: updatedLot,
                });

                const updatedLotsByProduct = Object.keys(lotsByProduct).reduce(
                  (acc: any, product) => {
                    if (activeLot.product === product) {
                      acc[product] = lotsByProduct[product]?.map((lot: any) => {
                        if (lot.id === activeLot.id) {
                          return updatedLot;
                        }
                        return lot;
                      });
                    } else {
                      acc[product] = lotsByProduct[product];
                    }
                    return acc;
                  },
                  {}
                );

                setLotsByProduct(updatedLotsByProduct);
                setActiveLot(updatedLot);
                await truckModal.current?.dismiss();
              },
            },
          ]}
        >
          {deliveriesByLotLoading ? (
            <Loader />
          ) : (
            <>
              <Truck lotInformation={activeLot} orders={activeLotDeliveries} />
            </>
          )}
        </Modal>
      </IonContent>
      <StickyFooter
        title={title}
        onSubmitClick={async () => {
          // Count lots in pending status
          let count = 0;
          await Object.keys(lotsByProduct).reduce(
            async (accPromise: any, product) => {
              const acc = await accPromise; // Await the accumulation
              const productLotsPromises = lotsByProduct[product]?.map(
                async (lot: any) => {
                  if (lot.status === "PENDING") {
                    count++;
                    return lot;
                  }
                  return null;
                }
              );
              acc[product] = await Promise.all(productLotsPromises);
              return acc;
            },
            Promise.resolve({})
          );

          if (count < 1) {
            presentAlert({
              header: "No Lots Ready",
              message: `There are no lots to unload. Click a lot to unload it or use "Select All"`,
              buttons: ["OK"],
            });
            return;
          }
          presentAlert({
            header: "Finish Unloading Truck?",
            message: `Are you sure you want to unload ${count} lots? This will also remove empty lots and send an inventory notification.`,
            buttons: [
              {
                text: "Cancel",
                role: "cancel",
              },
              {
                text: "OK",
                role: "confirm",
                handler: async () => {
                  /* Loop through and set all pending lots to PROCESSING */
                  const updatedLots = await Object.keys(lotsByProduct).reduce(
                    async (accPromise: any, product) => {
                      const acc = await accPromise; // Await the accumulation
                      const productLotsPromises = lotsByProduct[product]?.map(
                        async (lot: any) => {
                          if (lot.status === "PENDING") {
                            const updatedLot = {
                              ...lot,
                              ...{ status: "PROCESSING" },
                            };

                            await deliveryService.updateLot({
                              lot: updatedLot,
                            });

                            return updatedLot;
                          }
                          return lot;
                        }
                      );
                      acc[product] = await Promise.all(productLotsPromises);
                      return acc;
                    },
                    Promise.resolve({})
                  );

                  setLotsByProduct(updatedLots);
                  setTitle("Select All");
                },
              },
            ],
          });
        }}
        onSelectAll={async () => {
          const updatedLots = Object.keys(lotsByProduct).reduce(
            (acc: any, product) => {
              acc[product] = lotsByProduct[product]?.map((lot: any) => {
                if (title === "Select All" && lot.status === "LOADED") {
                  return {
                    ...lot,
                    ...{ status: "PENDING" },
                  };
                } else if (title === "Deselect All") {
                  window.location.reload();
                }
                return lot;
              });
              return acc;
            },
            {}
          );
          setLotsByProduct(updatedLots);
          setTitle(title === "Select All" ? "Deselect All" : "Select All");
        }}
      />
    </IonPage>
  );
};

export default MyTruck;
