import { IonList, IonItem, IonRadio, IonRadioGroup } from "@ionic/react";
import { forwardRef, useState, useEffect } from "react";
import { AutocompleteInput } from "../core/AutocompleteInput";

interface Props {
  label: string;
  description?: string;
  ref: React.RefObject<HTMLIonModalElement>;
  value: any;
  options: any[];
  onChange: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur: () => void;
  loading?: boolean;
  alreadySelected?: any;
  placeholder?: string;
  canCreate?: boolean;
}

type Ref = HTMLIonModalElement;

// TODO: interface for options (label,value) so its documented

export const Autocomplete = forwardRef<Ref, Props>((props, ref: any) => {
  const {
    label,
    description,
    value,
    onChange,
    onBlur,
    loading,
    options,
    alreadySelected = [],
    placeholder,
    canCreate = false,
  } = props;
  const [query, setQuery] = useState("");
  const [filteredOptions, setFilteredOptions] = useState<any[]>([]);

  /* We don't want to let users add anything if the alreadySelected prop contains its value */
  useEffect(() => {
    const filtered = options.filter((option: any) => {
      return (
        (option.value?.toLowerCase().indexOf(query?.toLowerCase()) > -1 ||
          option.label?.toLowerCase().indexOf(query?.toLowerCase()) > -1) &&
        !alreadySelected.includes(option.value)
      );
    });

    if (filtered.length === 0 && query.length > 0 && canCreate) {
      filtered.push({ label: `Create ${query}`, value: query });
    }
    setFilteredOptions(filtered);
  }, [query, options?.length]);

  const handleChange = (ev: Event) => {
    const target = ev.target as HTMLInputElement;
    if (target) setQuery(target.value);
  };

  /* Helpers */
  const sortAlphabetical = (items: any) => {
    return items.sort((a: any, b: any) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });
  };

  return (
    <>
      <div className="bg-white-100 sticky top-0 z-10 rounded-bl-xl rounded-br-xl">
        <div className="border border-blue-100 rounded-xl mb-3 focus-within:shadow-[0_0_0_4px_rgba(30,95,239,0.1)]">
          <AutocompleteInput
            label={label}
            placeholder={placeholder || "Select an option"}
            description=""
            value={query}
            onChange={(ev: any) => {
              handleChange(ev);
            }}
            className={"border-0"}
          />
        </div>
      </div>

      {filteredOptions.length > 0 ? (
        <IonList className="rounded-lg border border-black-10">
          <IonRadioGroup value={value}>
            {sortAlphabetical(filteredOptions)?.map(
              (option: any, index: any) => (
                <IonItem
                  key={index}
                  className="autocomplete-ion-list-override"
                  onClick={async () => {
                    await onChange(option.value);
                    await ref.current?.dismiss();
                    onBlur(); // kind of weird, but we wanna reset the value from the onBlur in the parent component
                    setQuery("");
                  }}
                >
                  <IonRadio value={option.value}>{option.label}</IonRadio>
                </IonItem>
              )
            )}
          </IonRadioGroup>
        </IonList>
      ) : loading ? (
        "Loading..."
      ) : (
        "No results"
      )}
    </>
  );
});
