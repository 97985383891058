import { EmailView } from "../../../components/dashboard/views/EmailView";
import { Header } from "../../../components/dashboard/Header";
import { Layout } from "../../../components/dashboard/Layout";

const Email: React.FC = ({}) => {
  return (
    <Layout>
      {/* Header */}
      <Header title={"Email Settings"} />
      {/* Content */}
      <EmailView />
    </Layout>
  );
};

export default Email;
