import { useState } from "react";
import deliveryService from "../service/deliveryService";

type FilterType = {
  id: string;
  label: string;
  value: string;
  selectedValues: string[];
};

type useFiltersType = {
  filters: FilterType[];
  setFilters: React.Dispatch<React.SetStateAction<FilterType[]>>;
  isFiltersLoading: boolean;
  fetchFilters: (params?: { exclude?: string[] }) => Promise<void>;
};

const useFilters = (): useFiltersType => {
  const [filters, setFilters] = useState<FilterType[]>([]);
  const [isFiltersLoading, setIsFiltersLoading] = useState(false);

  const fetchFilters = async (params: { exclude?: string[] } = {}) => {
    try {
      setIsFiltersLoading(true);
      const res = await deliveryService.getFilters(params);
      setFilters(
        res.map((filter: any) => ({
          ...filter,
          value: filter.value || "",
          selectedValues: [],
        }))
      );
      setIsFiltersLoading(false);
    } catch (error) {
      setIsFiltersLoading(false);
      console.error(error);
    }
  };

  return { filters, setFilters, isFiltersLoading, fetchFilters };
};

export default useFilters;
