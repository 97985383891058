import { createSlice, current } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
  loads: [],
  isLoadsLoading: false,
  hasReachedMaxPagination: false,
};

export const loads = createSlice({
  name: "loads",
  initialState,
  reducers: {
    setLoads: (state, action: PayloadAction<any>) => {
      state.loads = action.payload;
    },
    setIsLoadsLoading: (state, action: PayloadAction<any>) => {
      state.isLoadsLoading = action.payload;
    },
    setHasReachedMaxPagination: (state, action: PayloadAction<any>) => {
      state.hasReachedMaxPagination = action.payload;
    },
  },
});

export const { setLoads, setIsLoadsLoading, setHasReachedMaxPagination } =
  loads.actions;

export default loads.reducer;
