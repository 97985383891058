import SVG from "react-inlinesvg";
import add from "../../assets/svg/add.svg";
import scan from "../../assets/svg/scan.svg";

import React, { MouseEventHandler } from "react";

/* Interfaces */
interface Props {
  className: string;
  children: React.ReactNode;
  onClick?:
    | MouseEventHandler<HTMLButtonElement>
    | ((event: React.MouseEvent<HTMLButtonElement>) => Promise<void>);
  icon?: string;
  type?: string;
  disabled?: boolean;
}

/* Helpers */
const mapIconToSVG = (icon: string) => {
  switch (icon) {
    case "add":
      return add;
    case "scan":
      return scan;
    default:
      return "";
  }
};
const mapTypeToFill = (type: string) => {
  switch (type) {
    case "primary":
      return "rgba(30,95,239,1)";
    case "secondary":
      return "rgba(155, 155, 155, 1)";
    case "updateDewar":
      return "rgba(30,95,239,1)";
    default:
      return "";
  }
};

export const Button: React.FC<Props> = ({
  className,
  children,
  onClick,
  icon = "",
  type = "primary",
  disabled = false,
}) => {
  return (
    <button
      className={`block rounded-lg transition-all p-5 text-sm font-semibold ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {!!icon.length && (
        <SVG
          src={mapIconToSVG(icon)}
          style={{
            fill: `${mapTypeToFill(type)}`,
            height: "16px",
            width: "16px",
          }}
        />
      )}
      <span>{children}</span>
    </button>
  );
};
