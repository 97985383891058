interface Grid {
  key?: string;
  value?: string;
  valueClassName?: string;
}

interface Props {
  data: Grid[];
  className?: string;
}

export const GridData: React.FC<Props> = ({ data, className = "" }) => {
  return (
    <div
      className={`border-t border-b border-black-10 my-6 py-6 grid gap-2 ${className}`}
    >
      {data
        .filter((obj) => Object.keys(obj).length !== 0)
        ?.map((data, index) => (
          <div
            className="grid grid-cols-2 justify-between items-center"
            key={index}
          >
            <div className="text-md text-black-100">{data.key}</div>
            <div
              className={`text-md text-black-100 text-right ${data?.valueClassName}`}
            >
              {data.value}
            </div>
          </div>
        ))}
    </div>
  );
};
