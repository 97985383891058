const dewar = {
    singular: 'dewar',
    plural: 'dewars',
    relations: {
        customer: {
            belongsTo: {
                type:'customer',
                options: {
                    async: true
                }
            }
        },
        dewarInventories: {
            hasMany: 'dewarInventories'
        }
    }
}

module.exports = dewar;