import { KnowledgeCenterView } from "../../../components/dashboard/views/KnowledgeCenterView";
import { Header } from "../../../components/dashboard/Header";
import { Layout } from "../../../components/dashboard/Layout";

const KnowledgeCenter: React.FC = ({}) => {
  return (
    <Layout>
      {/* Header */}
      <Header title={"Knowledge Center"} />
      {/* Content */}
      <KnowledgeCenterView />
    </Layout>
  );
};

export default KnowledgeCenter;
