import SVG from "react-inlinesvg";
import { Warehouse } from "../core/Warehouse";

import arrowBack from "../../assets/svg/arrowBack.svg";
import history from "../../assets/svg/history.svg";

interface Props {
  title: string;
  address: {
    city: string;
    line1: string;
    state: string;
  };
  onInfoClick?: () => void;
  onHistoryClick: () => void;
  onBackClick: () => void;
}

export const DeliveryNoticeHeader: React.FC<Props> = ({
  title,
  address,
  onInfoClick,
  onHistoryClick,
  onBackClick,
}) => {
  return (
    <div className="sticky top-0 z-10 ion-no-border text-black-100 bg-app-bg grid grid-cols-[auto,auto,auto] justify-between items-center px-10 py-10 pt-14 cursor-pointer">
      <div
        className="grid items-center"
        onClick={() => (onBackClick ? onBackClick() : null)}
      >
        <div className="bg-transparent rounded-full hover:bg-black-10 transition p-5">
          <SVG
            src={arrowBack}
            style={{
              fill: "rgba(17,20,46,0.5)",
              height: "24px",
              width: "24px",
            }}
          />
        </div>
      </div>
      <div
        className="grid justify-center"
        onClick={() => (onInfoClick ? onInfoClick() : null)}
      >
        <Warehouse
          title={title}
          address={`${address.line1}, ${address.city}, ${address.state}`}
        />
      </div>
      <div
        className="grid justify-end bg-transparent "
        onClick={() => (onHistoryClick ? onHistoryClick() : null)}
      >
        <div className="hover:bg-black-10 transition rounded-full p-5">
          <SVG
            src={history}
            style={{
              fill: "rgba(17,20,46,0.5)",
              height: "25px",
              width: "25px",
            }}
          />
        </div>
      </div>
    </div>
  );
};
