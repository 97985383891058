import { DeliveryCard } from "./DeliveryCard";
import { Delivery } from "../../interfaces/inbox";

interface Props {
  group: [string, Delivery[]];
  filteredDeliveries: any;
  moveDeliveryToInProgress: any;
}

export const DeliveryGroup: React.FC<Props> = ({
  group,
  filteredDeliveries,
  moveDeliveryToInProgress,
}) => {
  return !!group[1].length ? (
    <div className="grid grid-cols-1 gap-3">
      <div className="capitalize text-md font-bold ">
        {group[0]?.toLocaleLowerCase()?.replace("_", " ")}
      </div>
      {group[1].map((delivery: Delivery, key) => (
        <DeliveryCard
          id={delivery.id}
          key={key}
          title={delivery.title}
          orderNumber={delivery.orderNumber}
          address={delivery.address}
          status={delivery.status}
          driver={delivery?.driver}
          dateCreated={delivery.dateCreated}
          dateUpdated={delivery.dateUpdated || delivery.dateCreated}
          filteredDeliveries={filteredDeliveries}
          moveDeliveryToInProgress={moveDeliveryToInProgress}
        />
      ))}
    </div>
  ) : (
    <></>
  );
};
