import { useState, useCallback } from "react";
import deliveryService from "../service/deliveryService";

interface FetchOptions {
  filters?: any;
  beforeDate?: string;
  offset?: number;
}

const PAGE_SIZE = 200;
const useLots = () => {
  const [flattenedLots, setFlattenedLots] = useState<any[]>([]);
  const [hasReachedMaxPagination, setHasReachedMaxPagination] = useState(false);
  const [lotsByProduct, setLotsByProduct] = useState<any>({});
  const [lotsByProductLoading, setLotsByProductLoading] = useState(true);

  const groupByProduct = (detailedLots: any) => {
    return detailedLots.reduce((acc: any, curr: any) => {
      let productKey = curr?.product;
      if (!acc[productKey]) {
        acc[productKey] = [];
      }
      acc[productKey].push(curr);
      return acc;
    }, {});
  };

  const fetchInitialLots = useCallback(async (options: FetchOptions) => {
    /*
      When we're fetching initial values, we can assume a filter has changed. Lets reset 
      all of our hooks to ensure nothing gets mixed up
    */
    setFlattenedLots([]);
    setHasReachedMaxPagination(false);
    setLotsByProduct({});

    /* Fetch new data */
    setLotsByProductLoading(true);
    const filtersObj = options.filters?.reduce((acc: any, item: any) => {
      acc[item.type] = item.selectedValues;
      return acc;
    }, {});

    const lots = await deliveryService.getCurrentLots({
      ...filtersObj,
      limit: PAGE_SIZE,
      sortDateKey: "dateCreated",
    });

    setFlattenedLots(lots);
    const groupedByProduct = groupByProduct(lots);
    setLotsByProduct(groupedByProduct);
    setLotsByProductLoading(false);

    if (lots.length < PAGE_SIZE) {
      setHasReachedMaxPagination(true);
    }
  }, []);

  const fetchMoreLots = async (options: FetchOptions) => {
    const filtersObj = options.filters?.reduce((acc: any, item: any) => {
      acc[item.type] = item.selectedValues;
      return acc;
    }, {});

    setLotsByProductLoading(true);
    const lastLotOfBatch = flattenedLots[flattenedLots.length - 1];

    const newLots = await deliveryService.getCurrentLots({
      ...filtersObj,
      limit: PAGE_SIZE,
      sortDateKey: "dateCreated",
      beforeDate: lastLotOfBatch?.dateCreated || "3000-01-01",
    });

    // Dedupe remove any lots that are already in the list
    const newLotsFiltered = newLots.filter(
      (newLot) => !flattenedLots.some((lot) => lot.id === newLot.id)
    );

    const groupedByProduct = groupByProduct([
      ...flattenedLots,
      ...newLotsFiltered,
    ]);

    setFlattenedLots([...flattenedLots, ...newLotsFiltered]);
    setLotsByProduct(groupedByProduct);
    setLotsByProductLoading(false);

    if (!newLotsFiltered.length) {
      setHasReachedMaxPagination(true);
    }
  };

  return {
    lotsByProduct,
    lotsByProductLoading,
    hasReachedMaxPagination,
    setLotsByProduct,
    fetchInitialLots,
    fetchMoreLots,
  };
};

export default useLots;
