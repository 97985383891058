import { createSlice, current } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {};

export const delivery = createSlice({
  name: "delivery",
  initialState,
  reducers: {
    resetDelivery: () => initialState,
    setDelivery: (state, action: PayloadAction<any>) => {
      return { ...state, ...action.payload };
    },
    setProductStartingInventory: (state, action: PayloadAction<any>) => {
      state.productInventories = state.productInventories.map(
        (product: any) => {
          if (product.id === action.payload.id) {
            product.measurement.starting = action.payload.value;
          }
          return product;
        }
      );
    },
    setProductSkipDelivery: (state, action: PayloadAction<any>) => {
      state.productInventories = state.productInventories.map(
        (product: any) => {
          if (product.id === action.payload.productId) {
            product.skipDelivery = action.payload.skipDelivery;
          }
          return product;
        }
      );
    },
    setProductLot: (state, action: PayloadAction<any>) => {
      state.productInventories = state.productInventories.map(
        (product: any) => {
          if (product.id === action.payload.productId) {
            product.lots = product.lots.map((lot: any) => {
              if (lot.id === action.payload.lotId) {
                if (action.payload.key === "added") {
                  lot.measurement.added = action.payload.value;
                }
                if (action.payload.key === "free") {
                  lot.measurement.free = action.payload.value;
                }
                if (action.payload.key === "serial") {
                  lot.serial = action.payload.value;
                }
              }
              return lot;
            });
          }
          return product;
        }
      );
    },
    addLotToProduct: (state, action: PayloadAction<any>) => {
      state.productInventories = state.productInventories.map(
        (product: any) => {
          // Prevent adding to all products on the delivery.
          if (product.id == action.payload.lot.product) {
            // "product" here is the product inventory ID.
            product.lots = [...product.lots, ...[action.payload.lot]];
            product.skipDelivery = false; // if a product has been added, we can assume this is always false
          }
          return product;
        }
      );
    },
    addProductToDelivery: (state, action: PayloadAction<any>) => {
      state.productInventories = [
        ...state.productInventories,
        ...[action.payload.product],
      ];
    },
    removeProductLot: (state, action: PayloadAction<any>) => {
      state.productInventories = state.productInventories.map(
        (product: any) => {
          if (product.id == action.payload.productId) {
            product.lots = product.lots.filter(
              (lot: any) => lot.id !== action.payload.lotId
            );
          }
          return product;
        }
      );
    },

    setDewar: (state, action: PayloadAction<any>) => {
      state.dewarInventories = state.dewarInventories.map((dewar: any) => {
        if (dewar.id === action.payload.dewarId) {
          /* Serial */
          if (action.payload.key === "serial") {
            dewar.dewar.serial = action.payload.value;
          }
          /* Measurements */
          if (action.payload.key === "starting") {
            dewar.measurement.starting = action.payload.value;
          }
          if (action.payload.key === "ending") {
            dewar.measurement.ending = action.payload.value;
          }
          if (action.payload.key === "cans") {
            dewar.measurement.cans = action.payload.value;
          }
          if (action.payload.key === "notes") {
            dewar.notes = action.payload.value;
          }
        }
        return dewar;
      });
    },

    addDewar: (state, action: PayloadAction<any>) => {
      if (
        state.dewarInventories &&
        action.payload?.dewarInventory &&
        action.payload.dewarInventory?.dewar
      ) {
        state.dewarInventories = [
          ...state.dewarInventories,
          action.payload.dewarInventory,
        ];
      }
    },

    removeDewarInventory: (state, action: PayloadAction<any>) => {
      state.dewarInventories = state.dewarInventories.filter(
        (dewar: any) => dewar.id !== action.payload.dewarInventoryId
      );
    },

    removeProduct: (state, action: PayloadAction<any>) => {
      state.productInventories = state.productInventories.filter(
        (product: any) => product.id !== action.payload.productId
      );
    },

    setSigner: (state, action: PayloadAction<any>) => {
      if (!state.signer) {
        state.signer = {};
      }
      if (action.payload.key === "name") {
        state.signer.name = action.payload.value;
      }
    },
    setNotificationEmails: (state, action: PayloadAction<any>) => {
      if (!state.notificationEmails) {
        state.notificationEmails = [];
      }
      state.notificationEmails = action.payload;
    },
    setSendNotification: (state, action: PayloadAction<any>) => {
      state.sendNotification = action.payload.value;
    },
    setNotes: (state, action: PayloadAction<any>) => {
      state.notes = action.payload.value;
    },
    setDeliveryInstructions: (state, action: PayloadAction<any>) => {
      state.customer.deliveryInstructions = action.payload.value;
    },
  },
});

export const {
  setDelivery,
  resetDelivery,
  setProductStartingInventory,
  setProductSkipDelivery,
  setProductLot,
  addLotToProduct,
  addProductToDelivery,
  removeProductLot,
  setDewar,
  removeDewarInventory,
  removeProduct,
  addDewar,
  setSigner,
  setNotificationEmails,
  setSendNotification,
  setNotes,
  setDeliveryInstructions,
} = delivery.actions;

export default delivery.reducer;
