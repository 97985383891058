import { useCallback, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  setDewars,
  setIsDewarsLoading,
  setHasReachedMaxPagination,
} from "../redux/dewars";
import deliveryService from "../service/deliveryService";

const useDewars = () => {
  /* Redux */
  const dewars = useAppSelector((state) => state.dewars);
  const dispatch = useAppDispatch();

  const fetchDewars = useCallback(async (filters: any) => {
    dispatch(setIsDewarsLoading(true));
    /* We always want to set this back to false on an initial fetch, otherwise it'll get as true on subsequent filters */
    dispatch(setHasReachedMaxPagination(false));
    try {
      const fetchedDewars = await deliveryService.getDewars(filters, 500);
      dispatch(setDewars(fetchedDewars));
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      dispatch(setIsDewarsLoading(false));
    }
  }, []);

  const fetchMore = useCallback(
    async (filters: any) => {
      const customerFilters = filters.filters?.find(
        (filter: any) => filter.type === "customer"
      );
      const warehouseFilters = filters.filters?.find(
        (filter: any) => filter.type === "warehouse"
      );
      const statusFilters = filters.filters?.find(
        (filter: any) => filter.type === "dewar_status"
      );
      const transformedFilters = customerFilters.selectedValues.length
        ? {
            customerId: customerFilters.selectedValues,
            warehouseId: null,
            statuses: null,
          }
        : {};

      if (warehouseFilters.selectedValues.length) {
        transformedFilters.warehouseId = warehouseFilters.selectedValues;
      }

      if (statusFilters.selectedValues.length) {
        transformedFilters.statuses = statusFilters.selectedValues;
      }

      const lastDewarOfBatch = dewars?.dewars[dewars?.dewars.length - 1];
      const newDewars = await deliveryService.getDewars(
        {
          ...transformedFilters,
          ...(lastDewarOfBatch?.serial && {
            afterId: lastDewarOfBatch?.serial,
          }),
        },
        500
      );

      dispatch(setIsDewarsLoading(false));

      if (!newDewars.length) {
        dispatch(setHasReachedMaxPagination(true));
      } else {
        dispatch(setDewars([...dewars?.dewars, ...newDewars]));
      }
    },
    [dewars.dewars]
  );

  const [activeInput, setActiveInput] = useState<string>("");

  const [viewAllHistory, setViewAllHistory] = useState<boolean>(false);

  const [fileName, setFileName] = useState<string>("");

  return {
    dewarsIsLoading: dewars.dewarsIsLoading,
    dewars: dewars.dewars,
    setDewars: (data: any) => dispatch(setDewars(data)),
    fetchDewars,
    fetchMore,
    hasReachedMaxPagination: dewars.hasReachedMaxPagination,
    activeInput,
    setActiveInput,
    viewAllHistory,
    setViewAllHistory,
    fileName,
    setFileName,
  };
};

export default useDewars;
