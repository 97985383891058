import { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip);

interface Props {
  data: any;
}

export const DoughnutChart: React.FC<Props> = ({ data }) => {
  /* 
    Chart JS has a weird feature where the chart animates from the top left on page load if its responsive.
    We will push if its mounted to the bottom of the call stack so its not animated by default, but will be 
    for subsequent renders and data changs.
  */
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    if (!hasMounted) {
      setTimeout(() => setHasMounted(true), 1000);
    }
  }, []);

  return (
    <div>
      <div className="h-[200px]">
        <Doughnut
          data={data}
          options={{
            maintainAspectRatio: false,
            responsive: true,
            animation: {
              duration: hasMounted ? 1000 : 0,
            },
          }}
        />
      </div>
      <div className="py-6">
        {data.labels.map((label: any, index: number) => (
          <div key={label} className="flex justify-between items-center">
            <div className="flex gap-2 items-center mb-1">
              <div
                className={`h-3 w-3 rounded-full`}
                style={{
                  backgroundColor: data.datasets[0].backgroundColor[index],
                }}
              ></div>
              <div>{label}</div>
            </div>
            <div className="font-semibold">{data.datasets[0].data[index]}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
