import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import userService from "../service/userService";
import service from "../service/service";
import deliveryService from "../service/deliveryService";
import { useAppDispatch } from "../redux/hooks";
import { updateDeliveryInList } from "../redux/deliveries";
import { setUsers } from "../redux/users";
import { updateDewarInList } from "../redux/dewars";
import { setProgress, setTotal } from "../redux/backgroundSyncSlice";

/*
  This hook will setup live sync if it isn't already enbled 
  + will update redux with any changes that come in from the remote db  
*/
const useSync = () => {
  /* Redux */
  const dispatch = useAppDispatch();
  /* Hooks */
  const location = useLocation();

  /* Refs */
  const hasInitialized = useRef<any>(null);

  /* Helpers */

  /*
    This will live update redux with any change that comes through over the wire 
    in real-time (we purposely exclude invidual delivieries as we want the latest edit to be the source of truth)
    TODO: we will have a docId on every doc, but its not currently on all of them, so using substr for now
  */
  const initSyncWatch = async () => {
    service
      .getDbSource()
      .changes({
        since: "now",
        live: true,
        include_docs: true,
        conflicts: false,
      })
      .on("change", async (change: any) => {
        if (!change.deleted) {
          /* Deliveries list */
          if (change?.doc?.data?.docType === "delivery") {
            const strippedDeliveryId = change.id.substring(
              change.id.lastIndexOf("_") + 1
            );
            deliveryService.getDelivery(strippedDeliveryId).then((res) => {
              dispatch(updateDeliveryInList(res));
            });
          } else if (change?.doc?.data?.docType === "user") {
            /* Users */
            userService.getUsers().then((res) => {
              dispatch(setUsers(res));
            });
          } else if (change?.doc?.data?.docType === "dewar") {
            /* Dewars*/
            const strippedDewarId = change.id.substring(
              change.id.lastIndexOf("_") + 1
            );
            deliveryService.getDewar(strippedDewarId).then((res) => {
              dispatch(updateDewarInList(res));
            });
          }
        } else if (change.deleted) {
          /* Delete User (only thing we currently support deleting in the UI) */
          const splitUserId = change.id.split("_")?.[0];
          if (splitUserId === "user") {
            console.log("User delete:", change);
            userService.getUsers().then((res) => {
              dispatch(setUsers(res));
            });
          }
        }
      })
      .on("error", function (err: any) {
        console.log("Error in live sync: ", err);
      })
      .catch(function (err: any) {
        console.log("Uncaught error in live sync: ", err);
      });
  };

  /*
    If we are already logged in, whenever a user enters any 
    view in the app/dashboard, we want to enable live sync.

    Not totally sure if this will work, but I set a var for the actual 
    live sync method, and it has a cancelled property that I can check.
    If it is cancelled, I will re-init the live sync.
  */
  useEffect(() => {
    const fetchData = async () => {
      const liveSyncObj = service.getLiveSync();
      const lowercasePath = location.pathname.toLowerCase();
      if (
        (!hasInitialized.current && !lowercasePath.startsWith("/login")) ||
        liveSyncObj?.cancelled === true
      ) {
        hasInitialized.current = true;
        let currentUser = await userService.getCurrentUser();
        if (currentUser) {
          await service.syncLive(
            currentUser.email,
            currentUser.accessToken,
            (update: any) => {
              if (update.type === "change") {
                dispatch(setProgress(update.change?.change?.last_seq));
                dispatch(setTotal(update.total));
              }
            }
          );
          initSyncWatch();
        }
      }

      /* If the user logs out, reset the hasInitialized value */
      if (lowercasePath.startsWith("/login")) {
        hasInitialized.current = false;
      }
    };
    fetchData();
  }, [hasInitialized.current, location.pathname]);

  return null;
};

export default useSync;
