import * as Sentry from "@sentry/react";
import { CaptureConsole } from "@sentry/integrations";
import packageJson from "../package.json";

const SentryContainer: React.FC = ({ children }) => {
  if (process.env.REACT_APP_SENTRY_ENABLED === "true") {
    Sentry.init({
      release: `ceva-delivery-ui@${packageJson.version}`,
      dsn: "https://58d87ed14601462d805b6a9a41e8f4a9@o4504696167202816.ingest.sentry.io/4504750056210432",
      integrations: [
        new Sentry.BrowserTracing(),
        new CaptureConsole({
          levels: ["error"],
        }),
        new Sentry.Replay(),
      ],
      tracesSampleRate: 0.5,
      replaysSessionSampleRate: 0.1,
      enabled: true,
    });
  }

  return <>{children}</>;
};

export default SentryContainer;
