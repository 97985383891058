import { configureStore } from "@reduxjs/toolkit";
import deliveryReducer from "./delivery";
import customerDewarReducer from "./customerDewar";
import customerProductReducer from "./customerProduct";
import inboxFiltersReducer from "./inboxFilters";
import truckFiltersReducer from "./truckFilters";
import dashboardFiltersReducer from "./dashboardFilters";
import dewarFiltersReducer from "./dewarFilters";
import customerFilters from "./customerFilters";
import validationReducer from "./validationSlice";
import backgroundSyncReducer from "./backgroundSyncSlice";
import deliveriesReducer from "./deliveries";
import usersReducer from "./users";
import dewarsReducer from "./dewars";
import customersReducer from "./customers";
import loadsReducer from "./loads";

export const store = configureStore({
  reducer: {
    delivery: deliveryReducer,
    customerDewars: customerDewarReducer,
    customerProducts: customerProductReducer,
    inboxFilters: inboxFiltersReducer,
    truckFilters: truckFiltersReducer,
    dashboardFilters: dashboardFiltersReducer,
    dewarFilters: dewarFiltersReducer,
    customerFilters: customerFilters,
    validation: validationReducer,
    backgroundSync: backgroundSyncReducer,
    deliveries: deliveriesReducer,
    users: usersReducer,
    dewars: dewarsReducer,
    customers: customersReducer,
    loads: loadsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
