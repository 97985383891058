import { useEffect } from "react";
import jwtDecode from "jwt-decode";
import { useHistory, useLocation } from "react-router-dom";
import { IonContent, IonPage } from "@ionic/react";
import { Route, Switch } from "react-router";
import userService from "../../service/userService";
import deliveryService from "../../service/deliveryService";
import { SideNav } from "../../components/dashboard/SideNav";
import Deliveries from "./Deliveries/Index";
import Users from "./Users/Index";
import Email from "./Email/Index";
import Dewars from "./Dewars/Index";
import Customers from "./Customers/Index";
import KnowledgeCenter from "./KnowledgeCenter/Index";
import Loads from "./Loads/Index";

interface Props {
  match: any;
}

const Dashboard: React.FC<Props> = ({ match }) => {
  /* Hooks */
  const history = useHistory();
  const location = useLocation();

  /* Watchers */
  /*
    Whenever a route changes, check the JWT and boot the user out if it is expired.
    Technically this should be in a HOC, but since the user always hits the inbox, once 
    the component has been mounted, this will always run in the background per ionic lifecycle hooks.
    Maybe it's hacky, but it'll do! 
  */
  useEffect(() => {
    const checkAuth = async () => {
      const token = await userService.getAuthToken();
      if (token !== undefined && token.value) {
        const decodedToken: any = jwtDecode(token.value);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp < currentTime) {
          await deliveryService.destroy();
          await userService.signout();
          history.push(`/login`);
        }
      } else {
        history.push(`/login`);
      }
    };
    checkAuth();
  }, [location.pathname]);

  return (
    <IonPage>
      <IonContent>
        <div className="grid grid-cols-[240px,calc(100%-240px)] bg-white-100">
          <SideNav />
          <Switch>
            <Route
              path={["/dashboard", "/dashboard/deliveries"]}
              component={Deliveries}
              exact
            />
            <Route path="/dashboard/users" component={Users} />
            <Route path="/dashboard/email" component={Email} />
            <Route path="/dashboard/dewars" component={Dewars} />
            <Route path="/dashboard/customers" component={Customers} />
            <Route path="/dashboard/loads" component={Loads} />
            <Route
              path="/dashboard/knowledge-center"
              component={KnowledgeCenter}
            />
          </Switch>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Dashboard;
