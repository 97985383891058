import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  setCustomers,
  setIsCustomersLoading,
  setHasReachedMaxPagination,
} from "../redux/customers";
import deliveryService from "../service/deliveryService";

const useCustomers = () => {
  /* Redux */
  const customers = useAppSelector((state) => state.customers);
  const dispatch = useAppDispatch();

  const fetchCustomers = useCallback(async (filters: any) => {
    dispatch(setIsCustomersLoading(true));
    /* We always want to set this back to false on an initial fetch, otherwise it'll get as true on subsequent filters */
    dispatch(setHasReachedMaxPagination(false));
    try {
      const fetchedCustomers = await deliveryService.getCustomers(true);
      const filteredCustomers = fetchedCustomers?.filter((customer: any) => 
        filters?.customerId?.some((id: any) => id === customer.id)
      );
      dispatch(setCustomers(filters?.customerId?.length ? filteredCustomers : fetchedCustomers));
    } catch (error) {
    } finally {
      dispatch(setIsCustomersLoading(false));
    }
  }, []);

  return {
    customersIsLoading: customers.customersIsLoading,
    customers: customers.customers,
    setCustomers: (data: any) => dispatch(setCustomers(data)),
    fetchCustomers,
  };
};

export default useCustomers;
