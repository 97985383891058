import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonContent,
  IonFooter,
} from "@ionic/react";
import SVG from "react-inlinesvg";
import { forwardRef } from "react";
import close from "../../assets/svg/close.svg";
import { ModalButtonContainer } from "../deliveryNotice/ModalButtonContainer";

interface Button {
  text: string;
  theme: "LIGHT" | "MEDIUM" | "DARK";
  onClick: () => void;
}

interface Props {
  children: React.ReactNode;
  ref: React.RefObject<HTMLIonModalElement>;
  buttons?: Button[];
  heading: string;
  onWillDismiss?: () => void;
}

type Ref = HTMLIonModalElement;

export const Modal = forwardRef<Ref, Props>((props, ref: any) => {
  /* Props */
  const { children, buttons, heading, onWillDismiss } = props;
  return (
    <IonModal
      ref={ref}
      onWillDismiss={async () => {
        await ref.current?.dismiss();
        onWillDismiss && onWillDismiss();
      }}
    >
      <IonHeader>
        <IonToolbar className="ion-toolbar-modal-override">
          <div className="flex justify-between items-center px-3 py-6">
            <div className="font-bold text-lg text-black-100">{heading}</div>
            <button
              onClick={async () => await ref.current?.dismiss()}
              className="p-5 rounded-full bg-transparent hover:bg-black-10 transition cursor-pointer -mr-3"
            >
              <SVG
                src={close}
                style={{
                  fill: "rgba(17,20,46,1)",
                  height: "20px",
                  width: "20px",
                }}
              />
            </button>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-content-modal-override">
        <div className="px-6 py-2">{children}</div>
      </IonContent>
      {buttons?.length && (
        <IonFooter>
          <IonToolbar className="ion-toolbar-modal-override">
            <ModalButtonContainer buttons={buttons}></ModalButtonContainer>
          </IonToolbar>
        </IonFooter>
      )}
    </IonModal>
  );
});
