import { createSlice, current } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { getDateRanges } from "../lib";

/* We always default to THIS_WEEK because it is the first option + is least likely to need pagination */
const initialState: any = {
  dateRange: getDateRanges()[0]
    .options.map((range: any) => {
      const { startDate, endDate } = range.range;
      return {
        ...range,
        startDate,
        startDateFull: startDate,
        endDate,
        endDateFull: endDate,
      };
    })
    .find((range: any) => range.type === "THIS_MONTH"),
};

export const dashboardFilters = createSlice({
  name: "dashboardFilters",
  initialState,
  reducers: {
    setDashboardDateRange: (state, action: PayloadAction<any>) => {
      state.dateRange = action.payload;
    },
    setDashboardFilters: (state, action: PayloadAction<any>) => {
      state.filters = action.payload;
    },

    setDashboardFilter: (state, action: PayloadAction<any>) => {
      state.filters = state?.filters.map((filter: any) => {
        if (filter.type === action.payload.selectedFilter.type) {
          filter.value = action.payload.value;
        }
        return filter;
      });
    },
  },
});

export const {
  setDashboardFilters,
  setDashboardFilter,
  setDashboardDateRange,
} = dashboardFilters.actions;

export default dashboardFilters.reducer;
