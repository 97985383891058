import { Warehouse } from "../core/Warehouse";
import { GridData } from "../deliveryNotice/GridData";

interface Props {
  children?: React.ReactNode;
  lotInformation: any;
  orders: any[];
}

export const Truck: React.FC<Props> = ({ lotInformation, orders }) => {
  return (
    <div>
      <div>{lotInformation.serial}</div>
      <GridData
        data={[
          { key: "Starting", value: lotInformation?.measurement?.starting },
          {
            key: "Ending",
            value: lotInformation?.measurement.remaining,
            valueClassName:
              lotInformation?.measurement.remaining < 0
                ? "!text-orange-100"
                : "",
          },
        ]}
      />
      <div className="mb-4">Orders</div>
      {orders?.map((order, index) => (
        <div className="grid mb-2" key={index}>
          <div className="mt-2 px-4 py-6 border border-black-10 rounded-lg">
            <div className="pb-4">
              <Warehouse
                title={order.customer?.internalName}
                address={`${order.customer?.address?.line1}, ${order.customer?.address?.city}, ${order.customer?.address?.state}`}
              />
            </div>
            <GridData
              className="py-0 mt-0 border-none mb-0"
              data={[
                { key: "Order Number", value: order.otherIds.orderId },
                {
                  key: "Delivery Date",
                  value: new Date(
                    order.dateDelivered
                      ? order.dateDelivered
                      : order.dateCreated
                  ).toLocaleDateString("en-us"),
                },
                {
                  key: "Units",
                  value: order.unitsDelivered || 0,
                },
                {
                  key: "Status",
                  value: (
                    <div className="capitalize">
                      {order.status?.toLowerCase().replace("_", " ")}
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </div>
      ))}
      {orders.length === 0 && <div>No orders</div>}
    </div>
  );
};
