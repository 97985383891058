import { Link, useLocation, useHistory } from "react-router-dom";
import { useIonAlert } from "@ionic/react";
import logo from "../../assets/logo.png";
import deliveryService from "../../service/deliveryService";
import userService from "../../service/userService";

// SVGs
import SVG from "react-inlinesvg";
import truck from "../../assets/svg/truck.svg";
import group from "../../assets/svg/group.svg";
import mail from "../../assets/svg/mail.svg";
import propaneTank from "../../assets/svg/propaneTank.svg";
import packageIcon from "../../assets/svg/package.svg";
import lightbulb from "../../assets/svg/lightbulb.svg";
import building from "../../assets/svg/building.svg";
import sendToMobile from "../../assets/svg/sendToMobile.svg";
import logout from "../../assets/svg/logout.svg";
import download from "../../assets/svg/download.svg";

const links = [
  { title: "Deliveries", path: "/dashboard/deliveries", icon: packageIcon },
  { title: "Users", path: "/dashboard/users", icon: group },
  { title: "Email Settings", path: "/dashboard/email", icon: mail },
  { title: "Dewars", path: "/dashboard/dewars", icon: propaneTank },
  { title: "Customers", path: "/dashboard/customers", icon: building },
  { title: "File Loads", path: "/dashboard/loads", icon: download },
  { title: "Inbox", path: "/inbox", icon: sendToMobile, type: "EXTERNAL" },
  { title: "Truck", path: "/truck", icon: truck, type: "EXTERNAL" },
  {
    title: "Knowledge Center",
    path: "https://haystackmidwest.notion.site/US-Poultry-Delivery-System-Documentation-6f095bb0498f4a19840d9940081c00ac",
    icon: lightbulb,
    type: "EXTERNAL",
  },
  // { title: "Delivery App", path: "/inbox", icon: sendToMobile },
];

const MenuItem = ({ link, isNavActive }: any) => {
  return (
    <li
      className={`mb-2 py-3 px-3 rounded-lg text-sm font-medium text-black-100 flex items-center ${
        isNavActive(link.path) ? "bg-black-100 text-white-100 " : ""
      }`}
    >
      <SVG
        src={link.icon}
        title={link.title}
        className={`w-6 h-6 mr-2 ${
          isNavActive(link.path) ? "fill-white-100" : ""
        }`}
      />
      <span>{link.title}</span>
    </li>
  );
};

export const SideNav: React.FC = () => {
  /* Hooks */
  const location = useLocation();
  const history = useHistory();
  const [presentAlert] = useIonAlert();

  /* Helpers */
  const handleLogout = () => {
    presentAlert({
      header: "Are you sure you want to logout?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "Yes",
          role: "confirm",
          handler: async () => {
            await deliveryService.destroy();
            await userService.signout();
            history.push(`/login`);
          },
        },
      ],
    });
  };

  const isNavActive = (path: string) => {
    if (
      location.pathname.replace(/\/+$/, "") === "/dashboard" &&
      path === "/dashboard/deliveries"
    )
      return true;
    return location.pathname.replace(/\/+$/, "") === path;
  };
  return (
    <div className="h-[100vh] bg-slate-gray-100 top-0 sticky self-start px-4 pt-8 ">
      <img src={logo} alt="logo" className="w-10 h-10" />

      <ul className="mt-6">
        {links.map((link) =>
          link.type === "EXTERNAL" ? (
            <a href={link.path} target="_blank" key={link.title}>
              <MenuItem link={link} isNavActive={isNavActive} />
            </a>
          ) : (
            <Link
              key={link.title}
              to={{ pathname: link.path, state: { noAnimation: true } }}
            >
              <MenuItem link={link} isNavActive={isNavActive} />
            </Link>
          )
        )}
      </ul>
      <div
        className="flex gap-2 items-center absolute bottom-5 cursor-pointer"
        onClick={handleLogout}
      >
        <SVG
          src={logout}
          style={{
            fill: "rgba(17,20,46,0.5)",
            height: "20px",
            width: "20px",
          }}
        />
        <div className="text-black-50">Log out</div>
      </div>
    </div>
  );
};
