import { useState, useEffect, useRef } from "react";
import SVG from "react-inlinesvg";
import close from "../../assets/svg/close.svg";
import { Modal } from "../core/Modal";
import { AutocompleteMulti } from "../modals/AutocompleteMulti";

interface Props {
  filters: any;
  setFilter: (e: any) => void;
  className?: string;
  setFilters?: (e: any) => void;
  allowMultiSelect?: boolean;
  disableClear?: boolean;
  showSelectedIfSingleSelect?: boolean;
}

export const Filters: React.FC<Props> = ({
  filters,
  setFilter,
  className = "",
  setFilters,
  allowMultiSelect = true,
  disableClear = false,
  showSelectedIfSingleSelect = false,
}) => {
  /* Refs*/
  const filtersModal = useRef<HTMLIonModalElement>(null);

  /* Hooks */
  const [selectedFilter, setSelectedFilter] = useState<any>({});

  /* Constants */
  const capitalizeAndStrip = (s: string) => {
    if (typeof s !== "string") return "";
    return (s.charAt(0).toUpperCase() + s.slice(1))?.replace("_", " ");
  };

  const selectedFilterType = capitalizeAndStrip(
    selectedFilter.display || selectedFilter.type
  );

  /* Handlers */
  const handleTagClick = async (filter: any) => {
    setSelectedFilter(filter);
    await filtersModal.current?.present();
  };

  const handleClearClick = (e: any, filter: any) => {
    e.stopPropagation();

    // Find the index of the filter in the filters array
    const filterIndex = filters.filters.findIndex(
      (f: any) => f.type === filter.type
    );

    // Create a new array of filters with the selectedValues of the target filter cleared
    const updatedFilters = filters.filters.map((f: any, index: number) =>
      index === filterIndex ? { ...f, selectedValues: [] } : f
    );

    // Update the filters state
    if (setFilters) {
      setFilters(updatedFilters);
    }

    // Clear the selected filter state
    setFilter({ selectedFilter: filter, selectedValues: [] });
  };

  return (
    <>
      <div
        className={`mb-0 grid gap-3 justify-start items-center sticky top-0 z-10 ${className}`}
        style={{
          gridTemplateColumns: `repeat(${filters?.filters?.length},auto)`,
        }}
      >
        {filters?.filters?.map((filter: any) => (
          <div
            key={filter.type}
            className={`flex items-center text-sm px-5 py-2.5 border hover:bg-black-10 rounded-full cursor-pointer ${
              filter?.selectedValues.length
                ? "border-blue-100 text-blue-100 bg-blue-10"
                : "border-black-10"
            }`}
            onClick={() => handleTagClick(filter)}
          >
            <span className="capitalize">
              {capitalizeAndStrip(filter?.display || filter?.type)}
            </span>
            {!!filter.selectedValues.length && (
              <div className="text-black-100 ml-2 font-medium flex items-center">
                <span className="mr-1 max-w-[100px] truncate">
                  {showSelectedIfSingleSelect &&
                  filter.selectedValues?.length === 1
                    ? filter.entries.find((e: any) => {
                        return e.value == filter.selectedValues?.[0];
                      }).display
                    : filter.selectedValues.length}
                </span>
                {!disableClear && (
                  <div onClick={(e) => handleClearClick(e, filter)}>
                    <SVG
                      src={close}
                      style={{
                        fill: "rgba(30,95,239,1)",
                        height: "20px",
                        width: "20px",
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
      {/* Modal */}
      <Modal heading={`Select ${selectedFilterType}`} ref={filtersModal}>
        <div>
          <AutocompleteMulti
            label={selectedFilterType}
            placeholder={
              selectedFilter?.entries?.find((entry: any) => {
                if (selectedFilter.value === "") return ""; // archived dewars are empty, just return empty string to safeguard here
                return entry.value === selectedFilter?.value;
              })?.display
            }
            values={selectedFilter.selectedValues}
            ref={filtersModal}
            options={selectedFilter.entries?.map((entry: any) => {
              return {
                label: entry.display,
                value: entry.value,
                selectedValues: entry.selectedValues,
              };
            })}
            alreadySelected={[]}
            onChange={async (value: any): Promise<void> => {
              let newSelected: any;

              if (allowMultiSelect) {
                newSelected = selectedFilter.selectedValues.includes(value)
                  ? selectedFilter.selectedValues.filter(
                      (val: any) => val !== value
                    )
                  : selectedFilter.selectedValues.concat(value);
              } else {
                // Allow only one selection (the most recent one)
                newSelected = [value];
              }

              /* Update the redux filters + active filter, as well as the hook in this function so all the data stays in sync */
              if (setFilters) {
                const updatedFilters = filters?.filters?.map((filter: any) => {
                  if (filter.type === selectedFilter.type) {
                    if (allowMultiSelect) {
                      return { ...filter, selectedValues: newSelected };
                    } else {
                      return {
                        ...filter,
                        selectedValues: newSelected,
                        value: value,
                      };
                    }
                  }
                  return filter;
                });

                setFilters(updatedFilters);

                // Find the updated selectedFilter
                const updatedSelectedFilter = updatedFilters.find(
                  (filter: any) => filter.type === selectedFilter.type
                );

                setSelectedFilter(updatedSelectedFilter);
                setFilter({
                  selectedFilter: updatedSelectedFilter,
                  selectedValues: newSelected,
                });
              }

              /* Now that we're at the end, if single select, then hide the modal*/
              if (!allowMultiSelect) {
                await filtersModal.current?.dismiss();
              }
            }}
            onBlur={() => {}}
          />
        </div>
      </Modal>
    </>
  );
};
