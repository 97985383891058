import { Credentials } from "./interfaces/credentials";
import service from "./service";
import axios from "axios";
import { authModel } from "../models/Auth";
import { mapById } from "./helper";
import { userModel } from "../models/User";
const jose = require("jose");
const { v4: uuidv4 } = require("uuid");
const jwtSecret = process.env.REACT_APP_MAGIC_LINK_JWT_SECRET;
const serverUrl = process.env.REACT_APP_SERVER_URL;
const appName = "ceva-delivery-service";

/* 
  Service Helpers:
  These are c/p from the service, but this file is too lightweight to really benefit from a passthrough
  + we have to make these DB specific anyway. This could be abstracted better later, but alas. 
*/
const getAuthById = async (model: any, id: string, includeFull: boolean) => {
  let result = await service.authDb.find(model.singular, id);

  if (includeFull) {
    return result;
  } else {
    // When "includeFull" is used, it returns all objects, necessitating a code-side lookup of the correct one.
    let map = mapById(result[model.plural], "id");
    return map.get(id);
  }
};
const saveAuth = async (model: any, value: any) => {
  return await service.authDb.save(model.singular, value);
};

const saveAuthBlindly = async (model: any, value: any) => {
  let doc = await getAuthById(model, value.id, false);
  let valueCopy = {
    ...value,
    rev: doc?.rev,
  };

  return await service.authDb.save(model.singular, valueCopy);
};

const deleteAuth = (model: any, item: any) => {
  service.authDb.find(model.singular, 1).then(function (at: any) {
    return service.authDb.del(model.singular, at);
  });
  return service.authDb.del(model.singular, item);
};

const getCurrentUser = async () => {
  let authToken = "";
  const pouchAuth = await getAuthById(authModel, "authToken", false);

  if (pouchAuth && pouchAuth.value) {
    authToken = pouchAuth.value;
  }

  if (authToken && authToken != "undefined") {
    try {
      const { payload, protectedHeader } = await jose.jwtVerify(
        authToken,
        new TextEncoder().encode(jwtSecret),
        {
          issuer: "works.haystack",
          audience: "ceva-delivery-service",
        }
      );
      let currentUser = await service.getById(userModel, payload.id, false);
      return {
        ...currentUser,
        authenticated: true,
      };
    } catch (e: any) {
      if (e.message === "signature verification failed") {
        console.log("Token validation failed. Signing out.");
        await signout();
      } else {
        console.log("Error verifying user token", e);
      }

      return {
        authenticated: false,
        message: "Not authorized, token not available",
      };
    }
  }
};

const getAuthToken = async () => {
  return await getAuthById(authModel, "authToken", false);
};

const saveAuthToken = async (token: string) => {
  return await userService.saveAuthBlindly(authModel, {
    id: "authToken",
    value: token,
  });
};

const deleteAuthToken = async () => {
  return await userService.deleteAuth(authModel, "authToken");
};

const authenticate = async (token: string, callback: Function) => {
  if (token) {
    try {
      const { payload, protectedHeader } = await jose.jwtVerify(
        token,
        new TextEncoder().encode(jwtSecret),
        {
          issuer: "works.haystack",
          audience: "ceva-delivery-service",
        }
      );

      if (payload?.email && payload.accessToken) {
        await service.syncOnce(payload?.email, payload.accessToken, callback);
        return {
          ...payload,
          authenticated: true,
        };
      } else {
        console.log("Payload missing token", payload); // TODO: Remove
        await signout();
        callback({ error: "Payload missing token" });
      }
    } catch (err) {
      return { authenticated: false, err };
    }
  }

  return {
    authenticated: false,
    message: "Not authorized, token not available",
  };
};

/*
    This method will register or login new users. based on the validity 
    of their magic token, send an email when necessary, and when successful,
    return a JWT token to the end user for their localStorage. 
*/
const login = async (login: Credentials, isApp: boolean) => {
  const res = await axios
    .post(
      serverUrl + "/api/v1/apps/login",
      { ...login, ...{ isApp } },
      {
        headers: { Authorization: `${process.env.REACT_APP_SERVICE_AUTH}` },
      }
    )
    .then((r) => r.data);

  return res;
};

const signout = async () => {
  const pouchAuth = await getAuthById(authModel, "authToken", false);
  await deleteAuth(authModel, pouchAuth);
};

const getUsers = async () => {
  const users = await service.getUsers();
  return users;
};

const updateUser = async (user: any) => {
  /* We are assuming in this app, the realm is always the same */
  const newUser = await service.save(userModel, {
    ...user,
    ...{ realm: appName },
  });
  return newUser;
};

const deleteUser = async (user: any) => {
  const deletedUser = await service.remove(userModel, user);
  return deletedUser;
};

const userService = {
  authenticate,
  login,
  signout,
  getCurrentUser,
  getAuthById,
  saveAuthBlindly,
  deleteAuth,
  getAuthToken,
  saveAuthToken,
  deleteAuthToken,
  getUsers,
  updateUser,
  deleteUser,
};

export default userService;
