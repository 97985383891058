interface Props {
  title: string;
  address: string;
}

export const Warehouse: React.FC<Props> = ({ title, address }) => {
  return (
    <div className="grid grid-cols-[auto,1fr] gap-2">
      <div className="bg-black-10 h-10 w-10 rounded-lg grid justify-center items-center">
        {title?.charAt(0)}
      </div>
      <div>
        <div className="text-base">{title}</div>
        <div
          className="text-sm text-black-50"
          dangerouslySetInnerHTML={{
            __html: address.split(/,(.*)/).join("<br />"),
          }}
        ></div>
      </div>
    </div>
  );
};
