// TODO: these can probably reference our global interfaces?
interface Lot {
  serial: string;
  quantity: number;
  measurement?: any;
}

interface Product {
  product: {
    code: string;
    name: string;
  };
  lots: Lot[];
}

interface Props {
  products: Product[];
  title: string;
}

export const ProductReview: React.FC<Props> = ({ products, title }) => {
  /* Helpers */
  const getAddedUnits = (productInventory: any) => {
    return (
      productInventory?.lots
        ?.map((lot: any) => lot.measurement?.added)
        .reduce(
          (sum: Number, current: Number) => Number(sum) + Number(current),
          productInventory.measurement?.starting
        ) || 0
    );
  };

  const getLotUnits = (productInventory: any, lot: any) => {
    let isPromotion = lot?.measurement?.free;

    let promotion = "";
    if (isPromotion) {
      promotion = ` (includes ${lot?.measurement?.free} ${
        productInventory?.product?.class == "909" ? "charged" : "free"
      })`;
    }

    return `${lot?.measurement?.added || 0} units${promotion}`;
  };

  return (
    <>
      <div className="text-md text-black-100 mt-4 mb-4">{title}</div>
      {products.map((productInventory: any, productIndex: any) => (
        <div className="my-4 last:mb-0" key={productIndex}>
          <div className="mb-1">
            {productInventory?.product?.code +
              " - " +
              productInventory?.product?.name}
          </div>
          <div
            className="grid grid-cols-2 justify-between items-center gap-0.25 mb-0.5"
            key={productInventory?.product.key}
          >
            <div className="text-black-50">Beginning</div>
            <div className="text-black-50 text-right">
              {/* TODO - these data models look different */}
              {productInventory?.measurement?.starting || 0} units
            </div>
            <div className="text-black-50">Added</div>
            <div className="text-black-50 text-right">
              {/* TODO - these data models look different */}
              {parseInt(getAddedUnits(productInventory)) -
                parseInt(productInventory?.measurement?.starting || 0)}{" "}
              units
            </div>
            <div className="text-black-50">Ending</div>
            <div className="text-black-50 text-right">
              {/* Real-time sum the added units from lots as the product inventory measurement is not yet persisted. */}
              {productInventory?.lots
                ?.map((lot: any) => lot.measurement?.added)
                .reduce(
                  (sum: Number, current: Number) =>
                    Number(sum) + Number(current),
                  productInventory.measurement?.starting
                ) || 0}{" "}
              units
            </div>
            <div className="text-black-50">
              {productInventory?.product?.class == "909" ? "Charged" : "Free"}
            </div>
            <div className="text-black-50 text-right">
              {/* Real-time sum the added units from lots as the product inventory measurement is not yet persisted. */}
              {productInventory?.lots
                ?.map((lot: any) => lot.measurement?.free)
                .reduce(
                  (sum: Number, current: Number) =>
                    Number(sum) + Number(current),
                  0
                ) || 0}{" "}
              units
            </div>
          </div>

          <div className="text-black-50 mt-4">Lots</div>
          <div className="block w-full">
            {productInventory.lots.map((lot: any, lotIndex: any) => (
              <div
                className="grid grid-cols-2 justify-between items-center gap-0.25 mb-0.5 w-full"
                key={lotIndex}
              >
                <div className="text-black-50">{lot.serial}</div>

                <div className="text-black-50 text-right">
                  {getLotUnits(productInventory, lot)}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};
