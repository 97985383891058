import { useCallback, useEffect } from "react";
import deliveryService from "../service/deliveryService";
import { Delivery } from "../interfaces/inbox";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  setIsDeliveriesLoading,
  setDeliveries,
  setHasReachedMaxPagination,
  setFilteredDeliveries,
} from "../redux/deliveries";

interface Deliveries {
  [key: string]: Delivery[];
}

interface FetchOptions {
  statuses: string[];
  beforeDate?: string;
  sortDateKey?: string;
}

const useDeliveries = () => {
  /* Redux */
  const deliveries = useAppSelector((state) => state.deliveries);
  const dispatch = useAppDispatch();

  /* Effects */
  /* We want to show a loading state on the list the first time it's loaded -- since we can't access hooks in the ion lifecyle hooks, and 
  ionic will only call this once, we'll just set the loading state to false here */
  useEffect(() => {
    dispatch(setIsDeliveriesLoading(true));
  }, []);

  const fetchInitialDeliveries = useCallback(async (options?: FetchOptions) => {
    const processing: any[] = [];
    const upcoming: any[] = [];
    const previous: any[] = [];
    dispatch(setIsDeliveriesLoading(true));
    const initialDeliveries = await deliveryService.getInbox(
      options || {},
      300
    ); // Get all not-completed deliveries

    dispatch(setDeliveries(initialDeliveries));
    dispatch(setIsDeliveriesLoading(false));
    /* We always want to set this back to false on an initial fetch, otherwise it'll get as true on subsequent filters */
    dispatch(setHasReachedMaxPagination(false));

    /* This is for the custom grouping logic we have on the inbox page -- we're setting the hook but don't have to access it */
    initialDeliveries.map((delivery: any) => {
      if (delivery.status === "IN_PROGRESS" || delivery.status === "READY") {
        upcoming.push(delivery);
      } else if (
        delivery.status === "PROCESSING" ||
        delivery.status === "REPROCESSING"
      ) {
        processing.push(delivery);
      } else {
        previous.push(delivery);
      }
    });
    if (upcoming.length || previous.length || processing.length) {
      dispatch(
        setFilteredDeliveries({
          UPCOMING_DELIVERIES: upcoming?.sort((a: any, b: any) => {
            if (a.status === "IN_PROGRESS" && b.status === "READY") {
              return -1;
            } else if (a.status === "READY" && b.status === "IN_PROGRESS") {
              return 1;
            } else {
              return 0;
            }
          }),
          PROCESSING_DELIVERIES: processing,
          PREVIOUS_DELIVERIES: previous,
        })
      );
    } else {
      dispatch(
        setFilteredDeliveries({
          UPCOMING_DELIVERIES: [],
          PROCESSING_DELIVERIES: [],
          PREVIOUS_DELIVERIES: [],
        })
      );
    }
  }, []);

  const fetchMore = useCallback(
    async (options: FetchOptions) => {
      const lastDeliveryOfBatch =
        deliveries?.deliveries[deliveries?.deliveries.length - 1];
      const newDeliveries = await deliveryService.getInbox(
        {
          ...options,
          beforeDate:
            lastDeliveryOfBatch?.[options.sortDateKey || "dateDelivered"] ||
            new Date(
              new Date().setMonth(new Date().getMonth() + 1)
            ).toISOString(),
        },
        10
      ); // Page archived deliveries 10 at a time
      dispatch(setIsDeliveriesLoading(false));

      if (!newDeliveries.length) {
        dispatch(setHasReachedMaxPagination(true));
      } else {
        dispatch(setDeliveries([...deliveries?.deliveries, ...newDeliveries]));
        dispatch(
          setFilteredDeliveries({
            UPCOMING_DELIVERIES:
              deliveries?.filteredDeliveries.UPCOMING_DELIVERIES,
            PROCESSING_DELIVERIES:
              deliveries?.filteredDeliveries.PROCESSING_DELIVERIES,
            PREVIOUS_DELIVERIES: [
              ...deliveries?.filteredDeliveries.PREVIOUS_DELIVERIES,
              ...newDeliveries,
            ],
          })
        );
      }
    },
    [deliveries.deliveries]
  );

  /*
    When a user clicks into a delivery, we want to change the status. This happens in the DB,
    but we need to update the UI to reflect that. This is kinda gross since we're mixing
    hooks with redux, but it'll do! If we find ourselves doing this again, it's time to refactor 
    Also, the sort here is not DRY, but not worried about it for now.
  */
  const moveDeliveryToInProgress = useCallback(
    async (deliveryId: any, filteredDeliveries: any) => {
      const activeDelivery = filteredDeliveries.UPCOMING_DELIVERIES.find(
        (delivery: any) => delivery.id === deliveryId
      );
      const updatedFilteredDeliveries = {
        ...filteredDeliveries,
        UPCOMING_DELIVERIES: filteredDeliveries.UPCOMING_DELIVERIES.map(
          (delivery: any) => {
            if (
              delivery.id === activeDelivery.id &&
              activeDelivery.status === "READY"
            ) {
              return {
                ...delivery,
                status: "IN_PROGRESS",
              };
            }
            return delivery;
          }
        )?.sort((a: any, b: any) => {
          if (a.status === "IN_PROGRESS" && b.status === "READY") {
            return -1;
          } else if (a.status === "READY" && b.status === "IN_PROGRESS") {
            return 1;
          } else {
            return 0;
          }
        }),
      };
      dispatch(setFilteredDeliveries(updatedFilteredDeliveries));
    },
    [deliveries]
  );

  return {
    deliveries: deliveries?.deliveries,
    setDeliveries: (data: any) => dispatch(setDeliveries(data)),
    filteredDeliveries: deliveries?.filteredDeliveries,
    hasReachedMaxPagination: deliveries?.hasReachedMaxPagination,
    fetchInitialDeliveries,
    fetchMore,
    isDeliveriesLoading: deliveries?.isDeliveriesLoading,
    setIsDeliveriesLoading: (data: any) =>
      dispatch(setIsDeliveriesLoading(data)),
    moveDeliveryToInProgress,
  };
};

export default useDeliveries;
