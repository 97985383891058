import { IonFooter } from "@ionic/react";
import { Button } from "../core/Button";

interface Props {
  title: string;
  onSubmitClick: () => void;
  onSelectAll: () => void;
}

export const StickyFooter: React.FC<Props> = ({
  title,
  onSubmitClick,
  onSelectAll,
}) => {
  return (
    <IonFooter>
      <div className="w-full bg-white-100 text-black-100 flex justify-end items-center p-6">
        <Button
          className="text-white-100 bg-blue-100 hover:bg-blue-150 grid grid-cols-[auto,auto] items-center gap-0.5 px-8 mr-2"
          onClick={onSelectAll}
        >
          <span>{title}</span>
        </Button>
        <Button
          className="text-white-100 bg-blue-100 hover:bg-blue-150 grid grid-cols-[auto,auto] items-center gap-0.5 px-8"
          onClick={onSubmitClick}
        >
          <span>Finish Unloading</span>
        </Button>
      </div>
    </IonFooter>
  );
};
