const dewarInventoryModel = {
    singular: 'dewarInventory',
    plural: 'dewarInventories',
    relations: { 
        delivery: {
            belongsTo: 'delivery'
        },
        dewar: {
            belongsTo: 'dewar'
        }
    }
}

const productInventoryModel = {
    singular: 'productInventory',
    plural: 'productInventories',
    relations: { 
        delivery: {
            belongsTo: 'delivery'
        },
        product: {
            belongsTo: 'product'
        },
        // lots: {
        //     hasMany: 'lots'
        // }
    }
}

const deliveryModel = {
    singular: 'delivery',
    plural: 'deliveries',
    relations: {
        customer: {
            belongsTo: 'customer'
        },
        // signer: {
        //     belongsTo: 'user'
        // },
        driver: {
            belongsTo: 'user'
        },
        dewarInventory: {
            //hasMany: 'dewarInventories'
            hasMany:  {
                type:'dewarInventories',
                options: {
                    //async: true,
                    queryInverse: 'delivery'
                }
            }
        },
        productInventory: {
            hasMany: {
                type:'productInventories',
                options: {
                    //async: true,
                    queryInverse: 'delivery'
                }
            }
        }
    }
}

module.exports = { deliveryModel, dewarInventoryModel, productInventoryModel }