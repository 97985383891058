import { IonTextarea } from "@ionic/react";
import { useRef } from "react";
import SVG from "react-inlinesvg";
import info from "../../assets/svg/info.svg";

interface Props {
  label: string;
  placeholder?: string;
  description?: string;
  value: any;
  onChange: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  disabled?: boolean;
}

export const TextArea: React.FC<Props> = ({
  label,
  placeholder,
  description,
  value,
  onChange,
  onBlur,
  disabled,
}) => {
  /* Refs */
  const textareaRef = useRef(null);

  return (
    <div
      className={`grid px-4 py-3 border border-black-10 rounded-xl focus-within:border-black-25 ${
        disabled ? "opacity-50 bg-black-5 cursor-not-allowed" : ""
      }`}
    >
      <label className="grid grid-cols-[auto,auto] justify-start items-center gap-1">
        <span className="text-gray-50 text-xs">{label}</span>
        {description && (
          <SVG
            src={info}
            style={{
              fill: "rgba(17,20,46,0.5)",
              height: "14px",
              width: "14px",
            }}
          />
        )}
      </label>

      <IonTextarea
        ref={textareaRef}
        placeholder={placeholder}
        aria-label={label}
        className={`bg-transparent mt-1 w-full tailwind-textarea-override`}
        autoGrow={true}
        value={value}
        onIonChange={(e: any) => onChange(e)}
        onIonBlur={() => (onBlur ? onBlur() : null)}
        disabled={disabled}
        autocapitalize="sentences"
      ></IonTextarea>
    </div>
  );
};
