import { isPlatform } from "@ionic/react";
import { BackgroundFetch } from "@transistorsoft/capacitor-background-fetch";
import { useEffect } from "react";
import { App } from "@capacitor/app";
import { useAppDispatch } from "./redux/hooks";
import { setShouldRefreshInbox } from "./redux/backgroundSyncSlice";

/*
  Our sync database will just notify redux that we need to refresh the inbox.
  The rest will all be handled there! (the sync + state update)
*/
const syncDatabase = async (notifyRedux: any) => {
  return new Promise(async (resolve, reject) => {
    let { isActive } = await App.getState();
    if (!isActive) {
      notifyRedux();
    }
    resolve(true);
  });
};

/* This is pretty much all straight from the plugin */
const initBackgroundFetch = async (notifyRedux: any) => {
  const status = await BackgroundFetch.configure(
    {
      minimumFetchInterval: 15,
    },
    async (taskId) => {
      console.log("[BackgroundFetch] EVENT:", taskId);
      // Perform your work in an awaited Promise
      const result = await syncDatabase(notifyRedux); // TODO: add arg
      console.log("[BackgroundFetch] work complete:", result);
      // [REQUIRED] Signal to the OS that your work is complete.
      BackgroundFetch.finish(taskId);
    },
    async (taskId) => {
      // The OS has signalled that your remaining background-time has expired.
      // You must immediately complete your work and signal #finish.
      console.log("[BackgroundFetch] TIMEOUT:", taskId);
      // [REQUIRED] Signal to the OS that your work is complete.
      BackgroundFetch.finish(taskId);
    }
  );
  // Checking BackgroundFetch status -- alert the user if there is an issue
  if (status !== BackgroundFetch.STATUS_AVAILABLE) {
    if (status === BackgroundFetch.STATUS_DENIED) {
      alert(
        "The user explicitly disabled background behavior for this app or for the whole system."
      );
    } else if (status === BackgroundFetch.STATUS_RESTRICTED) {
      alert(
        "Background updates are unavailable and the user cannot enable them again."
      );
    }
  }
};

const BackgroundSync: React.FC<any> = () => {
  /* Redux */
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isPlatform("ios") && !isPlatform("mobileweb")) {
      initBackgroundFetch(() => dispatch(setShouldRefreshInbox(true)));
    }
  }, []);

  return null;
};

export default BackgroundSync;
