import { useIonAlert } from "@ionic/react";
import { useHistory, useLocation } from "react-router-dom";
import SVG from "react-inlinesvg";
import { Button } from "../core/Button";
import userService from "../../service/userService";
import logo from "../../assets/logo.png";
import person from "../../assets/svg/person.svg";
import deliveryService from "../../service/deliveryService";
import { Modal } from "../core/Modal";
import { useEffect, useRef, useState } from "react";
import { Autocomplete } from "../modals/Autocomplete";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { appModel } from "../../models/App";
import service from "../../service/service";
import sync from "../../assets/svg/sync.svg";
import { Network } from "@capacitor/network";

interface Props {
  filters?: any;
}

export const InboxHeader: React.FC<Props> = ({ filters }) => {
  /* Redux */
  const dispatch = useAppDispatch();
  const inboxFilters = useAppSelector((state) => state.inboxFilters);
  const backgroundSync = useAppSelector((state) => state.backgroundSync);

  /* Hooks */
  const [presentAlert] = useIonAlert();
  const history = useHistory();
  const location = useLocation();
  const [selectedCustomer, setSelectedCustomer] = useState<any>({});
  const [selectedTruck, setSelectedTruck] = useState<any>({});
  const [customers, setCustomers] = useState<any>([]);
  const [trucks, setTrucks] = useState<any>([]);
  const [toggles, setToggles] = useState<any>({});
  const [showProgress, setShowProgress] = useState(false);
  const [progress, setProgress] = useState(100);
  const [lastTotal, setLastTotal] = useState(0);

  useEffect(() => {
    let pctComplete = Math.round(
      100 * (backgroundSync.progress / backgroundSync.total)
    );

    if (backgroundSync.total - backgroundSync.progress > 1) {
      let p = Math.round(
        (100 * Number(backgroundSync.progress - lastTotal)) /
          Number(backgroundSync.total - lastTotal)
      );
      if (p >= 0 && p <= 100) {
        setProgress(p);
        setShowProgress(true);
      } else {
        setShowProgress(false);
      }
    } else {
      setShowProgress(false);
      setLastTotal(backgroundSync.total);
    }
  }, [backgroundSync.progress, backgroundSync.total]);

  /* Refs*/
  const customerModal = useRef<HTMLIonModalElement>(null);
  const truckModal = useRef<HTMLIonModalElement>(null);

  /* Constants */
  const isCurrentPathActive = (path: string) =>
    location.pathname?.toLowerCase().includes(path);

  /* Handlers */
  const handleCreateDelivery = async () => {
    deliveryService
      .getTrucks()
      .then((trucks) =>
        setTrucks(trucks.map((t: any) => ({ display: t, value: t })))
      );
    await truckModal.current?.present();
  };

  const handleTruckChange = async (value: any): Promise<void> => {
    if (value) {
      setSelectedTruck(value);
      await deliveryService.getCustomers().then((res) => setCustomers(res));
      await customerModal.current?.present();
    }
  };

  const handleLogout = () => {
    presentAlert({
      header: "Are you sure you want to logout?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "Yes",
          role: "confirm",
          handler: async () => {
            await deliveryService.destroy();
            await userService.signout();
            history.push(`/login`);
          },
        },
      ],
    });
  };

  /* Watchers */
  useEffect(() => {
    const fetchToggles = async () => {
      const svcRes = await service.getById(
        appModel,
        "ceva-delivery-service",
        true
      );

      const app = svcRes?.apps?.[0] || {};

      setToggles(app?.toggles || {});
    };

    fetchToggles();
  }, [location.pathname]);

  const handleCustomerChange = async (value: any): Promise<void> => {
    if (value) {
      setSelectedCustomer(value);
      let user = await userService.getCurrentUser();
      if (user.authenticated) {
        deliveryService
          .createDelivery({
            customerId: value,
            driverEmail: user.email,
            truck: selectedTruck,
          })
          .then((d: any) => {
            history.push(`/delivery-notice/${d.id}`);
            setSelectedCustomer({});
          });
      }
    }
  };

  //Network status
  const [networkStatus, setNetworkStatus] = useState({
    connected: false,
    connectionType: "unknown",
  });

  useEffect(() => {
    // Function to check the current network status
    const checkCurrentNetworkStatus = async () => {
      const status = await Network.getStatus();
      setNetworkStatus(status);
    };

    // Check network status on component mount
    checkCurrentNetworkStatus();

    // Listener for network status changes
    const setupNetworkListener = async () => {
      const handler = await Network.addListener(
        "networkStatusChange",
        (status: any) => {
          setNetworkStatus(status);
        }
      );

      // Cleanup the listener on component unmount
      return () => {
        handler.remove();
      };
    };

    const cleanup = setupNetworkListener();

    return () => {
      cleanup.then((fn) => fn());
    };
  }, []);

  return (
    <div className="sticky top-0 z-10 ion-no-border text-black-100 bg-app-bg px-10 pt-14 pb-4">
      <div className="w-full grid grid-cols-2 justify-between items-start">
        <div className="w-full grid grid-cols-[auto,1fr] gap-x-4 items-start">
          <div>
            <img src={logo} alt="logo" className="w-10 h-10" />
          </div>
          <div className="flex flex-col">
            <div className="flex items-center gap-4">
              <div
                className={`text-lg font-bold cursor-pointer ${
                  isCurrentPathActive("inbox")
                    ? "text-black-100"
                    : "text-black-25 hover:text-black-50"
                } transition-all duration-300`}
                onClick={() => history.push("/inbox")}
              >
                Inbox
              </div>
              {toggles?.TRUCK && (
                <div
                  className={`text-lg font-bold cursor-pointer ${
                    isCurrentPathActive("truck")
                      ? "text-black-100"
                      : "text-black-25 hover:text-black-50"
                  } transition-all duration-300`}
                  onClick={() => history.push("/truck")}
                >
                  Truck
                </div>
              )}
              <div
                className={`text-${
                  networkStatus.connected ? "green-dark-100" : "black-50"
                } text-[10px] bg-${
                  networkStatus.connected ? "green-light-10" : "black-10"
                } font-medium rounded-full inline-block px-1.5 py-0.5`}
              >
                {networkStatus.connected ? "Online" : "Offline"}
              </div>
            </div>
            {showProgress && (
              <div className="flex items-center gap-1.5">
                <SVG
                  src={sync}
                  style={{
                    fill: "rgba(17,20,46,0.5)",
                    height: "16px",
                    width: "16px",
                  }}
                />

                <div className="text-black-50 text-xs">
                  Syncing ({`${progress}%`})
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="grid grid-cols-[auto,auto] gap-x-2.5 justify-end">
          <Button
            className="text-blue-100 bg-blue-10 hover:bg-blue-25 grid grid-cols-[auto,auto] items-center gap-0.5 !py-3.5"
            onClick={handleCreateDelivery}
            icon="add"
          >
            <span>Create Delivery</span>
          </Button>
          <div
            className="bg-black-5 hover:bg-black-25 rounded-full flex justify-center items-center px-2.5 cursor-pointer"
            onClick={handleLogout}
          >
            <SVG src={person} className="w-7 h-7" />
          </div>
        </div>
      </div>
      {showProgress && (
        <div className="w-full bg-black-25 rounded-full h-0.5 mt-6 overflow-hidden">
          <div
            className="bg-blue-100 h-full"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
      )}
      {filters}
      {/* Modal */}
      <Modal heading="Who is the delivery for?" ref={customerModal}>
        <div>
          <Autocomplete
            label="Customer"
            placeholder="Select customer"
            value={selectedCustomer}
            ref={customerModal}
            options={customers.map((customer: any) => {
              return { label: customer.display, value: customer.value };
            })}
            onChange={handleCustomerChange}
            onBlur={() => {}}
          />
        </div>
      </Modal>
      <Modal heading="Which truck is being used?" ref={truckModal}>
        <div>
          <Autocomplete
            label="Truck"
            placeholder="Select Truck"
            value={selectedTruck}
            ref={truckModal}
            options={trucks.map((truck: any) => {
              return { label: truck.display, value: truck.value };
            })}
            onChange={handleTruckChange}
            onBlur={() => {}}
          />
        </div>
      </Modal>
    </div>
  );
};
