import { createSlice, current } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
  customers: [],
  isCustomersLoading: false,
  hasReachedMaxPagination: false,
};

export const customers = createSlice({
  name: "customers",
  initialState,
  reducers: {
    setCustomers: (state, action: PayloadAction<any>) => {
      state.customers = action.payload;
    },
    setIsCustomersLoading: (state, action: PayloadAction<any>) => {
      state.isCustomersLoading = action.payload;
    },
    setHasReachedMaxPagination: (state, action: PayloadAction<any>) => {
      state.hasReachedMaxPagination = action.payload;
    },
    updateCustomerList: (state, action: PayloadAction<any>) => {
      const existingCustomer = state.customers.find(
        (customer: any) => customer.id === action.payload.id
      );

      if (existingCustomer) {
        state.customers = state.customers.map((customer: any) => {
          if (customer.id === action.payload.id) {
            return action.payload;
          }
          return customer;
        });
      } else {
        state.customers = [...state.customers, ...[action.payload]];
      }
    },
  },
});

export const {
  setCustomers,
  setIsCustomersLoading,
  setHasReachedMaxPagination,
  updateCustomerList,
} = customers.actions;

export default customers.reducer;
