import axios from "axios";
const serverUrl = process.env.REACT_APP_SERVER_URL;

// Page by suppling the id of the last load retrieved
const getLoads = async (afterId?: string, limit?: number) => {
  try {
    let url = `${serverUrl}/api/v1/loads?afterId=${afterId}&limit=${limit}`;
    let response = await axios.get(url, {
      headers: {
        Authorization: `${process.env.REACT_APP_SERVICE_AUTH}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

const loadService = {
  getLoads,
};

export default loadService;
