import { useEffect, useState } from "react";
import { SectionContainer } from "../SectionContainer";
import { DataTable } from "../DataTable";
import useLoads from "../../../hooks/useLoads";
import axios from "axios";
import { saveAs } from "file-saver";

export const LoadsView: React.FC = () => {
  /* Hooks */
  const {
    loads,
    isLoadsLoading,
    fetchLoads,
    fetchMore,
    hasReachedMaxPagination,
  } = useLoads();

  const [isSingleLoadLoading, setIsSingleLoadLoading] = useState(false);

  /* Watchers */
  useEffect(() => {
    fetchLoads();
  }, []);

  /* Helpers */

  const formatDateTime = (dateString: string) => {
    const date = new Date(dateString);

    const formattedDate = `${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}/${String(date.getDate()).padStart(2, "0")}/${date.getFullYear()}`;

    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const formattedTime = `${String(hours).padStart(2, "0")}:${String(
      minutes
    ).padStart(2, "0")}${ampm}`;

    return `${formattedDate} ${formattedTime}`;
  };

  const exportSingleLoad = async (inventoryFile: string, orderFile: string) => {
    setIsSingleLoadLoading(true);
    try {
      const [inventoryResult, orderResult] = await Promise.all([
        axios
          .post(
            `${process.env.REACT_APP_SERVER_URL}/api/v1/files/file`,
            {
              fileKey: inventoryFile,
            },
            {
              headers: {
                Authorization: `${process.env.REACT_APP_SERVICE_AUTH}`,
              },
            }
          )
          .then((r) => r.data),
        axios
          .post(
            `${process.env.REACT_APP_SERVER_URL}/api/v1/files/file`,
            {
              fileKey: orderFile,
            },
            {
              headers: {
                Authorization: `${process.env.REACT_APP_SERVICE_AUTH}`,
              },
            }
          )
          .then((r) => r.data),
      ]);

      const inventoryBlob = new Blob([inventoryResult], {
        type: "text/csv;charset=utf-8",
      });
      saveAs(inventoryBlob, inventoryFile);

      const orderBlob = new Blob([orderResult], {
        type: "text/csv;charset=utf-8",
      });
      saveAs(orderBlob, orderFile);
    } catch (err) {
      console.error(err);
    }
    setIsSingleLoadLoading(false);
  };

  /* Constants */
  const formattedLoads = loads.map((load: any) => {
    return {
      name: load.data.name,
      location: load.data.location,
      dateLoaded: formatDateTime(load.data.dateLoaded),
      status:
        load?.status?.charAt(0).toUpperCase() +
          load?.status?.slice(1)?.toLowerCase() || "Unknown",
      actions: ["download"],
      metadata: load,
    };
  });

  return (
    <>
      {/* Table */}
      <div className="mb-[20px]">
        <SectionContainer className="px-0 pb-0!">
          <DataTable
            data={formattedLoads}
            isLoading={isLoadsLoading}
            fetchMore={fetchMore}
            hasReachedMaxPagination={hasReachedMaxPagination}
            actionClickOptions={{
              download: (row: any) => {
                if (!isSingleLoadLoading)
                  exportSingleLoad(
                    row?.metadata?.data?.inventoryFile,
                    row?.metadata?.data?.orderFile
                  );
              },
            }}
          />
        </SectionContainer>
      </div>
    </>
  );
};
