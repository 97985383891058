import { useState } from "react";
import { LoadsView } from "../../../components/dashboard/views/LoadsView";
import { Header } from "../../../components/dashboard/Header";
import { Layout } from "../../../components/dashboard/Layout";

const Loads: React.FC = ({}) => {
  return (
    <Layout>
      {/* Header */}
      <Header title={"File Loads"} />
      <LoadsView />
    </Layout>
  );
};

export default Loads;
