import { Wrapper } from "../../components/core/Wrapper";
import { Input } from "../../components/core/Input";
import { Button } from "../../components/core/Button";
import { Loader } from "../../components/core/Loader";
import logo from "../../assets/logo.png";
import userService from "../../service/userService";
import { useHistory } from "react-router";
import {
  IonContent,
  IonPage,
  useIonAlert,
  useIonViewDidEnter,
  isPlatform,
} from "@ionic/react";
import { useState } from "react";

const Login: React.FC = () => {
  /* Hooks */
  const [email, setEmail] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const history = useHistory();
  const [presentAlert] = useIonAlert();

  /* Constants */
  const isIos = isPlatform("ios");
  const invalidEmailMessage =
    "We couldn't log you in. Please make sure you are using a valid email address and try again.";
  const invalidTokenMessage =
    "Auth code is invalid or expired. Please try again.";
  const invalidRolesMessage =
    "You do not have the proper roles assigned to access this application. Please contact your administrator.";

  /* Methods */
  const clearLoadingState = (error: any) => {
    if (error) {
      console.log("Problem with initial setup.", error);
      history.push(`/login`);
    }
    console.log("Done syncing!");
    setHasSubmitted(false);
    setEmail("");
    setCode("");
    setErrorMessage("");
    history.push(isIos ? `/inbox` : "/dashboard");
  };

  /* Handlers */
  const handleSendLoginLink = async () => {
    setIsLoading(true);
    setErrorMessage("");
    const res = await userService.login(
      {
        email: email?.toLowerCase(),
      },
      isIos
    );

    if (!res.success) {
      if (res.errorType === "ROLES") {
        setErrorMessage(invalidRolesMessage);
      } else {
        setErrorMessage(invalidEmailMessage);
      }
    } else {
      setErrorMessage("");
    }
    setHasSubmitted(true);
    setIsLoading(false);
  };

  const presentHelpAlert = (e: any) => {
    e.preventDefault();
    presentAlert({
      header: "Need help logging in?",
      message:
        "Make sure you have internet connection if you are logging in. Otherwise, please contact your CEVA administrator so they can get you access.",
      buttons: [
        {
          text: "Close",
          role: "cancel",
        },
      ],
    });
  };

  /* Effects */

  /*
    Handle auth on page load based on if user has a token.
    NOTE: we pretty much do this in the auth handler already, but this is 
    here specifically to show the loading state on the initial login
  */
  useIonViewDidEnter(async () => {
    setIsLoading(true);
    const authToken = await userService.getAuthToken();

    if (authToken && authToken != "undefined" && authToken.value) {
      const authRes = await userService
        .authenticate(authToken.value, clearLoadingState)
        .catch((e) => setIsLoading(false));
      if (!authRes.authenticated) {
        setIsLoading(false);
        await userService.deleteAuthToken();
      } else {
        /* If we've made it here, we can send the user to the inbox */
        history.push(isIos ? `/inbox` : "/dashboard");
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  });

  /*
    Handle login when a user submits their auth code
  */
  const login = async ({ email, code }: { email: string; code: string }) => {
    setIsLoading(true);
    setErrorMessage("");
    const loginRes = await userService
      .login(
        {
          email: email.toLowerCase(),
          magicToken: code,
        },
        isIos
      )
      .catch((e) => {
        setIsLoading(false);
      });

    const authRes = await userService
      .authenticate(loginRes.authToken, clearLoadingState)
      .catch((e) => {
        setIsLoading(false);
      });

    if (authRes.authenticated) {
      await userService.saveAuthToken(loginRes.authToken);
    } else {
      if (authRes.errorType === "ROLES") {
        setErrorMessage(invalidRolesMessage);
      } else {
        setErrorMessage(invalidTokenMessage);
      }
      setIsLoading(false);
    }
  };

  return (
    <IonPage className="max-w-[768px] mx-auto">
      <IonContent fullscreen>
        <div className="h-screen">
          <Wrapper>
            {isLoading ? (
              <div className="h-[100vh] flex flex-col justify-center items-center">
                <Loader />
                {hasSubmitted && (
                  <div className="mt-4">
                    <div className="font-bold text-center">Syncing Data</div>
                    <div className="text-center max-w-[400px] mt-1">
                      Sit tight, if this is your first time using the app this
                      could take a moment.
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="h-screen flex flex-col justify-center items-center">
                {!hasSubmitted ||
                (hasSubmitted && errorMessage === invalidEmailMessage) ||
                errorMessage === invalidRolesMessage ? (
                  <>
                    <img src={logo} alt="logo" className="w-20 h-20 mb-4" />

                    <div className="min-w-[400px] mb-4 bg-white-100">
                      <Input
                        label="Email address"
                        placeholder="driver@ceva.us"
                        autoFocus={true}
                        value={email}
                        onChange={(e: any) => setEmail(e.target.value)}
                      />
                    </div>
                    {!!errorMessage?.length && (
                      <div className="max-w-[400px] min-w-[400px] mb-4 text-sm text-orange-100 bg-orange-10 px-3 py-2 rounded-lg">
                        {errorMessage}
                      </div>
                    )}

                    <div className="min-w-[400px] mb-4">
                      <Button
                        className="text-white-100 w-full bg-blue-100 hover:bg-blue-50 grid items-center gap-0.5 px-8"
                        onClick={handleSendLoginLink}
                      >
                        <span>Send Login Link</span>
                      </Button>
                    </div>

                    <a
                      href=""
                      className="text-black-50 text-sm"
                      onClick={presentHelpAlert}
                    >
                      Need help logging in?
                    </a>
                  </>
                ) : (
                  <>
                    <div className="mb-6">
                      Check your email inbox for the login code.
                    </div>

                    <div className="min-w-[400px] mb-4 bg-white-100">
                      <Input
                        label="Login code"
                        placeholder="······"
                        value={code}
                        onChange={(e: any) => setCode(e.target.value)}
                      />
                    </div>
                    {!!errorMessage?.length && (
                      <div className="max-w-[400px] min-w-[400px] mb-4 text-sm text-orange-100 bg-orange-10 px-3 py-2 rounded-lg">
                        {errorMessage}
                      </div>
                    )}

                    <div className="min-w-[400px] mb-4">
                      <Button
                        className="text-white-100 w-full bg-blue-100 hover:bg-blue-50 grid items-center gap-0.5 px-8"
                        onClick={() => login({ email, code })}
                      >
                        <span>Login</span>
                      </Button>
                    </div>

                    <div
                      className="text-black-50 text-sm cursor-pointer"
                      onClick={() => {
                        setHasSubmitted(false);
                        setEmail("");
                      }}
                    >
                      Didn't get one?{" "}
                      <span className="text-blue-100">
                        Click here to try again
                      </span>
                    </div>
                  </>
                )}
              </div>
            )}
          </Wrapper>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Login;
