import { createSlice, current } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
  users: [],
  isUsersLoading: false,
};

export const users = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsers: (state, action: PayloadAction<any>) => {
      state.users = action.payload;
    },
    setIsUsersLoading: (state, action: PayloadAction<any>) => {
      state.isUsersLoading = action.payload;
    },
  },
});

export const { setUsers, setIsUsersLoading } = users.actions;

export default users.reducer;
