import { Input } from "../../components/core/Input";
import { DewarInventory as DewarInventoryInterface } from "../../interfaces/delivery";
import { DewarEntry } from "../../components/core/DewarEntry";
import { DeliveryEntry } from "../../components/core/DeliveryEntry";
import deliveryService from "../../service/deliveryService";
import delivery, { setDewar, removeDewarInventory } from "../../redux/delivery";
import { useAppDispatch } from "../../redux/hooks";
import { numericOnly } from "../../lib";
import { v4 as uuidv4 } from "uuid";

interface Props {
  props: DewarInventoryInterface;
  customerDewar: any;
  isAdded?: boolean;
  updateDewar?: (dewar: any) => void;
  setMissingDewars?: any;
  missingDewars?: any;
  isAdding?: boolean;
}

export const Dewar: React.FC<Props> = ({
  props,
  customerDewar,
  isAdded,
  updateDewar,
  setMissingDewars,
  missingDewars,
  isAdding,
}) => {
  /* Hooks */
  const { id, measurement, notes, dewar } = props;
  const dewarInventoryId = id; // just making this variable name clearer

  /* Redux */
  const dispatch = useAppDispatch();

  const transformToDewar = (obj: any) => {
    return { dewar: obj, id: uuidv4(), measurement: obj.measurement };
  };

  return !!isAdded ? (
    <DeliveryEntry
      title={dewar.serial}
      type="DEWAR"
      deleteMessage="Are you sure you want to delete this measurement?"
      archiveMessage="Are you sure you want to archive this dewar?"
      deleteCallback={() => {
        dispatch(removeDewarInventory({ dewarInventoryId }));
        deliveryService.removeDewarInventory(props);
        setMissingDewars &&
          setMissingDewars([...missingDewars, transformToDewar(dewar)]);
      }}
      archiveCallback={() => {
        dispatch(removeDewarInventory({ dewarInventoryId }));
        deliveryService.removeDewarInventory(props);
        deliveryService.addOrUpdateDewar({
          ...dewar,
          customer: undefined,
          status: "ARCHIVED",
        });
        setMissingDewars && setMissingDewars([...missingDewars]);
      }}
      id={id}
    >
      <div className="ml-10 mt-5 ">
        <div className="mt-5 mb-3 text-sm">Dewar Details</div>

        <div className="grid grid-cols-[90%] gap-5 items-center">
          <div className="flex gap-5">
            {/*Beginning Level */}
            <div className="flex-[1_0_calc(25.0%)]">
              <Input
                label="Beginning level"
                placeholder={"0"}
                min="0"
                value={
                  measurement.starting || 0 > 0 ? measurement.starting : ""
                }
                type="number"
                inputMode="numeric"
                onChange={(e: any) =>
                  dispatch(
                    setDewar({
                      dewarId: dewarInventoryId,
                      value: numericOnly(e.target.value),
                      key: "starting",
                    })
                  )
                }
              />
              {customerDewar?.measurement && (
                <div className="rounded-md py-1 px-2 mt-2 text-sm">
                  Previous: {customerDewar?.measurement?.starting}
                </div>
              )}
            </div>

            {/*Ending Level */}
            <div className="flex-[1_0_calc(25.0%)]">
              <Input
                label="Ending level"
                placeholder={"0"}
                min="0"
                value={measurement.ending || 0 > 0 ? measurement.ending : ""}
                type="number"
                inputMode="numeric"
                onChange={(e: any) =>
                  dispatch(
                    setDewar({
                      dewarId: dewarInventoryId,
                      value: numericOnly(e.target.value),
                      key: "ending",
                    })
                  )
                }
              />
              {customerDewar?.measurement && (
                <div className="rounded-md py-1 px-2 mt-2 text-sm">
                  Previous: {customerDewar?.measurement?.ending}
                </div>
              )}
            </div>

            {/*Number of Cans */}
            <div className="flex-[1_0_calc(25.0%)]">
              <Input
                label="Number of Cans"
                placeholder={"0"}
                min="0"
                max="6"
                value={measurement.cans || 0 > 0 ? measurement.cans : ""}
                type="number"
                inputMode="numeric"
                onChange={(e: any) =>
                  dispatch(
                    setDewar({
                      dewarId: dewarInventoryId,
                      value: numericOnly(e.target.value),
                      key: "cans",
                    })
                  )
                }
              />
              {customerDewar?.measurement && (
                <div className="rounded-md py-1 px-2 mt-2 text-sm">
                  Previous: {customerDewar?.measurement?.cans}
                </div>
              )}
            </div>

            {/*Notes */}
            <div className="flex-[1_0_calc(25.0%)]">
              <Input
                label="Notes"
                placeholder={"Add note"}
                value={notes || customerDewar?.notes || ""}
                type="text"
                onChange={(e: any) =>
                  dispatch(
                    setDewar({
                      dewarId: dewarInventoryId,
                      value: e.target.value,
                      key: "notes",
                    })
                  )
                }
              />
              {customerDewar?.notes && (
                <div className="rounded-md py-1 px-2 mt-2 text-sm">
                  Previous: {customerDewar?.notes}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </DeliveryEntry>
  ) : (
    <DewarEntry
      title={dewar.serial}
      type="DEWAR"
      deleteMessage="Are you sure you want to delete this measurement?"
      archiveMessage="Are you sure you want to archive this dewar?"
      deleteCallback={() => {
        dispatch(removeDewarInventory({ dewarInventoryId }));
        deliveryService.removeDewarInventory(props);
      }}
      archiveCallback={() => {
        dispatch(removeDewarInventory({ dewarInventoryId }));
        deliveryService.removeDewarInventory(props);
        deliveryService.addOrUpdateDewar({
          ...dewar,
          customer: undefined,
          status: "ARCHIVED",
        });
        setMissingDewars &&
          setMissingDewars([...missingDewars, transformToDewar(dewar)]);
      }}
      updateDewar={updateDewar}
      dewar={dewar}
      id={id}
      isAdding={isAdding}
    >
      <></>
    </DewarEntry>
  );
};
