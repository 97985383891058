import {
  IonContent,
  IonPage,
  IonRefresher,
  useIonViewDidEnter,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { InboxHeader } from "../../components/headers/Inbox";
import { Wrapper } from "../../components/core/Wrapper";
import { DeliveryGroup } from "../../components/inbox/DeliveryGroup";
import { ButtonContainer } from "../../components/deliveryNotice/ButtonContainer";
import { Loader } from "../../components/core/Loader";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { setShouldRefreshInbox } from "../../redux/backgroundSyncSlice";
import { useHistory } from "react-router";
import useDeliveries from "../../hooks/useDeliveries";
import useFilters from "../../hooks/useFilters";
import { setInboxFilter, setInboxFilters } from "../../redux/inboxFilters";
import userService from "../../service/userService";
import { Filters } from "../../components/core/Filters";
import { resetDelivery } from "../../redux/delivery";

const Inbox: React.FC = () => {
  /* Hooks */
  const [hasClickedLoadMore, setHasClickedLoadMore] = useState(false);
  const [filterOptions, setFilterOptions] = useState({});
  const [pullEvent, setPullEvent] = useState<any>({});
  const [pathName, setPathName] = useState("");
  const [hasMounted, setHasMounted] = useState(false);
  const history = useHistory();
  const {
    deliveries,
    filteredDeliveries,
    hasReachedMaxPagination,
    fetchInitialDeliveries,
    fetchMore,
    isDeliveriesLoading,
    setIsDeliveriesLoading,
    moveDeliveryToInProgress,
  } = useDeliveries();
  /* Filter Hooks */
  const [hasSetDefaultFilters, setHasSetDefaultFilters] = useState(false);
  const { filters, fetchFilters } = useFilters();
  const [currentUser, setCurrentUser] = useState<any>({});

  /* Redux */
  const inboxFilters = useAppSelector((state) => state.inboxFilters);
  const backgroundSync = useAppSelector((state) => state.backgroundSync);
  const dispatch = useAppDispatch();

  /* Local constants */
  const handleLoadMore = () => {
    if (!hasClickedLoadMore) setHasClickedLoadMore(true);
    fetchMore({
      ...{
        statuses: ["DELIVERED", "CORRECTED"],
        sortDateKey: "dateDelivered",
        beforeDate:
          deliveries[deliveries.length - 1]?.dateCreated || "3000-01-01",
      },
      ...filterOptions,
    });
  };

  const fetchInitial = async (inboxFilters: any) => {
    setHasClickedLoadMore(false);
    const filters = inboxFilters?.filters?.reduce((acc: any, item: any) => {
      acc[item.type] = item.selectedValues;
      return acc;
    }, {});

    setFilterOptions(filters);

    fetchInitialDeliveries({
      ...filters,
      ...{ statuses: ["IN_PROGRESS", "READY", "PROCESSING", "REPROCESSING"] },
    });
    /* Since our background task can trigger this, if it ever gets triggered, make sure this is false */
    if (backgroundSync.shouldRefreshInbox) {
      dispatch(setShouldRefreshInbox(false));
    }
  };

  /* For thep pull down*/
  const handleRefresh = (event: CustomEvent) => {
    setPullEvent(event);
    fetchInitial(inboxFilters);
  };

  /* Lifecyle */
  useEffect(() => {
    if (!pathName.length) {
      setPathName(history.location.pathname);
    }
  }, [history.location.pathname]);

  /*
   Watch for changes in the inbox filters and refetch deliveries with the new filters
  */
  /*  Watch for entering the inbox page */
  useEffect(() => {
    (async () => {
      if (!hasMounted && history.location.pathname.startsWith("/inbox")) {
        setHasMounted(true);
      }
    })();
  }, [history.location.pathname, hasMounted]);

  /* Watch for leaving the inbox page */
  useEffect(() => {
    (async () => {
      if (hasMounted && !history.location.pathname.startsWith("/inbox")) {
        setPathName("");
        setHasMounted(false);
      }
    })();
  }, [history.location.pathname, hasMounted]);

  /* 
    Fetch initial data when hasMounted changes or when inboxFilters change.
    At the time of writing, its safe to assume that there will always be inboxFilters available to filter by -- 
    if that ever changes we should change this.
  */
  useEffect(() => {
    const fetchData = async () => {
      if (hasMounted && Object.keys(inboxFilters).length && deliveries.length) {
        setIsDeliveriesLoading(false);
      }
    };
    fetchData();
  }, [inboxFilters, deliveries, hasMounted]);

  /*
    Fetch new deliveries when the filters change
  */
  useEffect(() => {
    if (Object.keys(inboxFilters).length) {
      fetchInitial(inboxFilters);
    }
  }, [inboxFilters]);

  /*
    Just to be safe, reset the delivery state when entering the inbox page so we never 
    cross wires with specific deliveries
  */
  useIonViewDidEnter(() => {
    dispatch(resetDelivery());
  });

  /*
    Since the state here is all pretty much local hook based data, we just fire a boolean from our background sync 
    component that lets us know if we should do a new sync! fetchInitial handles the sync and inbox reload 
  */
  useEffect(() => {
    if (backgroundSync.shouldRefreshInbox) {
      fetchInitial(inboxFilters);
    }
  }, [backgroundSync.shouldRefreshInbox]);

  /*
    Filter related watchers 
  */

  /* Fetch filters on mount */
  useEffect(() => {
    fetchFilters({
      exclude: [
        "product",
        "on_target",
        "truck_load_status",
        "truck_empty_status",
        "dewar_status",
      ],
    });
  }, []);

  /* Set the default filters */
  useEffect(() => {
    if (
      currentUser &&
      Object.keys(currentUser)?.length &&
      filters?.length &&
      !hasSetDefaultFilters
    ) {
      setHasSetDefaultFilters(true);
      dispatch(
        setInboxFilters(
          filters.map((filter: any) => {
            if (currentUser?.filters?.[filter.type]) {
              return {
                ...filter,
                selectedValues: currentUser.filters[filter.type],
              };
            }
            return filter;
          })
        )
      );
    }
  }, [currentUser, filters.length, hasSetDefaultFilters]);

  /* Fetch the current user so we know their default filters */
  useEffect(() => {
    const fetchCurrentUser = async () => {
      const user = await userService.getCurrentUser();
      setCurrentUser(user);
    };
    fetchCurrentUser();
  }, []);

  /*
    Create a new map of deliveries that are filtered by whatever combination of active filters we have
  */
  const deliveryGroups = Object.assign(
    {},
    ...Object.keys(filteredDeliveries).map((group: any) => {
      return {
        [group]: filteredDeliveries[group],
      };
    })
  );

  /*
    If the user has performed a pull down, if the pullEvent is complete and the deliveries are still loading,
    then remove the pull down animation
  */
  useEffect(() => {
    if (pullEvent?.detail?.complete && !isDeliveriesLoading) {
      pullEvent.detail.complete();
      setPullEvent({});
    }
  }, [pullEvent, isDeliveriesLoading]);

  return (
    <IonPage>
      <InboxHeader
        filters={
          <Filters
            className="mt-6 pb-4 bg-[#fafafa]"
            filters={inboxFilters}
            setFilter={(e: any) => {
              dispatch(setInboxFilter(e));
            }}
            setFilters={(e: any) => {
              dispatch(setInboxFilters(e));
            }}
          />
        }
      />
      <IonContent>
        <Wrapper>
          <IonRefresher
            slot="fixed"
            pullFactor={0.5}
            pullMin={50}
            pullMax={100}
            onIonRefresh={handleRefresh}
          >
            <Loader />
          </IonRefresher>

          {isDeliveriesLoading && !Object.keys(pullEvent)?.length ? (
            <Loader />
          ) : (
            <>
              <div className="grid grid-cols-1 gap-8">
                {Object.keys(deliveryGroups).map((group, groupIndex) => (
                  <DeliveryGroup
                    group={[group, deliveryGroups[group]]}
                    key={groupIndex}
                    filteredDeliveries={filteredDeliveries}
                    moveDeliveryToInProgress={moveDeliveryToInProgress}
                  />
                ))}
                {/* All the button store for pagination, would be cleaner as its own component, but this is simple */}
                {!hasReachedMaxPagination ? (
                  <div className="mb-6">
                    <ButtonContainer
                      text={
                        hasClickedLoadMore
                          ? "Load more"
                          : "Show delivered orders"
                      }
                      className="justify-center"
                      onClick={handleLoadMore}
                    />
                  </div>
                ) : (
                  hasReachedMaxPagination && (
                    <div className="text-center text-black-50 mb-2">
                      You've reached the end of the list
                    </div>
                  )
                )}
              </div>
            </>
          )}
          <div className="h-4 w-full"></div>
        </Wrapper>
      </IonContent>
    </IonPage>
  );
};

export default Inbox;
